import React from 'react';
import {
    Button,
    Card,
    CardHeader,
    Table,
    Container,
    Row,
    Form,
    Input,
    Modal,
    ModalBody,
    ModalFooter,
  } from "reactstrap";
  import jsPDF from 'jspdf';
import 'jspdf-autotable';
const KROCalculationPDF = ({trans_data,user_data,month_year,review_date,type_data })=>{

let totalFinalweightageValue = 0;
let totalFinalweightagescoreValue = 0;
const handleDownload = () => {
  console.log(type_data)
const unit = "pt";
const size = "A4"; // Use A1, A2, A3 or A4
const orientation = "portrait"; // portrait or landscape

const marginLeft = 40;
const doc = new jsPDF(orientation, unit, size);
const pageWidth = doc.internal.pageSize.getWidth();
doc.setFontSize(15);

const title = "KRO";
const headers = [["Particulars", "Achieved","Rating","Weightage","Weighted Score"]];
const data1 = trans_data.map(item => [ item.type, item.actual_target, item.rating,item.weightage,item.weighted_score]);
const data = [["Employee Name",user_data.name],["Employee Email id",user_data.email],["Name of the Reviewer",user_data.manager_name],["Month",month_year],["Review Date",review_date]];
const totalWidth = pageWidth - 80; // Subtracting 20 units for margins or padding

const column1Width = totalWidth * 0.4;
const column2Width = (totalWidth - column1Width);
let totalFinalweightageValue = trans_data.reduce((sum, item) => sum + parseFloat(item.weightage), 0);
let totalFinalweightagescoreValue = trans_data.reduce((sum, item) => sum + ((item.weighted_score) ? parseFloat(item.weighted_score) : 0), 0) ;
const ratinglabel = (totalFinalweightagescoreValue.toFixed(2) <= 1 ? "Needs Action" : (totalFinalweightagescoreValue.toFixed(2) <= 2.5 ? "Below Expectations" : (totalFinalweightagescoreValue.toFixed(2) <= 3.5 ? "Meets Expectations" : (totalFinalweightagescoreValue.toFixed(2) <= 4.5 ? "Exceed Expectation" : (totalFinalweightagescoreValue.toFixed(2) <= 5 ? "Exceptional" : "")) )));

// Function to calculate the height of the table based on the number of rows
function calculateTableHeight(content) {
  // Assuming each row has a fixed height, you can calculate the total height
  // by multiplying the number of rows by the height of each row
  const rowHeight = 10; // Adjust this value based on your actual row height
  const numRows = content.body.length; // Assuming body is an array of rows
  const totalHeight = numRows * rowHeight;

  return totalHeight;
}

const content = {
  theme: 'grid',
  startY: 50,
  body: data,
  columnStyles: {
    0: {
      cellWidth: column1Width,
    },
    1: {
      cellWidth: column2Width,
    }
  },

  bodyStyles: {
    cellPadding: 2, // Set cell padding
    lineWidth: 'bold',
    lineWidth: 0.5, // Set border line width for body
    lineColor: [0, 0, 0], // Set border line color for body
    fontStyle: 'bold'
  },

  showFoot: "lastPage", // Show footer on last page only
  margin: { top: 10 },
};


doc.setFontSize(16);
const fontFamily = 'Helvetica'; 
const fontWeight = 'bold'; 
const letterspacing = 2; 
doc.setFont(fontFamily, fontWeight);
doc.text(title, (pageWidth / 2), 35, 'center');
doc.setFontSize(13);
doc.setTextColor(0, 0, 0);
const fontFamilyo = 'Courier'; 
const fontWeighto = 'normal'; 
doc.setFont(fontFamilyo, fontWeight);
doc.autoTable(content);
let height = 0;

let currentHeight = 130; // Initial height for the first page
//currentHeight = height + 10;
    let content1 = {
      theme: 'grid',
      startY: currentHeight,
      margin: { bottom: 0 },
     head: headers,
       body: data1,
       foot: [
        ['Total','','', totalFinalweightageValue.toFixed(2)+' %',totalFinalweightagescoreValue.toFixed(2)] ,
        [ 'Average Rating' ,  { colSpan: 4, content: ratinglabel }] 
    ],
      headStyles : {fillColor : [115, 147, 179],textColor: [0, 0, 0],borderColor: [0, 0, 0],lineWidth: 0.2, lineColor: [0,0,0],lineWeight: 'bold',borderColor: [0, 0, 0]},
      bodyStyles: { 
        cell: { 
          cellHeight: 18, 
          
        },
        lineWidth: 'bold',
        lineWidth: 0.5, 
        lineColor: [0, 0, 0], 
      },
      
      footStyles :{textColor: [0, 0, 0],fillColor : [255,255,255],lineWidth: 0.2, lineColor: [0,0,0],lineWeight: 'bold',borderColor: [0, 0, 0],fontWeight: 'bold'},

    };
    let table1Height = calculateTableHeight(content1);
    let startYForSecondTable = content1.startY + table1Height + 100;
    doc.autoTable(content1);
    if (type_data && Object.keys(type_data).length > 0) {
      Object.keys(type_data).forEach((type) => {
        let typearr = type.split("@@");
        let typename = typearr[0]; // Extract type name
        let type_id = typearr[1]; // Extract type ID
        
        // Define table header
        let tableHeader = [
          [{ colSpan: 2, content: typename, styles: { halign: 'center', fontSize: 14, fontStyle: 'bold' } }],
          ['Target', 'Rating'] // Header for rows
        ];
  
        // Prepare data for the table
        let tableData = type_data[type].map((item) => [
          item.criteria.replace(/\$actual_target/g, "value"), // Replace dynamic target value
          item.rating_name
        ]);
  
        // Create the table in PDF
        doc.autoTable({
          head: tableHeader,
          body: tableData,
          tableWidth: (pageWidth / 2),
          startY: doc.lastAutoTable ? doc.lastAutoTable.finalY + 10 : 20, // Adjust vertical position if there are previous tables
          // margin: { top: 10, left: 10, right: 10 },
          // styles: { overflow: 'linebreak', fontSize: 10, cellPadding: 4, halign: 'center' },
          // columnStyles: { 0: { cellWidth: 'auto' }, 1: { cellWidth: 'auto' } }
          headStyles : {fillColor : [255, 255, 255],textColor: [0, 0, 0],borderColor: [0, 0, 0],lineWidth: 0.2, lineColor: [0,0,0],lineWeight: 'bold',borderColor: [0, 0, 0]},
          bodyStyles: { 
            cell: { 
              cellHeight: 16, 
              
            },
            lineWidth: 'bold',
            lineWidth: 0.5, 
            lineColor: [0, 0, 0], 
          },
          //footStyles : {fillColor : [255,255,255]},
          footStyles :{textColor: [0, 0, 0],fillColor : [255,255,255],lineWidth: 0.2, lineColor: [220,220,220],borderColor: [0, 0, 0],fontWeight: 'normal'},
          
        });
  
        // Add a page break if there is more content to print
        // if (Object.keys(type_data).length > 1) {
        //   doc.addPage();
        // }
      });
    }
    const tableHeader4 = [
      [
        { colSpan: 2, content: 'Final Rating', styles: { halign: 'center', fontSize: 14, fontStyle: 'bold' } }
      ],
      [
        'Target', 'Rating'
      ]
    ];
    const data4 = [['Less than or equal to 1','Needs Action'],['Less than or equal to 2.5','Below Expectation'],['Less than or equal to 3.5','Meet Expectation'],['Less than or equal to 4.5','Exceed Expectation'],['Less than or equal to 5','Exceptional']]
    
    // let startYForSecondTable3 = startYForSecondTable + table1Height2 + 312;
    let content4 = {
      theme: 'grid',
      
      // startY: top_height1 + 20,
      head: tableHeader4,
      tableWidth: (pageWidth / 2),
      body: data4,
      headStyles : {fillColor : [255, 255, 255],textColor: [0, 0, 0],borderColor: [0, 0, 0],lineWidth: 0.2, lineColor: [0,0,0],lineWeight: 'bold',borderColor: [0, 0, 0]},
      bodyStyles: { 
        cell: { 
          cellHeight: 16, 
          
        },
        lineWidth: 'bold',
        lineWidth: 0.5, 
        lineColor: [0, 0, 0], 
      },
      //footStyles : {fillColor : [255,255,255]},
      footStyles :{textColor: [0, 0, 0],fillColor : [255,255,255],lineWidth: 0.2, lineColor: [220,220,220],borderColor: [0, 0, 0],fontWeight: 'normal'},
      
    };
    doc.autoTable(content4);
   doc.save("billingreport.pdf");
   
    // var tableHeader = [
    //   [
    //     { colSpan: 2, content: 'KRO Targets - Productivity', styles: { halign: 'center' } }
    //   ],
    //   [
    //     'Target', 'Rating'
    //   ]
    // ];
    // const data2 = [['Upto 75%','Needs Action'],['>75% to 90%','Below Expectation'],['>90% to 100%','Meet Expectation'],['>100% to 115%','Exceed Expectation'],['>115%','Exceptional']]
    // let content2 = {
    //   theme: 'grid',
    //   // startY: startYForSecondTable,
    //   head: tableHeader,
    //   tableWidth: (pageWidth / 2),
    //   body: data2,
    //   headStyles : {fillColor : [255, 255, 255],textColor: [0, 0, 0],borderColor: [0, 0, 0],lineWidth: 0.2, lineColor: [0,0,0],lineWeight: 'bold',borderColor: [0, 0, 0]},
    //   bodyStyles: { 
    //     cell: { 
    //       cellHeight: 18, 
          
    //     },
    //     lineWidth: 'bold',
    //     lineWidth: 0.5, 
    //     lineColor: [0, 0, 0], 
    //   },
    //   //footStyles : {fillColor : [255,255,255]},
    //   footStyles :{textColor: [0, 0, 0],fillColor : [255,255,255],lineWidth: 0.2, lineColor: [220,220,220],borderColor: [0, 0, 0],fontWeight: 'normal'},
      
    // };

    // const tableHeader3 = [
    //   [
    //     { colSpan: 3, content: 'Accuracy Sources', styles: { halign: 'center' } }
    //   ],
    //   [
    //     'Score - Associates/ Doers', 'Rating','Rating'
    //   ]
    // ];
    
    // const data3 = [['0 errors per job','Exceptional','5'],['Less than or equal to 1 error per job','Exceed Expectation','4'],['Less than or equal to 2 error per job','Meet Expectation','3'],['Less than or equal to 3 error per job','Below Expectation','2'],['More than 3 errors','Needs Action','1']]
    // let table1Height2 = calculateTableHeight(content2);
    // let startYForSecondTable2 = startYForSecondTable + table1Height2 + 112;
    // let content3 = {
    //   theme: 'grid',
    //   // startY: startYForSecondTable2,
    //   head: tableHeader3,
    //   tableWidth: 500,
    //   body: data3,
    //   headStyles : {fillColor : [255, 255, 255],textColor: [0, 0, 0],borderColor: [0, 0, 0],lineWidth: 0.2, lineColor: [0,0,0],lineWeight: 'bold',borderColor: [0, 0, 0]},
    //   bodyStyles: { 
    //     cell: { 
    //       cellHeight: 18, 
          
    //     },
    //     lineWidth: 'bold',
    //     lineWidth: 0.5, 
    //     lineColor: [0, 0, 0], 
    //   },
    //   foot: [
    //     [ { colSpan: 3, content: 'If the error rate is low and severity is high, it will be considered as rating 1'}] ,
    //     [ { colSpan: 3, content: 'If there are 4 or more than 4 external errors in a single job, Accuracy rating will be 1' } ] 
    // ],
    //   footStyles :{fillColor : [255, 255, 255],textColor: [0, 0, 0],borderColor: [0, 0, 0],lineWidth: 0.2, lineColor: [0,0,0],lineWeight: 'bold',borderColor: [0, 0, 0]},
      
    // };
    //doc.autoTable(content3);
  //for bestpracticestable
  // let tableHeaderbestpractice = [ [
  //   { colSpan: 3, content: 'Accuracy Sources - Best practices', styles: { halign: 'center' } }
  // ],
  // [
  //   'Score - TLs', 'Rating','Rating'
  // ]];
  // let dataforbestpractice = [['0 errors','Exceptional','5'],['Less than or equal to 2 errors','Exceed Expectation','4'],['Less than or equal to 3 errors','Meet Expectation','3'],['Less than or equal to 4 errors','Below Expectation','2'],['More than 4 errors','Needs Action','1']];
  // let bestpracticestable = {
  //   theme: 'grid',
  //   // startY: startYForSecondTable2,
  //   head: tableHeaderbestpractice,
  //   tableWidth: 500,
  //   body: dataforbestpractice,
  //   headStyles : {fillColor : [255, 255, 255],textColor: [0, 0, 0],borderColor: [0, 0, 0],lineWidth: 0.2, lineColor: [0,0,0],lineWeight: 'bold',borderColor: [0, 0, 0]},
  //   bodyStyles: { 
  //     cell: { 
  //       cellHeight: 18, 
        
  //     },
  //     lineWidth: 'bold',
  //     lineWidth: 0.5, 
  //     lineColor: [0, 0, 0], 
  //   },
    
   
    
  // };
  //end bestpracticestable
    //// for retention table

    // const tableHeader5 = [
    //   [
    //     { colSpan: 3, content: 'Retention', styles: { halign: 'center' } }
    //   ],
    //   [
    //     'Attrition %', 'Rating','Rating'
    //   ]
    // ];
    // const data5 = [['below 20 %','Exceptional','5'],['20% to 25%','Exceed Expectation','4'],['25% to 30%','Meet Expectation','3'],['30% to 40%','Below Expectation','2'],['More than 40%','Needs Action','1']]
    // let startYForSecondTable5 = startYForSecondTable + table1Height2 + 112;
    // let content5 = {
    //   theme: 'grid',
      
    //   // startY: top_height1 + 20,
    //   head: tableHeader5,
    //   tableWidth: (pageWidth / 2),
    //   body: data5,
    //   headStyles : {fillColor : [255, 255, 255],textColor: [0, 0, 0],borderColor: [0, 0, 0],lineWidth: 0.2, lineColor: [0,0,0],lineWeight: 'bold',borderColor: [0, 0, 0]},
    //   bodyStyles: { 
    //     cell: { 
    //       cellHeight: 16, 
          
    //     },
    //     lineWidth: 'bold',
    //     lineWidth: 0.5, 
    //     lineColor: [0, 0, 0], 
    //   },
    //   //footStyles : {fillColor : [255,255,255]},
    //   footStyles :{textColor: [0, 0, 0],fillColor : [255,255,255],lineWidth: 0.2, lineColor: [220,220,220],borderColor: [0, 0, 0],fontWeight: 'normal'},
      
    // };
  
    //// for retention table
    //onetimebillingtable
    // let tableHeaderonetimebilling = [ [
    //   { colSpan: 3, content: 'On time Billing', styles: { halign: 'center' } }
    // ],
    // [
    //   'Working days', 'Rating','Rating'
    // ]];
    // let dataforonetimebilling = [['1st working day','Exceptional','5'],['2nd to 3rd working day','Exceed Expectation','4'],['4th to 5th working day','Meet Expectation','3'],['4th to 7th working day','Below Expectation','2'],['More than 7th working day','Needs Action','1']];
    // let onetimebillingtable = {
    //   theme: 'grid',
    //   // startY: startYForSecondTable2,
    //   head: tableHeaderonetimebilling,
    //   tableWidth: 500,
    //   body: dataforonetimebilling,
    //   headStyles : {fillColor : [255, 255, 255],textColor: [0, 0, 0],borderColor: [0, 0, 0],lineWidth: 0.2, lineColor: [0,0,0],lineWeight: 'bold',borderColor: [0, 0, 0]},
    //   bodyStyles: { 
    //     cell: { 
    //       cellHeight: 18, 
          
    //     },
    //     lineWidth: 'bold',
    //     lineWidth: 0.5, 
    //     lineColor: [0, 0, 0], 
    //   },
      
     
      
    // };
    //endonetimebillingtable
    //for typeid 8 and 10
   
    // let tableHeader8 = [ [
    //   { colSpan: 3, content: 'MIS Updation', styles: { halign: 'center' } }
    // ],
    // [
    //   'Score - Associates/ Doers', 'Rating','Rating'
    // ]];
    // let data8 = [['0','Exceptional','5'],['Less than or equal to 1','Exceed Expectation','4'],['Less than or equal to 2','Meet Expectation','3'],['Less than or equal to 3 error per job','Below Expectation','2'],['More than 3','Needs Action','1']];
    // let content8 = {
    //   theme: 'grid',
    //   // startY: startYForSecondTable2,
    //   head: tableHeader8,
    //   tableWidth: 500,
    //   body: data8,
    //   headStyles : {fillColor : [255, 255, 255],textColor: [0, 0, 0],borderColor: [0, 0, 0],lineWidth: 0.2, lineColor: [0,0,0],lineWeight: 'bold',borderColor: [0, 0, 0]},
    //   bodyStyles: { 
    //     cell: { 
    //       cellHeight: 18, 
          
    //     },
    //     lineWidth: 'bold',
    //     lineWidth: 0.5, 
    //     lineColor: [0, 0, 0], 
    //   },
      
     
      
    // };
    // let tableHeader10 = [ [
    //   { colSpan: 3, content: 'Shift Adherance - WFO', styles: { halign: 'center' } }
    // ],
    // [
    //   'Score - Associates/ Doers', 'Rating','Rating'
    // ]];
    // let content10 = {
    //   theme: 'grid',
    //   // startY: startYForSecondTable2,
    //   head: tableHeader10,
    //   tableWidth: 500,
    //   body: data8,
    //   headStyles : {fillColor : [255, 255, 255],textColor: [0, 0, 0],borderColor: [0, 0, 0],lineWidth: 0.2, lineColor: [0,0,0],lineWeight: 'bold',borderColor: [0, 0, 0]},
    //   bodyStyles: { 
    //     cell: { 
    //       cellHeight: 18, 
          
    //     },
    //     lineWidth: 'bold',
    //     lineWidth: 0.5, 
    //     lineColor: [0, 0, 0], 
    //   },
      
     
      
    // };
    //end for typeid 8 and 10
    //for typeid9
    // let tableHeader9 = [ [
    //   { colSpan: 3, content: 'Leave Adherance', styles: { halign: 'center' } }
    // ],
    // [
    //   'Score - Associates/ Doers', 'Rating','Rating'
    // ]];
    // let data9 = [['0','Exceptional','5'],['Less than or equal to 1','Meet Expectation','3'],['More than 1','Needs Action','1']];
    // let content9 = {
    //   theme: 'grid',
    //   // startY: startYForSecondTable2,
    //   head: tableHeader9,
    //   tableWidth: 500,
    //   body: data9,
    //   headStyles : {fillColor : [255, 255, 255],textColor: [0, 0, 0],borderColor: [0, 0, 0],lineWidth: 0.2, lineColor: [0,0,0],lineWeight: 'bold',borderColor: [0, 0, 0]},
    //   bodyStyles: { 
    //     cell: { 
    //       cellHeight: 18, 
          
    //     },
    //     lineWidth: 'bold',
    //     lineWidth: 0.5, 
    //     lineColor: [0, 0, 0], 
    //   },
      
     
      
    // };
    //end for typeid 9

    // if(trans_data && trans_data.length && trans_data != null){
    //   trans_data.map((item, i)=>{
    //     if(item.type_id ==1){
    //       doc.autoTable(content2);
    //     }
    //     else if(item.type_id ==2){
    //       doc.autoTable(content3);
    //     }else if(item.type_id ==4){
    //       doc.autoTable(bestpracticestable);
    //     }else if(item.type_id ==6){
    //       doc.autoTable(content5);
    //     }else if(item.type_id ==7){
    //       doc.autoTable(onetimebillingtable);
    //     }else if(item.type_id == 8 ){
    //       doc.autoTable(content8);
    //     }else if(item.type_id==10){
    //       doc.autoTable(content10);
    //     }else if(item.type_id==9){
    //       doc.autoTable(content9);
    //     }else{

    //     }

    //   })
    // }
  
    //table 6
    // const tableHeader6 = [
    //   [
    //     { colSpan: 3, content: 'Accuracy Sources - Best practices', styles: { halign: 'center' } }
    //   ],
    //   [
    //     'Score - TLs', 'Rating','Rating'
    //   ]
    // ];
    // const data6 = [['0 errors','Exceptional','5'],['Less than or equal to 2 errors','Exceed Expectation','4'],['Less than or equal to 3 errors	','Meet Expectation','3'],['Less than or equal to 4 errors','Below Expectation','2'],['More than 4 errors','Needs Action','1']]
    // // let startYForSecondTable6 = startYForSecondTable + table1Height2 + 212;
    // let content6 = {
    //   theme: 'grid',
      
    //   // startY: top_height1 + 20,
    //   head: tableHeader6,
    //   tableWidth: (pageWidth / 2),
    //   body: data5,
    //   headStyles : {fillColor : [255, 255, 255],textColor: [0, 0, 0],borderColor: [0, 0, 0],lineWidth: 0.2, lineColor: [0,0,0],lineWeight: 'bold',borderColor: [0, 0, 0]},
    //   bodyStyles: { 
    //     cell: { 
    //       cellHeight: 16, 
          
    //     },
    //     lineWidth: 'bold',
    //     lineWidth: 0.5, 
    //     lineColor: [0, 0, 0], 
    //   },
    //   //footStyles : {fillColor : [255,255,255]},
    //   footStyles :{textColor: [0, 0, 0],fillColor : [255,255,255],lineWidth: 0.2, lineColor: [220,220,220],borderColor: [0, 0, 0],fontWeight: 'normal'},
      
    // };
    //doc.autoTable(content6);
    //table 6 end
  
 
};
   return (<Button
    className="dash-button"
    color="danger"
    type="button"
    
    onClick={() => handleDownload(0)}
    
  >
    Export to PDF
  </Button>); 
}
export default KROCalculationPDF