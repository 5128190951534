import { useState, useEffect,useRef } from "react";
import React from 'react';
import {
  Button,
  Card,
  Table,
  Container,
  CardFooter,
  Row,
  Input,
  Pagination,
  PaginationItem,
  PaginationLink
} from "reactstrap";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-theme-alpine-dark.css";
import "../../assets/css/custom.css";
import Header from "components/Headers/Header.js";
import { clientmanageService } from "../../services/clientmanageService.js";
import 'assets/css/themecustom-style.css';
import { toast } from 'react-toastify';
import { useHistory,useParams } from "react-router-dom";
import Select from 'react-select';
const axios = require("axios");
import { usermanageService } from "../../services/usermanageService";

import { taskmanageService } from "../../services/taskmanageService";

const FeedbackChecklist = (props) => {
  const params  = useParams();
  const user_info = JSON.parse(localStorage.getItem("user-info"));
  const [trans_data,settransData] = useState([]);

useEffect(() => {
  getTransitionData();
  getclient_list();
}, []);
const [currentPage, setCurrentPage] = useState(null);
  const [last_page, setLastPageData] = useState(0);
  const handlePageClick = (e, index) => {
    e.preventDefault();
    getTransitionData(index);
  };

  const handlePreviousClick = () => {
    const prev = currentPage - 1;
    getTransitionData(prev);
  };

  const handleNextClick = () => {
    const next = currentPage + 1;
    getTransitionData(next);
  };

  const getTransitionData = async (pageNumber = 1,filterFields='') => {
  clientmanageService.getChecklistFeedback(pageNumber,filterFields).then((response) => {
      
        if(response.status === 200){
          settransData(response.data.data.data)
          setCurrentPage(response.data.data.current_page);
          setLastPageData(response.data.data.last_page);
        }else {
          toast.error('Something went wrong!');
        }
    });
  }

  const [clientData, setclientData] = useState([]);
  function getclient_list() {
    usermanageService.getmanager_list().then((response) => {
      setclientData(response.data.client);
    });
  }

  function setclient(e){
    subclientList(e.label)

  }

  const [ subclientlist, setsubclientlist ] = useState([{'label':'Select Sub Client',value:''}])

  function subclientList(clientname=''){
    //setsubclientlist([]);
    clientmanageService.getsubclientlistsearchable(clientname).then((response) => {
        if(response.status === 200){
          setsubclientlist(response.data)
        }else {
          toast.error('Something went wrong!');
        }
    });
  }

  const [filterFields,setfilterFields] = useState([]);
  const companyfilter = (e,index,field) => {
    
    const cloneValues = {...filterFields};
    cloneValues[index] = {
      ...cloneValues[index],
      ['field']: field,
      ['value']: encodeURIComponent(e.label),
      ['operator']: '='
    };
    setfilterFields(cloneValues);
    getTransitionData(1,cloneValues);
  }
  const searchData = (e,index,field)=>{
    const cloneValues = {...filterFields};
    cloneValues[index] = {
      ...cloneValues[index],
      ['field']: e.target.name,
      ['value']: e.target.value,
      ['operator']: '='
    };
    setfilterFields(cloneValues);
    getTransitionData(1,cloneValues);
  }
  function downloadReport(){
    clientmanageService.checklist_feedback_report(filterFields).then((response) => {
     
    });
  }
  const handleeditRow = (index) => {
    let temp_state = [...trans_data];
    let temp_element = { ...temp_state[index] };
    temp_element.editing = true;
    temp_state[index] = temp_element;
    settransData(temp_state);
};

function inputhandlerchecklist(e, field, index,id) {
  
  let temp_m = [...trans_data];
  let temp_ele = { ...temp_m[index] };
  var value = e.target.value;
  if(field == 'qa_rejection_date'){
    temp_ele['rejection_date'] = value;
  }
  temp_ele[field] = value;
  temp_ele.editing = false;
  temp_m[index] = temp_ele;
  settransData(temp_m);
  taskmanageService.editChecklistUpdate(value, field, id).then((response) => {

  })
}
const removeRow = (id,type)=>{
  swal({
    title: "Are you sure want to remove?",
    text: "",
    icon: "warning",
    buttons: true,
    dangerMode: true,
  })
  .then((isConfirm) => {
    if (isConfirm){
    taskmanageService.removeChecklistRow(id,type).then((response) => {
    if(response.status == 200){
    getTransitionData(1);
     toast.success(response.data.message);
     //window.location.reload(); 
    }else{
      toast.error(response.data.message);
    }
  });
}
});
}
  return (
    <>
      <Header />
      <Container className="mt--7"  fluid>
      <h3>Checklist Feedback</h3>
        <Row className="mt-5">
          <div className="col-2">
          <label>Client</label>
        <Select 
          className="input-group-alternative mb-3" 
          name="client"
          searchable 
          options={clientData} // set list of the data
          onChange={(e) => { setclient(e,0,'client');companyfilter(e,1,'client')}}
        /></div><div className="col-2">
        <label>Sub Client</label>
        <Select 
                       
          className="input-group-alternative mb-3" 
          name="sub_client"
          options={subclientlist} // set list of the data
          onChange={(e) => companyfilter(e,1,'sub_client')}
          defaultValue={subclientlist}
          searchable
          isClearable={true}
          /></div>
          <div className="col-2">
          <label>Date</label>
                      <input type="date"
                            className="form-control"
                            name="date"
                            onChange={(e) =>
                              searchData(e,3,'date')
                              }
                            />
          </div>
          <div className="col-2">
          <label>Category</label>
            <Input placeholder="category" id="companydata" name="category"  type="text" style={{fontWeight:"600",color:"black"}}
                onKeyUp={(e) =>searchData(e,4)}  
                /></div>
                <div className="col-2">
                <label>Responsibility</label>
            <Input placeholder="responsibility" id="companydata" name="responsibility"  type="text" style={{fontWeight:"600",color:"black"}}
                onKeyUp={(e) =>searchData(e,5)}  
                /></div>
                          <div className="col-2">
                            <br></br>
             <Button
                      className="col-12"
                      type="button"
                      onClick={downloadReport}
                      style={{textAlign:'center'}}
                      title="Download Report"
                    >
                    <i className="fas fa-download" style={{fontSize:'14px'}}></i> Export 
                    </Button>
          </div>
        </Row>      
        <Row >
          <div className="col" style={{padding:'0%'}}>
            <Card className="shadow">
        
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                    <th>Client Name</th>
                    <th scope="col" className="whitespace">Subclient Name</th>
                    <th scope="col" className="whitespace">Team</th>
                    <th scope="col" className="whitespace">Category</th>
                    <th scope="col" className="whitespace">Sub Category</th>
                    <th scope="col" className="whitespace">Checklist</th>
                    <th scope="col" className="whitespace">Responsibility</th>
                   
                    {user_info.role_id == 2 || user_info.role_id == 3 || user_info.role_id == 12 || user_info.role_id == 14 ?
                    <>
                    <th scope="col" className="whitespace">Rejection raised by (For QA)</th>
                    <th scope="col" className="whitespace">Rejection for QA</th>
                    <th scope="col" className="whitespace">Rejection date QA</th>
                    <th scope="col" className="whitespace">QA Feedback / Remarks  </th>
                    <th scope="col" className="whitespace">Phase Feedback By  </th>
                    <th scope="col" className="whitespace">Rejection for phase</th>
                    <th scope="col" className="whitespace">Phase Rejection Date  </th>
                    <th scope="col" className="whitespace">Phase Feedback  </th>
                    </>
                    :
                    <>
                    <th scope="col" className="whitespace">Phase Feedback By  </th>
                    <th scope="col" className="whitespace">Rejection for phase</th>
                    <th scope="col" className="whitespace">Phase Rejection Date  </th>
                    <th scope="col" className="whitespace">Phase Feedback  </th>
                    </>
                    }
                    <th scope="col" className="whitespace">Rejection type </th>
                    {user_info.role_id==14 ?
                    <th scope="col" className="whitespace">Action </th>:null}
                    
                </thead>
                <tbody>
           
                { trans_data && trans_data.length
                          ?                               
                          trans_data.map((tdata,i) => (
                              <tr key={i} className={((i % 2 == 0)? 'purple-row': 'orange-row' )}>
                                <td className="whitespace">{tdata.name}</td>
                                <td className="whitespace">{tdata.sub_client}</td>
                                <td className="whitespace">{tdata.team}</td>
                                <td className="whitespace">{tdata.sub_cat}</td>
                                <td className="whitespace">{tdata.category}</td>
                                <td className="whitespace">{tdata.checklist}</td>
                                <td className="whitespace">{tdata.responsibility}</td>
                                <td>{tdata.raised_error_by}</td>
                                <td>{tdata.rejection_tm}</td>
                                <td onClick={()=> handleeditRow(i)}>
                                  {tdata.editing && (user_info.role_id == 2 || user_info.role_id == 12 || user_info.role_id == 14) ? (
                                  
                                   <input type="date"
                                   className="form-control"
                                     defaultValue={tdata.qa_rejection_date}
                                     onBlur={(e) =>
                                      inputhandlerchecklist(
                                        e,
                                        "qa_rejection_date",
                                        i,tdata.id
                                      )}
                                   />
                                  ) : <span>{tdata.rejection_date}</span> }
                              </td>
                                <td className="whitespace">{tdata.feedback}</td>
                                {user_info.role_id == 2 || user_info.role_id == 3 || user_info.role_id == 12 || user_info.role_id == 14 ?
                                <>
                                <td>{tdata.phasefeedbackbyname}</td>
                                <td>{tdata.phaserejection_tm}</td>
                                <td onClick={()=> handleeditRow(i)}>
                                  {tdata.editing  && (user_info.role_id == 2 || user_info.role_id == 12 || user_info.role_id == 14) ? (
                                  
                                   <input type="date"
                                   className="form-control"
                                     defaultValue={tdata.phase_rejection_date}
                                     onBlur={(e) =>
                                      inputhandlerchecklist(
                                        e,
                                        "phase_rejection_date",
                                        i,tdata.id
                                      )}
                                   />
                                  ) : <span>{tdata.phase_rejection_date}</span> }
                              </td>
                                <td className="whitespace">{tdata.phase_feedback}</td>
                                </>
                                :null}
                                <td className="whitespace" onClick={()=> handleeditRow(i)}>
                                  {tdata.editing  ? (
                                    <select
                                      className="form-control"
                                      onChange={(e) =>
                                        inputhandlerchecklist(
                                          e,
                                          "rejection_type",
                                          i,tdata.id
                                        )
                                      }
                                      defaultValue={tdata.rejection_type}
                                    >
                                       <option value="">Select</option>
                                      <option value="Fetal">Fetal</option>
                                        <option value="Non-Fetal">Non-Fetal</option>
                                    </select>
                                  ) : (tdata.rejection_type!=null)?tdata.rejection_type:''}
                                </td>
                                { user_info.role_id==14 ?
                                <td>
                                  {tdata.qa_rejection && user_info.role_id==14 ? 
                                <button type="button" className="btn btn-danger" onClick={() => removeRow(tdata.id,'qa_rejection')}>
                                <i className="fa fa-remove"></i> QA Delete
                                </button> : null}
                                {tdata.phase_rejection ? 
                                <button type="button" className="btn btn-danger" onClick={() => removeRow(tdata.id,'phase_rejection')}>
                                <i className="fa fa-remove"></i> Phase Delete
                                </button> : null}
                                 
                                </td>:null}
                              </tr>
                            ))
                          :
                           ( <p>No Data Found</p> )}
                         
        
                </tbody>
              </Table>
              <CardFooter className="py-4">
                <nav aria-label="...">
                  <Pagination
                    className="pagination justify-content-end mb-0"
                    listClassName="justify-content-end mb-0"
                  >
                    <PaginationItem disabled={currentPage <= 1}>
                      <PaginationLink onClick={handlePreviousClick} previous />
                    </PaginationItem>
                    {[...Array(last_page)].map((page, i) => (
                      <PaginationItem
                        active={i + 1 === currentPage}
                        key={i + 1}
                      >
                        <PaginationLink
                          onClick={(e) => handlePageClick(e, i + 1)}
                        >
                          {i + 1}
                        </PaginationLink>
                      </PaginationItem>
                    ))}
                    <PaginationItem disabled={currentPage >= last_page}>
                      <PaginationLink onClick={handleNextClick} next />
                    </PaginationItem>
                  </Pagination>
                </nav>
              </CardFooter>
              
            </Card>
          </div>
        </Row>
        

      </Container>
    </>
  );
};


export default FeedbackChecklist;
