import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import React from 'react';
import {
  Button,
  Card,
  CardHeader,
  Table,
  Container,
  Row,
  Form,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-theme-alpine-dark.css";
import "../../../assets/css/custom.css";
import Header from "../../../components/Headers/Header.js";
import 'assets/css/themecustom-style.css';
import '@flowjs/flow.js';
import { ToastContainer, toast } from 'react-toastify';
import Select from 'react-select';
import { usermanageService } from "../../../services/usermanageService";
import jsPDF from 'jspdf';
import 'jspdf-autotable';
const axios = require("axios");
import KROCalculationPDF from "./KROCalculationPDF";

const KROCalculation = (props) => {
  const params  = useParams();
  const [trans_data,settransData] = useState([]);
  const [competency_data,setcompetencyData] = useState([]);
  const [state, setState] = useState({});
  const [disablestate, setdisablestate] = useState(false);
  const [newbox, setNewBox] = useState(0);
  const [kroapproval, setApproval] = useState(0);
  const [review_date,setReviewDate] = useState('');
  const [kromanagerapproval, setManagerApproval] = useState(0);
  const [enableSavekrobutton, setenableSavekrobutton] = useState(false);
  const [usersearch, setusersearch] = useState({'label':'Select User',value:'al_key_1',manager:''});
  const months = [{value:'01',label : 'Jan'},{value:'02',label :'Feb'},{value:'03',label :'March'},{value:'04',label :'April'},{value:'05',label :'May'},{value:'06',label :'June'},{value:'07',label :'July'},{value:'08',label :'Aug'},{value:'09',label :'Sept'},{value:10,label :'Oct'},{value:11,label :'Nov'},{value:12,label :'Dec'}];
const [monthdata, setmonthdata] = useState(months);
const [sendData, setSendData] = useState([])
const [uniqueuserslist, setUniqueUsersData] = useState([]);
const [user_data,setuserData] = useState([]);
const [selected_user_role,setuserRole] = useState('');
const [transModalOpen, settransModalOpen] = useState(false);
function get_unique_userslist(){
  usermanageService.getusersforchatbox().then((response)=>{
    setUniqueUsersData(response.data.users);
  });
}

const [krotypes, setKroTypes] = useState([]);
function getkrotypes(){
  usermanageService.getkrotypes().then((response)=>{
    setKroTypes(response.data.data);
  });
}

const user_info = JSON.parse(localStorage.getItem("user-info"));
let user_id = user_info.id;
  if(params.user_id!='undefined' && params.user_id!=undefined){
    user_id = params.user_id;
  }
  let month_year ='';
  if(params.month_year!='undefined' && params.month_year!=undefined){
    month_year = params.month_year;
  }
const initialData = {
  user_id : user_id,
  month_year : month_year
}
const [inputFields, setInputfields] = useState(initialData);

function showKROCalculation(sync=0){
  settransData([])

  console.log(inputFields)
  
    usermanageService.showKROCalculation(inputFields,sync).then((response) => {
     
        if(response.status === 200){
       if(inputFields.user_id != ''){
        setusersearch({'label':response.data.user.name, value : response.data.user.id,manager : response.data.user.manager})
       }
       console.log(typeof(response.data.data))
          settransData(response.data.data)
          setSendData(response.data.send_data)
          // setAccuracyData(response.data.accuracy)
          // setProductivityData(response.data.productivity)
          setApproval(response.data.approval)
          setManagerApproval(response.data.manager_approval)
          setReviewDate(response.data.review_date)
          setState(response.data.data)      
          setNewBox(response.data.data.length + 1)
          setuserData(response.data.user)
          setcompetencyData(response.data.competency)
          setenableSavekrobutton(response.data.enableSavekrobutton)
          setuserRole(response.data.user_role)
          const type_data = response.data.data.map(data => data.type_id);
          setTypeData(response.data.user_role,type_data)
          // setAccuracyArrData(response.data.accuracy_arr)
          // setProductivityArrData(response.data.productivity_arr)
        }else {
          toast.error(response.data.message);
        }
    });
}
const [type_data,setKROTypeData] = useState([]);
const setTypeData=(active_role,type_data)=>{
  usermanageService.KROTypeRatingList(1,type_data,active_role).then((response) => { 
    if(response){  
      setKROTypeData(response.data.data);
    }
  
  });
}
function inputhandler1(e,field) {
  if(field=='month_year'){
    setInputfields((prevInputFields)=>({ ...prevInputFields, [field]: e.target.value } ));
  }else{
  setInputfields((prevInputFields) => ({
    ...prevInputFields,
    [field]: e.value,
  }));
  setusersearch({'label':e.label,value:e.value,manager:""})
  }

}
function inputhandler(e,index,type_id=null) {
 
  let temp_st = { ...state };
  let temp_ele = temp_st[index] ? { ...temp_st[index] } : {}; 
  
  if (e.target.name === 'rating') {
    temp_ele.rating = e.target.value;
  }
  
  temp_ele[e.target.name] = e.target.value;
  temp_ele.user_id = inputFields.user_id;

  if (temp_ele.unit == 'char' && e.target.value !== 'Y' && e.target.value !== 'N') {
    toast.error("Input must be 'Y' or 'N'");
    return;
}

  if(e.target.name == 'actual_target') {
    // calculate rating
    usermanageService.calculateRating(e.target.value,type_id,inputFields.user_id).then((response) => {
      console.log(response.data.rating)
      let temp_trans_dta = [ ...trans_data ];
      let temp_trans_ele = { ...temp_trans_dta[index] }; 
        temp_trans_ele.rating = response.data.rating;
        temp_trans_ele.weighted_score = (response.data.rating * temp_trans_ele.weightage)/100;
        temp_trans_dta[index] = temp_trans_ele;

        console.log(temp_trans_dta)
        settransData(temp_trans_dta)

        temp_ele.rating = response.data.rating;
        temp_ele.weighted_score = (response.data.rating * temp_trans_ele.weightage)/100;
    });
  }
  
  if (temp_st[index]) {
    temp_st[index] = temp_ele;
  }
  console.log(temp_st)
  setState(temp_st);
 

}

function inputhandlercompetency(e,index,type_id=null) {
 
  let temp_trans_dta = [ ...competency_data ];
  let temp_trans_ele = { ...temp_trans_dta[index] }; 
    temp_trans_ele.rating =  e.target.value;
    temp_trans_ele.user_id = inputFields.user_id;
    temp_trans_dta[index] = temp_trans_ele;

    setcompetencyData(temp_trans_dta)
}

function addsopform(e) {
  console.log(e);
  setdisablestate(true)
  e.preventDefault();
  usermanageService.updateUserKroCalculationForm(state,inputFields.month_year).then((response) => {
    if (response.status == 200) {
      toast.success(response.data.message);
      // location.reload();
    }
    setdisablestate(false)
  });
}


function addcompetencyform(e) {
  console.log(e);
  setdisablestate(true)
  e.preventDefault();
  usermanageService.updateCompetencyForm(competency_data,inputFields.month_year).then((response) => {
    if (response.status == 200) {
      toast.success(response.data.message);
      // location.reload();
    }
    setdisablestate(false)
  });
}
 
const addNewbox = ()=>{
  const setdata = newbox+1;
  setNewBox(setdata)
 
}
const approveKRO = () => {
  setdisablestate(true)
  usermanageService.approveKRO(inputFields).then((response) => {
    if (response.status == 200) {
      toast.success(response.data.message);
      setApproval(1)
    }
    setdisablestate(false)
  });
}

useEffect(() => {
  get_unique_userslist();
  getkrotypes();
  showKROCalculation()
}, []);

let totalFinalweightageValue = 0;
let totalFinalweightagescoreValue = 0;

const [defaultType, setdefaultType] = useState(0);
const [defaultworkedhours, setdefaultworkedhours] = useState(0);
const [defaultindex, setdefaultindex] = useState('');
function showTransCalculation(type,value='',index= ''){
  setdefaultType(type)
  setdefaultworkedhours(value)
  setdefaultindex(index)
  settransModalOpen(!transModalOpen)
}
const editField = (value) => {
  // usermanageService.editKROvalue(value, field, id, type,no_error_data).then((response) => {
    let temp_st = [...state];
    let temp_ele = { ...temp_st[defaultindex] };
   
    temp_st[defaultindex] = temp_ele;
    temp_ele.value = value;
    setState(temp_st);
    setdefaultworkedhours(value)
  // });
};

  return (
    <>
      <Header />
      <Container className="mt--7"  fluid>
    
        <Row className="mt-12" >
          <div className="col" style={{padding:'0%'}}>
            <Card className="shadow">
            
              <CardHeader className="border-0">
              <Row className="filter" style={{padding:'0 1%'}}>
                   <div className="col-xl-4 col-md-3 col-xs-12">
                    <h3 className="clientformtag"> <b> User :</b>  {usersearch.label}</h3>
                   {(uniqueuserslist.length > 0) ? 
                   <Select 
                   
                   className="input-group-alternative mb-3" 
                   name="user_id"
                   options={uniqueuserslist} // set list of the data
                   onChange={(e) => inputhandler1(e,'user_id')}
                   value={usersearch}
                   searchable
                   isClearable={false}
                 />
                 : null }
                  </div>
                  <div className="col-xl-4 col-md-3 col-xs-12">
                    <h3 className="clientformtag"> Select month</h3>
                    <input type="month"
                                      className="form-control"
                                      name="month_year"
                                      onChange={(e) => { inputhandler1(e,'month_year')}}
                                      value={inputFields.month_year}
                                      options={monthdata}
                                      required
                                      />
                                       {inputFields.month_year && <div style={{ marginTop: 20, lineHeight: '25px' }}></div>}
                      
                                  </div>
                  
                  
                    <div className="col-xl-4 col-md-3 col-xs-12">
                      <h3></h3><br></br>
                      <Button className="col-xl-4 btn btn-primary"  onClick={(e) => showKROCalculation(e)}>Show</Button>
                    </div>
                    {/* </div> */}
                </Row>
              </CardHeader>
             


            </Card>
          </div>
        </Row>
        <Row className="mt-12" >
        
        <Card className="shadow"  style={{width:"100%"}}>
          <div>
          {/* <Button
            className="dash-button"
            color="danger"
            type="button"
            
            onClick={() => handleDownload(0)}
            
          >
            Export to PDF
          </Button> */}
         <KROCalculationPDF trans_data={trans_data} user_data={user_data} month_year={inputFields.month_year} review_date ={review_date } type_data={type_data}/>

            <span
              color="primary"
              style={{
                color:
                kroapproval == "1"
                    ? "#02C779"
                    : "#FF1717",
                    textAlign: "center",
              }}
            >{(kroapproval== 1) ? 'Discussed & Approved by Reviewer ' : (kroapproval== 2) ? " Rejected By Reviewer" : "  "}</span>  
<br></br>
            <span
              color="primary"
              style={{
                color:
                kromanagerapproval == "1"
                    ? "#02C779"
                    : "#FF1717",
                    textAlign: "center",
              }}
            >{(kromanagerapproval== 1) ? 'Discussed & Approved by Manager' : (kromanagerapproval== 2) ? "Rejected By Manager" : "  "}</span>     
          {(kromanagerapproval == 2 || kroapproval == 2) ?
          <Button
            className="dash-button"
            color="danger"
            type="button"
            
            onClick={() => showKROCalculation(1)}
            
          >
            Sync Data
          </Button> : null }
          </div>
          <br/>
          <h3>KRO</h3>
            <Form
                role="form"
                id="phase-selection-form"
                onSubmit={addsopform}
            
              >
              <Table  className="align-items-center table-flush" responsive>
              <thead>
                <tr style={{ border: "1px solid #000" }}>
                  <th style={{ border: "1px solid #000" }}>Sr.No.</th>
                  <th style={{ border: "1px solid #000" }} className="whitespace">Particulars</th>
                  <th style={{ border: "1px solid #000" }} className="whitespace">Achieved</th>
                  <th style={{ border: "1px solid #000" }} className="whitespace">Rating</th>
                  <th style={{ border: "1px solid #000" }} className="whitespace">% Weightage</th>
                  <th style={{ border: "1px solid #000" }} className="whitespace">Weighted score</th>
                </tr>
              </thead>
                <tbody>
                {trans_data && trans_data.length && trans_data != null ? (
                  <>
                    { trans_data.map((item, i) => {
                      totalFinalweightageValue += parseFloat(item.weightage);
                      totalFinalweightagescoreValue += ((item.weighted_score) ? parseFloat(item.weighted_score) : 0);
                      return (
                 
                  <tr className="whitespace" style={{border : "1px solid #000"}}>  
                    <td className="whitespace" style={{border : "1px solid #000"}}>{i+1}</td>
                    <td className="whitespace" style={{border : "1px solid #000"}} onClick={(e) => { if (((item.type_id < 8 && user_info.department != 'Payroll') || (item.type_id == 1 || item.type_id == 2 || item.type_id == 6 || item.type_id == 5))) showTransCalculation(item.type_id,item.value,i) }}>
                      {item.type}
                    </td> 
                    <td className="whitespace" style={{border : "1px solid #000"}}>
                    <Input
                            bsSize="sm"
                            name="actual_target"
                            placeholder="Achieved"
                            id="example-date-input"
                            type={(item.unit == 'char') ? "text" : "number"}
                            min="0"
                            // user_info.role_id != 12 &&
                            // readOnly={(kroapproval == 1 || (item.type_id <= 2) && user_info.rolename != 'Payroll') ? true : false }
                            // readOnly={( user_info.role_id != 18 && (user_info.department != 'Payroll' || (item.type_id == 1 || item.type_id == 2 || item.type_id == 6))) ? true : false }
                            readOnly={(user_info.role_id != 18 && user_info.role_id != 19 && item.type_id != 12 && (user_info.department != 'Payroll' || (item.type_id == 1 || item.type_id == 2 || item.type_id == 6 || item.type_id == 5 || item.type_id == 11))) ? true : false }
                           
                            maxLength={(item.unit == 'char') ? 1 : null} 
                            defaultValue={(item.actual_target) ? item.actual_target : (item.type_id==2) ? sendData.accuracy : (item.type_id == 1) ? sendData.productivity : (item.type_id==5) ? sendData.team_average : (item.type_id==6) ? sendData.retention : (item.type_id==4) ? sendData.bestpractices : (item.type_id==11) ? sendData.teamspancount : null}
                            onChange={(e) => inputhandler(e, i,item.type_id)}
                            // onClick={(e) => { if (user_info.role_id != 18 && ((item.type_id < 8 && user_info.department != 'Payroll') || (item.type_id == 1 || item.type_id == 2 || item.type_id == 6))) showTransCalculation(item.type_id) }}
                            onClick={(e) => { if (user_info.role_id != 18 && user_info.role_id != 19 && item.type_id != 12 && (user_info.department != 'Payroll' || (item.type_id == 1 || item.type_id == 2 || item.type_id == 6 || item.type_id == 5 || item.type_id == 11))) showTransCalculation(item.type_id) }}
                        />
                    </td>
                    <td className="whitespace" style={{border : "1px solid #000"}}>
                    <Input
                            bsSize="sm"
                            name="rating"
                            placeholder="Rating"
                            id="example-date-input"
                            type="number"
                            readOnly
                            value={(item.rating) ? item.rating : ''}
                            onChange={(e) => inputhandler(e, i)}
                        />
                    </td>
                    <td className="whitespace" style={{border : "1px solid #000"}}>
                    <Input
                            bsSize="sm"
                            name="weightage"
                            placeholder="weightage"
                            id="example-date-input"
                            type="number"
                            readOnly
                            value={(item.weightage) ? item.weightage : ''}
                            onChange={(e) => inputhandler(e, i)}
                        />
                    </td>
                    <td className="whitespace" style={{border : "1px solid #000"}}>
                    <Input
                            bsSize="sm"
                            name="weighted_score"
                            placeholder="weightage score"
                            id="example-date-input"
                            type="number"
                            readOnly
                            value={(item.weighted_score) ? item.weighted_score :''}
                            onChange={(e) => inputhandler(e, i)}
                        />
                    </td>
                  </tr>
                 
                )
                    }
            )}
          </>
            ) : (
            null
            )}
          {trans_data.length > 0 ? 
                <>
                            <tr className="whitespace" style={{border : "1px solid #000"}}>
                              <td  className="whitespace" style={{border : "1px solid #000"}}></td>
                              <td  className="whitespace" style={{border : "1px solid #000"}}><b>Total</b></td>
                              <td  className="whitespace" style={{border : "1px solid #000"}} colSpan="2"></td>
                              <td  className="whitespace" style={{border : "1px solid #000"}}> <b>{totalFinalweightageValue.toFixed(2)} %</b></td>
                              <td  className="whitespace" style={{border : "1px solid #000"}}> <b>{totalFinalweightagescoreValue.toFixed(2)}</b></td>
                            </tr>
                            <tr className="whitespace" style={{border : "1px solid #000"}}>
                              <td  className="whitespace" style={{border : "1px solid #000"}}></td>
                              <td  className="whitespace" style={{border : "1px solid #000"}}><b>Average Rating</b></td>
                              <td  align="center" className="whitespace" style={{border : "1px solid #000"}} colSpan="4"><b>{(totalFinalweightagescoreValue.toFixed(2) <= 1 ? "Needs Action" : (totalFinalweightagescoreValue.toFixed(2) <= 2.5 ? "Below Expectations" : (totalFinalweightagescoreValue.toFixed(2) <= 3.5 ? "Meets Expectations" : (totalFinalweightagescoreValue.toFixed(2) <= 4.5 ? "Exceed Expectation" : (totalFinalweightagescoreValue.toFixed(2) <= 5 ? "Exceptional" : "")) )))}</b></td>
                            </tr>
                            </>
                  : null }
            </tbody>
              </Table>
              </Form>
            <br></br>
            {enableSavekrobutton}
            <div class="d-flex">
               {((kroapproval != 1 || kromanagerapproval == 2) && enableSavekrobutton ==true) ? 
            <Button color="primary" className="col-xl-3" style={{flex:"0 0 10%"}} type="button" onClick={addsopform} disabled={disablestate}>
            
            {disablestate ? 'Sending...' : 'Save KRO'}
              </Button>  : null }
            </div>
              <br></br>
            <Form
                role="form"
                id="phase-selection-form"
                onSubmit={addcompetencyform}
            
              >
                   <h3>Competency ratings</h3>
            <Table  className="align-items-center table-flush" responsive>
              <thead>
                <tr style={{ border: "1px solid #000" }}>
                  <th style={{ border: "1px solid #000" }}>Sr.No.</th>
                  <th style={{ border: "1px solid #000" }} className="whitespace">Particulars</th>
                  <th style={{ border: "1px solid #000" }} className="whitespace">Rating</th>
                </tr>
              </thead>
                <tbody>
                {competency_data && competency_data.length && competency_data != null ? (
                  <>
                    { competency_data.map((item, i) => {
                      return (
                 
                  <tr className="whitespace" style={{border : "1px solid #000"}}>  
                    <td className="whitespace" style={{border : "1px solid #000"}}>{i+1}</td>
                    <td className="whitespace" style={{border : "1px solid #000"}} >
                      {item.name}
                    </td> 
                    <td className="whitespace" style={{border : "1px solid #000"}}>
                    <Input
                            bsSize="sm"
                            name="rating"
                            placeholder="Rating"
                            id="example-date-input"
                            type="number"
                            required
                            readOnly={( user_info.id == inputFields.user_id) ? true : false }
                            value={(item.rating) ? item.rating : ''}
                            onChange={(e) => inputhandlercompetency(e, i)}
                        />
                    </td>
                    </tr>
                     )
                    }
                  )}
                </>
                  ) : (
                  null
                  )}
                </tbody>
            </Table>
            </Form>
            <br></br>
          {inputFields.user_id != user_info.id ? (
           <>
            <div class="d-flex">
               {(kroapproval != 1 || kromanagerapproval == 2) ? 
            <Button color="primary" className="col-xl-2" style={{flex:"0 0 16%"}} type="button" onClick={addcompetencyform} disabled={disablestate}>
            
            {disablestate ? 'Sending...' : 'Save Competency'} 
              </Button>  : null }
              </div>
              <br></br>
              </>
          ): null }
              {/* {(kroapproval != 0) ? 
              (kroapproval == 1) ? <Button
                                  color="primary"
                                  style={{
                                    background: "#02C779",
                                  border: "#02C779",
                                  }}
                                >R. Approved</Button> :
                                (usersearch.manager == user_info.id) ?
              <Button
                                color="primary"
                                className="col-xl-1"
                                style={{
                                  background: "#21c0f3",
                                  border: "#21c0f3",
                                }}
                                size="sm"
                                type="button"
                                onClick={(e) =>
                                  approveKRO()
                                }
                              >
                               Click to Approve
                              </Button>  : null 
                              : null } */}
            
             
              <Row>
              {trans_data && trans_data.length && trans_data != null ? (
                    
              //       (item.type_id == 1) ? ( // for productivty table 
             
              // <Table className="align-items-center table-flush col-xl-3" style={{marginRight:"20px"}}>
              //   <thead style={{ border: "1px solid #000" }} className="thead-light">
              //     <th colSpan={2} style={{ border: "1px solid #000",textAlign:"center" }} scope="col">KRO Targets - Productivity</th>
              //   </thead>
              //   <thead style={{ border: "1px solid #000" }} className="thead-light">
              //     <th style={{ border: "1px solid #000" }} scope="col">Target</th>
              //     <th style={{ border: "1px solid #000" }} scope="col">Rating</th>
              //   </thead>
              //   <tbody>
              //     <tr>
              //       <td style={{ border: "1px solid #000" }}>Upto 75%</td>
              //       <td style={{ border: "1px solid #000" }}>Needs Action</td>
              //     </tr>
              //     <tr>
              //       <td style={{ border: "1px solid #000" }}>{'>'}75% to 90%</td>
              //       <td style={{ border: "1px solid #000" }}>Below Expectation</td>
              //     </tr>
              //     <tr>
              //       <td style={{ border: "1px solid #000" }}>{'>'}90% to 100%</td>
              //       <td style={{ border: "1px solid #000" }}>Meet Expectation</td>
              //     </tr>
              //     <tr>
              //       <td style={{ border: "1px solid #000" }}>{'>'}100% to 115%</td>
              //       <td style={{ border: "1px solid #000" }}>Exceed Expectation</td>
              //     </tr>
              //     <tr>
              //       <td style={{ border: "1px solid #000" }}>{'>'}115%</td>
              //       <td style={{ border: "1px solid #000" }}>Exceptional</td>
              //     </tr>
              //   </tbody>
              // </Table>
              // ) :   (item.type_id == 2) ? ( // for accuracy table 
              // <Table className="align-items-center table-flush col-xl-4 " style={{marginRight:"20px"}}>
              //   <thead style={{ border: "1px solid #000" }} className="thead-light">
              //     <th colSpan={3} style={{ border: "1px solid #000",textAlign:"center" }} scope="col">Accuracy Sources </th>
              //   </thead>	 	 
              //   <thead style={{ border: "1px solid #000" }} className="thead-light">
              //     <th style={{ border: "1px solid #000" }} scope="col">Score - Associates/ Doers</th>
              //     <th style={{ border: "1px solid #000" }} scope="col">Rating</th>
              //     <th style={{ border: "1px solid #000" }} scope="col">Rating</th>
              //   </thead>
              //   <tbody>
              //     <tr>
              //       <td style={{ border: "1px solid #000" }}>0 errors per job</td>
              //       <td style={{ border: "1px solid #000" }}>Exceptional</td>
              //       <td style={{ border: "1px solid #000" }}>5</td>
              //     </tr>
              //     <tr>
              //       <td style={{ border: "1px solid #000" }}>Less than or equal to 1 error per job</td>
              //       <td style={{ border: "1px solid #000" }}>Exceed Expectation</td>
              //       <td style={{ border: "1px solid #000" }}>4</td>
              //     </tr>
              //     <tr>
              //       <td style={{ border: "1px solid #000" }}>Less than or equal to 2 error per job</td>
              //       <td style={{ border: "1px solid #000" }}>Meet Expectation</td>
              //       <td style={{ border: "1px solid #000" }}>3</td>
              //     </tr>
              //     <tr>
              //       <td style={{ border: "1px solid #000" }}>Less than or equal to 3 error per job</td>
              //       <td style={{ border: "1px solid #000" }}>Below Expectation</td>
              //       <td style={{ border: "1px solid #000" }}>2</td>
              //     </tr>
              //     <tr>
              //       <td style={{ border: "1px solid #000" }}>More than 3 errors</td>
              //       <td style={{ border: "1px solid #000" }}>Needs Action</td>
              //       <td style={{ border: "1px solid #000" }}>1</td>
              //     </tr>
              //     <tr>
              //       <td style={{ border: "1px solid #000",background:"#fff9b0" }} colspan={3}>If the error rate is low and severity is high, it will be considered as rating 1	 	</td>
              //     </tr>
              //     <tr>
              //       <td style={{ border: "1px solid #000",background:"#fff9b0"  }} colspan={3}>If there are 4 or more than 4 external errors in a single job, Accuracy rating will be 1	 	</td>
              //     </tr>
              //   </tbody>
              // </Table>
              // )
              // :   (item.type_id == 4) ? ( // for best practices table 
              // <Table className="align-items-center table-flush col-xl-4 " style={{marginRight:"20px"}}>
              //   <thead style={{ border: "1px solid #000" }} className="thead-light">
              //     <th colSpan={3} style={{ border: "1px solid #000",textAlign:"center" }} scope="col">Accuracy Sources - Best practices </th>
              //   </thead>	 	 
              //   <thead style={{ border: "1px solid #000" }} className="thead-light">
              //     <th style={{ border: "1px solid #000" }} scope="col">Score - TLs</th>
              //     <th style={{ border: "1px solid #000" }} scope="col">Rating</th>
              //     <th style={{ border: "1px solid #000" }} scope="col">Rating</th>
              //   </thead>
              //   <tbody>
              //     <tr>
              //       <td style={{ border: "1px solid #000" }}>0 errors</td>
              //       <td style={{ border: "1px solid #000" }}>Exceptional</td>
              //       <td style={{ border: "1px solid #000" }}>5</td>
              //     </tr>
              //     <tr>
              //       <td style={{ border: "1px solid #000" }}>Less than or equal to 2 errors</td>
              //       <td style={{ border: "1px solid #000" }}>Exceed Expectation</td>
              //       <td style={{ border: "1px solid #000" }}>4</td>
              //     </tr>
              //     <tr>
              //       <td style={{ border: "1px solid #000" }}>Less than or equal to 3 errors</td>
              //       <td style={{ border: "1px solid #000" }}>Meet Expectation</td>
              //       <td style={{ border: "1px solid #000" }}>3</td>
              //     </tr>
              //     <tr>
              //       <td style={{ border: "1px solid #000" }}>Less than or equal to 4 errors</td>
              //       <td style={{ border: "1px solid #000" }}>Below Expectation</td>
              //       <td style={{ border: "1px solid #000" }}>2</td>
              //     </tr>
              //     <tr>
              //       <td style={{ border: "1px solid #000" }}>More than 4 errors</td>
              //       <td style={{ border: "1px solid #000" }}>Needs Action</td>
              //       <td style={{ border: "1px solid #000" }}>1</td>
              //     </tr>
                 
              //   </tbody>
              // </Table>
              // )
              // :   (item.type_id == 6) ? ( // for retention table 
              // <Table className="align-items-center table-flush col-xl-4 " style={{marginRight:"20px"}}>
              //   <thead style={{ border: "1px solid #000" }} className="thead-light">
              //     <th colSpan={3} style={{ border: "1px solid #000",textAlign:"center" }} scope="col">Retention </th>
              //   </thead>	 	 
              //   <thead style={{ border: "1px solid #000" }} className="thead-light">
              //     <th style={{ border: "1px solid #000" }} scope="col">Attrition %</th>
              //     <th style={{ border: "1px solid #000" }} scope="col">Rating</th>
              //     <th style={{ border: "1px solid #000" }} scope="col">Rating</th>
              //   </thead>
              //   <tbody>
              //     <tr>
              //       <td style={{ border: "1px solid #000" }}>below 20 %</td>
              //       <td style={{ border: "1px solid #000" }}>Exceptional</td>
              //       <td style={{ border: "1px solid #000" }}>5</td>
              //     </tr>
              //     <tr>
              //       <td style={{ border: "1px solid #000" }}>20% to 25%</td>
              //       <td style={{ border: "1px solid #000" }}>Exceed Expectation</td>
              //       <td style={{ border: "1px solid #000" }}>4</td>
              //     </tr>
              //     <tr>
              //       <td style={{ border: "1px solid #000" }}>25% to 30%</td>
              //       <td style={{ border: "1px solid #000" }}>Meet Expectation</td>
              //       <td style={{ border: "1px solid #000" }}>3</td>
              //     </tr>
              //     <tr>
              //       <td style={{ border: "1px solid #000" }}>30% to 40%</td>
              //       <td style={{ border: "1px solid #000" }}>Below Expectation</td>
              //       <td style={{ border: "1px solid #000" }}>2</td>
              //     </tr>
              //     <tr>
              //       <td style={{ border: "1px solid #000" }}>More than 40%</td>
              //       <td style={{ border: "1px solid #000" }}>Needs Action</td>
              //       <td style={{ border: "1px solid #000" }}>1</td>
              //     </tr>
              //   </tbody>
              // </Table>
              // )
              // :   (item.type_id == 7) ? ( // for ontime billing table 
              // <Table className="align-items-center table-flush col-xl-4 " style={{marginRight:"20px"}}>
              //   <thead style={{ border: "1px solid #000" }} className="thead-light">
              //     <th colSpan={3} style={{ border: "1px solid #000",textAlign:"center" }} scope="col">On time Billing </th>
              //   </thead>	 	 
              //   <thead style={{ border: "1px solid #000" }} className="thead-light">
              //     <th style={{ border: "1px solid #000" }} scope="col">Working days</th>
              //     <th style={{ border: "1px solid #000" }} scope="col">Rating</th>
              //     <th style={{ border: "1px solid #000" }} scope="col">Rating</th>
              //   </thead>
              //   <tbody>
              //     <tr>
              //       <td style={{ border: "1px solid #000" }}>1st working day</td>
              //       <td style={{ border: "1px solid #000" }}>Exceptional</td>
              //       <td style={{ border: "1px solid #000" }}>5</td>
              //     </tr>
              //     <tr>
              //       <td style={{ border: "1px solid #000" }}>2nd to 3rd working day</td>
              //       <td style={{ border: "1px solid #000" }}>Exceed Expectation</td>
              //       <td style={{ border: "1px solid #000" }}>4</td>
              //     </tr>
              //     <tr>
              //       <td style={{ border: "1px solid #000" }}>4th to 5th working day</td>
              //       <td style={{ border: "1px solid #000" }}>Meet Expectation</td>
              //       <td style={{ border: "1px solid #000" }}>3</td>
              //     </tr>
              //     <tr>
              //       <td style={{ border: "1px solid #000" }}>4th to 7th working day</td>
              //       <td style={{ border: "1px solid #000" }}>Below Expectation</td>
              //       <td style={{ border: "1px solid #000" }}>2</td>
              //     </tr>
              //     <tr>
              //       <td style={{ border: "1px solid #000" }}>More than 7th working day</td>
              //       <td style={{ border: "1px solid #000" }}>Needs Action</td>
              //       <td style={{ border: "1px solid #000" }}>1</td>
              //     </tr>
              //   </tbody>
              // </Table>
              // )
              // :   (item.type_id == 8 || item.type_id == 10) ? ( 
              // <Table className="align-items-center table-flush col-xl-4 " style={{marginRight:"20px"}}>
              //   <thead style={{ border: "1px solid #000" }} className="thead-light">
              //     <th colSpan={3} style={{ border: "1px solid #000",textAlign:"center" }} scope="col">{item.type}  </th>
              //   </thead>	 	 
              //   <thead style={{ border: "1px solid #000" }} className="thead-light">
              //     <th style={{ border: "1px solid #000" }} scope="col">Score - Associates/ Doers</th>
              //     <th style={{ border: "1px solid #000" }} scope="col">Rating</th>
              //     <th style={{ border: "1px solid #000" }} scope="col">Rating</th>
              //   </thead>
              //   <tbody>
              //     <tr>
              //       <td style={{ border: "1px solid #000" }}>0</td>
              //       <td style={{ border: "1px solid #000" }}>Exceptional</td>
              //       <td style={{ border: "1px solid #000" }}>5</td>
              //     </tr>
              //     <tr>
              //       <td style={{ border: "1px solid #000" }}>Less than or equal to 1 </td>
              //       <td style={{ border: "1px solid #000" }}>Exceed Expectation</td>
              //       <td style={{ border: "1px solid #000" }}>4</td>
              //     </tr>
              //     <tr>
              //       <td style={{ border: "1px solid #000" }}>Less than or equal to 2 </td>
              //       <td style={{ border: "1px solid #000" }}>Meet Expectation</td>
              //       <td style={{ border: "1px solid #000" }}>3</td>
              //     </tr>
              //     <tr>
              //       <td style={{ border: "1px solid #000" }}>Less than or equal to 3 error per job</td>
              //       <td style={{ border: "1px solid #000" }}>Below Expectation</td>
              //       <td style={{ border: "1px solid #000" }}>2</td>
              //     </tr>
              //     <tr>
              //       <td style={{ border: "1px solid #000" }}>More than 3 </td>
              //       <td style={{ border: "1px solid #000" }}>Needs Action</td>
              //       <td style={{ border: "1px solid #000" }}>1</td>
              //     </tr>
                  
              //   </tbody>
              // </Table>
              // )
              // : (item.type_id == 9) ? ( 
              // <Table className="align-items-center table-flush col-xl-4 " style={{marginRight:"20px"}}>
              //   <thead style={{ border: "1px solid #000" }} className="thead-light">
              //     <th colSpan={3} style={{ border: "1px solid #000",textAlign:"center" }} scope="col">{item.type} </th>
              //   </thead>	 	 
              //   <thead style={{ border: "1px solid #000" }} className="thead-light">
              //     <th style={{ border: "1px solid #000" }} scope="col">Score - Associates/ Doers</th>
              //     <th style={{ border: "1px solid #000" }} scope="col">Rating</th>
              //     <th style={{ border: "1px solid #000" }} scope="col">Rating</th>
              //   </thead>
              //   <tbody>
              //     <tr>
              //       <td style={{ border: "1px solid #000" }}>0</td>
              //       <td style={{ border: "1px solid #000" }}>Exceptional</td>
              //       <td style={{ border: "1px solid #000" }}>5</td>
              //     </tr>
                  
              //     <tr>
              //       <td style={{ border: "1px solid #000" }}>Less than or equal to 1 </td>
              //       <td style={{ border: "1px solid #000" }}>Meet Expectation</td>
              //       <td style={{ border: "1px solid #000" }}>3</td>
              //     </tr>
              //     <tr>
              //       <td style={{ border: "1px solid #000" }}>More than 1</td>
              //       <td style={{ border: "1px solid #000" }}>Needs Action</td>
              //       <td style={{ border: "1px solid #000" }}>1</td>
              //     </tr>
                  
              //   </tbody>
              // </Table>
              // )
              // : (item.type_id == 11) ? ( 
              //   <Table className="align-items-center table-flush col-xl-3" >
              //   <thead style={{ border: "1px solid #000" }} className="thead-light">
              //     <th colSpan={2} style={{ border: "1px solid #000",textAlign:"center" }} scope="col">Team Span</th>
              //   </thead>
              //   <thead style={{ border: "1px solid #000" }} className="thead-light">
              //     <th style={{ border: "1px solid #000" }} scope="col">Target</th>
              //     <th style={{ border: "1px solid #000" }} scope="col">Rating</th>
              //   </thead>
              //   <tbody>
              //     <tr>
              //       <td style={{ border: "1px solid #000" }}>Less than or equal to 15</td>
              //       <td style={{ border: "1px solid #000" }}>Needs Action</td>
              //     </tr>
              //     <tr>
              //       <td style={{ border: "1px solid #000" }}>16 to less than or equal to 24</td>
              //       <td style={{ border: "1px solid #000" }}>Below Expectation</td>
              //     </tr>
              //     <tr>
              //       <td style={{ border: "1px solid #000" }}>25 to less than or equal to 35</td>
              //       <td style={{ border: "1px solid #000" }}>Meet Expectation</td>
              //     </tr>
              //     <tr>
              //       <td style={{ border: "1px solid #000" }}>36 to less than or equal to 40</td>
              //       <td style={{ border: "1px solid #000" }}>Exceed Expectation</td>
              //     </tr>
              //     <tr>
              //       <td style={{ border: "1px solid #000" }}>Above 40</td>
              //       <td style={{ border: "1px solid #000" }}>Exceptional</td>
              //     </tr>
              //   </tbody>
              // </Table>
              //   )
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              {type_data && type_data !== null ? (
                Object.keys(type_data).map((type, index) => {
                  let typearr = type.split("@@");
                  let typename = typearr[0];
                  let type_id = typearr[1];

                  return (
                    <React.Fragment key={index}>
                      <Table className="align-items-center table-flush col-xl-5" style={{ marginRight: "20px", marginBottom: '5px' }}>
                        
                        <thead style={{ border: "1px solid #000" }} className="thead-light">
                          <tr>
                            <th colSpan={2} style={{ border: "1px solid #000", textAlign: "center" }}>{typename}</th>
                          </tr>
                        </thead>
                        <thead style={{ border: "1px solid #000" }} className="thead-light">
                          <tr>
                            <th style={{ border: "1px solid #000" }}>Target</th>
                            <th style={{ border: "1px solid #000" }}>Rating</th>
                          </tr>
                        </thead>
                        <tbody>
                          {type_data[type].map((item, i) => (
                            <tr key={item.id}>
                              <td style={{ border: "1px solid #000" }} className="whitespace" >
                               
                                  <span>{item.criteria.replace(/\$actual_target/g, "value")}</span>
                                
                              </td>
                              <td style={{ border: "1px solid #000" }} className="whitespace" >
                                
                                  {item.rating_name}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </React.Fragment>
                  );
                })
              ) : (
                null
              )}
            </div>
                    )
             : null }
               <Table className="align-items-center table-flush col-xl-3" >
                <thead style={{ border: "1px solid #000" }} className="thead-light">
                  <th colSpan={2} style={{ border: "1px solid #000",textAlign:"center" }} scope="col">Final Rating</th>
                </thead>
                <thead style={{ border: "1px solid #000" }} className="thead-light">
                  <th style={{ border: "1px solid #000" }} scope="col">Target</th>
                  <th style={{ border: "1px solid #000" }} scope="col">Rating</th>
                </thead>
                <tbody>
                  <tr>
                    <td style={{ border: "1px solid #000" }}>Less than or equal to 1</td>
                    <td style={{ border: "1px solid #000" }}>Needs Action</td>
                  </tr>
                  <tr>
                    <td style={{ border: "1px solid #000" }}>Less than or equal to 2.5</td>
                    <td style={{ border: "1px solid #000" }}>Below Expectation</td>
                  </tr>
                  <tr>
                    <td style={{ border: "1px solid #000" }}>Less than or equal to 3.5</td>
                    <td style={{ border: "1px solid #000" }}>Meet Expectation</td>
                  </tr>
                  <tr>
                    <td style={{ border: "1px solid #000" }}>Less than or equal to 4.5</td>
                    <td style={{ border: "1px solid #000" }}>Exceed Expectation</td>
                  </tr>
                  <tr>
                    <td style={{ border: "1px solid #000" }}>Less than or equal to 5</td>
                    <td style={{ border: "1px solid #000" }}>Exceptional</td>
                  </tr>
                </tbody>
              </Table>
              </Row> 
              </Card>
              </Row>
          <Modal  isOpen={transModalOpen} size="lg" scrollable={true}>
            <div className=" modal-header" style={{padding:"13px 1rem 1px 1rem"}}>
              <h5 className=" modal-title" id="exampleModalLabel">
              Details
              </h5>
              <button aria-label="Close" className=" close" type="button" onClick={() => settransModalOpen(!transModalOpen)}>
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <ModalBody style={{padding:"2px"}}>
              <Table className="align-items-center table-flush table">
                <thead>
                  {(defaultType == 1  && user_info.department != 'Payroll') ?
                  <tr>
                    <th><b>Target hours</b></th>
                    <th><b>Approved timesheet days</b></th>
                    <th><b>Idle hours</b></th>
                    <th><b>Total worked </b></th>
                    <th><b>Final productivity</b></th>
                  </tr>
                  :  (defaultType == 1  && user_info.department == 'Payroll') ?
                  <tr>
                    {(selected_user_role == 20) ? 
                    <>
                    <th><b>Total Payroll count</b></th>
                    <th><b>Total payslip count</b></th>
                    <th><b>Payroll productivity</b></th>
                    <th><b>Payslip productivity</b></th>
                    </>
                    : 
                    <th><b>Average taken</b></th> }
                    <th><b>Final productivity</b></th>
                  </tr> 
                  : null }
                  {(defaultType == 2 && user_info.department != 'Payroll') ?
                  <tr>
                    <th><b>Internal Errors</b></th>
                    <th><b>External Errors</b></th>
                    <th><b>Completed Jobs</b></th>
                    <th><b>Accuracy</b></th>
                  </tr>
                  : (defaultType == 2  && user_info.department == 'Payroll') ? 
                  <tr>
                    <th><b> Errors</b></th>
                  </tr>
                  : null }
                  {(defaultType == 4) ?
                  <tr>
                    <th><b>Errors</b></th>
                  </tr>
                  : null }
                  {(defaultType == 5) ?
                  <tr>
                    <th><b>User</b></th>
                    <th><b>Rating</b></th>
                  </tr>
                  : null }
                  {(defaultType == 6) ?
                  <tr>
                    <th><b>No. Of exits</b></th>
                    <th><b>Opening Team size</b></th>
                    <th><b>Closing Team size</b></th>
                    <th><b>Average Team size</b></th>
                    <th><b>Retention</b></th>
                  </tr>
                  : null }
                </thead>
                <tbody>
                {(defaultType == 1 && user_info.department != 'Payroll') ?
                  <tr>
                    <td>{sendData.productivity_arr.target}</td>
                    <td>{sendData.productivity_arr.timesheet_count}</td>
                    <td>{sendData.productivity_arr.ideal_hours}</td>
                    {(user_info.role_id == 18) ?
                          <td  >
                            {/* {item.mainediting ? ( */}
                              <input type="number"
                              className="form-control"
                              defaultValue={defaultworkedhours}
                              
                                onBlur={(e) =>
                                  editField(
                                    e.target.value
                                  )
                                }
                              />
                            {/* ) : (
                              <span>{sendData.productivity_arr.totalworkedhrs}</span>
                            )} */}
                          </td>
                          : <td>{sendData.productivity_arr.totalworkedhrs}</td> }
                    
                    <td>{sendData.productivity_arr.final_productivity}</td>
                  </tr>
                : (defaultType == 1 && user_info.department == 'Payroll') ? 
                <tr>
                  {(selected_user_role == 20) ? 
                    <>
                  <td>{sendData.productivity_arr.total_payroll_count}</td>
                  <td>{sendData.productivity_arr.total_payslip_count}</td>
                  <td>{sendData.productivity_arr.payroll_productivity}</td>
                  <td>{sendData.productivity_arr.payslip_productivity}</td>
                  </>
                  :
                  <td>{sendData.productivity_arr.average}</td>
                  }
                  <td>{sendData.productivity_arr.final_productivity}</td>
                </tr> 
                : null }
                {(defaultType == 2  && user_info.department != 'Payroll') ?
                  <tr>
                    <td>{sendData.accuracy_arr.internal}</td>
                    <td>{sendData.accuracy_arr.external}</td>
                    <td>{sendData.accuracy_arr.completed_count}</td>
                    <td>{sendData.accuracy_arr.total}</td>
                  </tr>
                 : (defaultType == 2  && user_info.department == 'Payroll') ? 
                 <tr>
                 <td>{sendData.accuracy_arr.total}</td>
                 </tr>
                 : null }
                {(defaultType == 4) ?
                  <tr>
                    <td>{sendData.bestpractices}</td>
                  </tr>
                : null }
                {(defaultType == 5 && sendData.team_arr_data && sendData.team_arr_data.length && sendData.team_arr_data != null) ?
                  sendData.team_arr_data.map((item, i) =>
                  <tr>
                    <td>{item.name}</td>
                    <td>{item.weighted_score}</td>
                  </tr>
                  )
                : null }
                {(defaultType == 6) ?
                  <tr>
                    <td>{sendData.retention_arr.no_of_exits}</td>
                    <td>{sendData.retention_arr.opening_team_size}</td>
                    <td>{sendData.retention_arr.closing_team_size}</td>
                    <td>{sendData.retention_arr.average_team_size}</td>
                    <td>{sendData.retention_arr.count_retention}</td>
                  </tr>
                : null }
                {(defaultType == 11) ?
                  sendData.teamspanusers.map((item, i) =>
                  <tr>
                    <td>{item}</td>
                  </tr>
                  )
                : null }
                </tbody>
              </Table>
            </ModalBody>
           
          </Modal>
      </Container>
    </>
  );
};

 
export default KROCalculation;
