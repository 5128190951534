import { useState, useEffect,useRef } from "react";
import React from 'react';
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  FormGroup,
  Form,
  Input,
  InputGroup,
  Pagination,
  PaginationItem,
  PaginationLink,
  PopoverBody,
  UncontrolledPopover 
} from "reactstrap";
import { AgGridReact, AllCommunityModules, AgGridColumn } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-theme-alpine-dark.css";
import { userService } from "../../../services/authentication";
import { reportmanageService } from "../../../services/reportmanageService";
import "../../../assets/css/custom.css";
import Header from "components/Headers/Header.js";
import { forEachChild } from "typescript";
import { connect } from "react-redux";
import DataTable from "react-data-table-component";
import Navbuttons from "components/Navigations/Navbuttons.js";
import { stringToArray } from "ag-grid-community";
const axios = require("axios");
import { apiUrl } from "../../../constant";
import { authtoken } from "global.js";
import {Formloader} from 'components/Loader/PageLoader.js';
import Paginationcomponent from "components/Paginationcomponent.js"
import {taskmanageService} from '../../../services/taskmanageService';
import { usermanageService } from "../../../services/usermanageService";

const TurnOverReport = (props) => {
  const [filtertype,setfiltertype] = useState('total');
  const [dashboarddata, setDashboarddata] = useState([]);
  const [currentPage, setCurrentPage] = useState(null);
  const [last_page, setLastPageData] = useState(0);
  const [total_page,setTotalData] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const rowsPerPageOptions = [5, 10, 25, 50, 100];
  
  const handlePageClick = (e, index) => {
 
    e.preventDefault();
    get_list(index,null,filtertype,rowsPerPage);
  };

  const handlePreviousClick = () => {
    const prev = currentPage - 1;
    get_list(prev,null,filtertype,rowsPerPage);
  };

  const handleNextClick = () => {
    const next = currentPage + 1;
    get_list(next,null,filtertype,rowsPerPage);
  };
  const handleRowsPerPageChange = (newRowsPerPage) => {

    setRowsPerPage(newRowsPerPage);
    
    setCurrentPage(1); 
    get_list(1,null,filtertype,newRowsPerPage)
  
  };
  const get_list = async (pageNumber = 1,e,filtertype='',rowsPerPage='',year='') => {
    let  textbox = '';

    if(e != null){
       textbox = e.target.value;
    }
   
    reportmanageService.getturnoverReport(pageNumber,textbox,filtertype,rowsPerPage).then((response) => {
     console.log(response.data.data)
      setDashboarddata(response.data.data.data);
      setCurrentPage(response.data.data.current_page);
      setTotalData(response.data.data.total);
    });
  };
  function filterallcom({type}){
    setfiltertype(type)
    get_list(1,null,type);
 }

 useEffect(() => {
  getPhases();
  // get_dashboard_data();
   get_list(1,null,filtertype);
  
}, []);


  const[phaselist,setPhaseList]=useState([]);
  function getPhases(){
    usermanageService.getphasesfulllist().then((response)=>{
      if (response.status == 200) {

        setPhaseList(response.data.phases)
      }
    })
  }
  
  return (
    <>
    <Header />
     <Container className="mt--7" fluid>
        <Row>
        <Col>
         <h2>Turnover Report</h2>
            
         
          </Col>
        </Row>

          
         
            <div className="col" style={{padding:'0%'}}>
              <Card className="shadow">
              <CardHeader>
                  <div className="filter">
                  
                    <div   style={{maxWidth:'100%'}}>
                    {/* <Button
                      className="btn-icon-clipboard"
                      type="button"
                      onClick={downloadReport}
                      style={{textAlign:'center'}}
                      title="Download Report"
                    >
                    <i className="fas fa-download" style={{fontSize:'14px'}}></i> Export
                    </Button> */}
                    {/* <select
        id="year"
        name="year"
        value={selectedYear}
        onChange={handleYearChange}
        className="form-control"
        
      >
        <option value="">Select Year</option>
        {years.map(year => (
          <option key={year} value={year}>
            {year}
          </option>
        ))}
      </select> */}

                    </div></div>
              </CardHeader>
              <Table className="align-items-center table-flush"   responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col" className="whitespace sortclass" >Client</th>
                    <th scope="col">Sub CLient</th>
                    {phaselist.map(phase=>{
                      return <th>{phase.desc}</th>
                    })}
                    
                    
                    
                  </tr>
                </thead>
               <tbody>
               {dashboarddata.length > 0 ? (
  dashboarddata.map((item, i) => (
    <React.Fragment key={i}>
      <tr className={i % 2 === 0 ? 'blue-row' : (i % 3 === 0 ? 'purple-row' : 'orange-row')}>
        <td className="text-left">{item.name}</td>
        <td className="text-left">{item.sub_client}</td>
        
        
        {phaselist.map((phase, phaseIndex) => {
          
          const phaseValue = item[phase.desc];
          
          return (
            <td key={phaseIndex}>
              {phaseValue !== null  ? phaseValue : ''}
            </td>
          );
        })}
      </tr>
    </React.Fragment>
  ))
) : (
  <tr>
    <td colSpan="10">No Data Found</td>
  </tr>
)}

               </tbody>
              </Table>
              <CardFooter className="py-4">
              <div>
     
              <Paginationcomponent
        currentPage={currentPage}
        totalItems={total_page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={rowsPerPageOptions}
        handlePageClick={handlePageClick}
        handlePreviousClick={handlePreviousClick}
        handleNextClick={handleNextClick}
        handleRowsPerPageChange={handleRowsPerPageChange}
      />
                </div>
              </CardFooter>
              </Card>
            </div>
            
          
       
     </Container>
    </>
  );
};


export default TurnOverReport;
