import { useState, useEffect } from "react";
import {
  BrowserRouter,
  Route,
  Switch,
  Redirect,
  useHistory,
} from "react-router-dom";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Container,
  Radio
} from "reactstrap";
import { toast } from 'react-toastify';
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-theme-alpine-dark.css";
import { usermanageService } from "../../services/usermanageService";
import "../../assets/css/custom.css";
import Header from "components/Headers/Header.js";
import Buttoncomponent from 'components/Buttoncomponent.js';
import Select from 'react-select';
import { manageService } from "../../services/manageaccountservices";
const Useradd = (props) => {
  const [inputFields, setInputfields] = useState({});
  const history = useHistory();
  const [disablestate, setdisablestate] = useState(false);
  const user_info = JSON.parse(localStorage.getItem("user-info"));
  function inputhandler(e,user_id='') {
    let phseup = {}
    if(user_id){
      phseup = { ...inputFields, 'user_id': e.value }    
    setInputfields(phseup);
    }else{
    
    phseup = { ...inputFields, [e.target.name]: e.target.value }    
    setInputfields(phseup);
    }
    
  }
  const [uniquecompanieslist, setUniqueCompaniesData] = useState([]);
function get_unique_companieslist(){
  manageService.get_clientusers().then((response)=>{
    setUniqueCompaniesData(response.data);
  });
}

  useEffect(() => {
    get_unique_companieslist();
    return () => {
      setInputfields({});
      
    };
  }, []);

  function adduser(e){
    setdisablestate(true)
    e.preventDefault();
    usermanageService.addclientuser(inputFields).then((response)=>{
      if(response.status == 200){
         toast.success(response.data.message); 
         document.getElementById("adduser-form").reset();
         history.push('users');
      }else{
        console.log(response.data);
         toast.error(response.data.message);
      }
      setdisablestate(false)
    });
  }
  const styles = {
    'color':'#4a4444',
  };
  

  function  ismailsend(val) {
    if(val == 'yes'){
      setsendmail(true);
    }else{
      setsendmail(false);
    }
  } 
  

  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <div className="row">
          <div className="col-xl-12">
            <div className="col-md-12 col-12">
              <div class="row">
                  <div class="col-md-6"><h2>Add User</h2></div>
                  <div class="col-md-6">
                    <div class="row">
                      <div class="col-md-2 offset-md-10">
                        <Buttoncomponent/>
                      </div>
                    </div>
                  </div>
                </div>
                <br/>
              <Card className="card">
                <Form role="form" id="adduser-form" onSubmit={adduser}>
                  {user_info.role_id !=13 ? 
                  <div className="row mb-3">
                  <div className="col-md-4">
                       
                        <Select 
                   
                          className="input-group-alternative " 
                          name="user_id"
                          options={uniquecompanieslist} // set list of the data
                          onChange={(e) => { inputhandler(e,"user_id")}}
                          
                          searchable
                          isClearable={false}
                        />
                       
                    </div> 
                  </div>:null}
                <div className="row">
                    <div className="col-md-4">
                       <FormGroup>
                        <InputGroup className="input-group-alternative mb-3">
                          <Input
                            placeholder="Name"
                            type="text"
                            name="name"
                            onChange={inputhandler}
                            style={styles}
                            required
                          />
                        </InputGroup>
                      </FormGroup>    
                    </div> 
                    <div className="col-md-4">
                       <FormGroup>
                          <InputGroup className="input-group-alternative mb-3">
                            <Input
                              placeholder="Email"
                              type="text"
                              name="email"
                              onChange={inputhandler}
                              style={styles}
                              required
                            />
                          </InputGroup>
                        </FormGroup>
                    </div> 
                    <div className="col-md-4">
                       <FormGroup>
                          <InputGroup className="input-group-alternative mb-3">
                            <Input
                              placeholder="Password"
                              type="password"
                              name="password"
                              onChange={inputhandler}
                              style={styles}
                              required
                            />
                          </InputGroup>
                        </FormGroup>
                    </div>
                    
                </div>
                <div class="row">
                
                    <div className="col-md-4">
                         <Button className="col-12 " color="primary" type="submit" disabled={disablestate}>
                         {disablestate ? 'Sending...' : 'Add user'} 
                         </Button>
                          
                    </div>
                </div>
                
                <FormGroup>
                  <InputGroup className="input-group-alternative mb-3"></InputGroup>
                </FormGroup>
                </Form>
              </Card>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default Useradd;
