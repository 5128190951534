/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/

// reactstrap components
import React from 'react';
import { useState, useEffect, useRef } from "react";
import { Input , Row, Col ,Button,Modal,ModalBody,DropdownMenu,
  DropdownItem, Form,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  UncontrolledDropdown,Card,
  CardHeader,
  CardBody,
  DropdownToggle } from "reactstrap";
  import Select from 'react-select';
import { usermanageService } from "./../../services/usermanageService";
import {  toast } from "react-toastify";

const Footer = () => {
  const [inputFields, setInputfields] = useState({ 'content' : '' });
  const [inputGroup, setInputgroup] = useState({ 'name' : '', participants : [] });
  const [userdata, setUserdata] = React.useState([]);
  const [open, setOpen] = React.useState(false);  
  const [opencollapse, setOpenCollapse] = React.useState(false); 
  const [users, setUsers] = React.useState([]);
  const [isActive, setIsActive] = React.useState(false);
  const [show, setShow] = React.useState();
  const [showchat, setchat] = React.useState(false);
  const [totalunread, setTotalUnread] = React.useState(0);
  const [getlabel, getLabel] = React.useState(false);
  const [lastupdateddate, setlastupdatedDate] = React.useState('');
  const [conversation_id, getConversationId] = React.useState('');
  const initialVisibleAreas = {
    tab1: true,
    tab2: false,
  };
  const [visibleAreas, setVisibleAreas] = React.useState(initialVisibleAreas);
  const user_info = JSON.parse(localStorage.getItem("user-info"));
  const toggle = () => {
    getLabel('');
    setchat(false);
    setOpenCollapse(!opencollapse);
  };

  function getTotData(){
      usermanageService.getTotalChatCount().then((response) => {
        setTotalUnread(response.data.total_unread);
        // setlastupdatedDate(response.data.last_updated_time);
      });
  }

  function getuser_list(key='') {
    if(key){
      usermanageService.getclient().then((response) => {
        setUsers(response.data.clients);
      });
    }else{
      usermanageService.getusersforchatbox().then((response) => {
        setUsers(response.data.users);
       setUserdata(response.data.group_users);
      });
    }
  }

  function inputhandler(e) {
       setInputfields({ ...inputFields, [e.target.name]: e.target.value });
  }
  function inputgrouphandler(e) {
    let phseup = {}
    if(Array.isArray(e)){     
      phseup = {...inputGroup,participants : Array.isArray(e) ? e.map(x => x.value) : []}
    }else{
        phseup = { ...inputGroup, [e.target.name]: e.target.value }    
    }
    setInputgroup(phseup);
}
  function opentab(e, key,client){
    setIsActive(current => !current);
    Object.entries(visibleAreas).map(([k, v], index) => {
      if(k == key) {
       visibleAreas[k]= true ;
      }else{
       visibleAreas[k]= false ;
      }
    })
    getuser_list(client)
    setVisibleAreas({ ...visibleAreas,  visibleAreas});
  }
  const[converfield,setConverFields] = useState({});
  const [messages, setMessages] = useState([]);
  function toggleShow(e,type) {
    // add conversation
    var data = [];
    if(type == 'single'){
      data['type'] = type;
      data['user_id'] = e.value;
    }else if(type == 'group'){
      if(inputGroup.name == '' || inputGroup.participants.length == 0){
        toast.error('Group name and participants can not be blank.');
        return false;
      }
      data['type'] = type;
      data['name'] = inputGroup.name;
      data['participants'] = inputGroup.participants;
    }
    usermanageService.addconversation(data).then((response) => {
      if(response.data.already == true){
        setMessages(response.data.data);
        
      }else{
        setMessages([]);
      }
      getConversationId(response.data.conversation_id)
    });

    let label = '';
    setchat(true);
    if(type == 'single'){
    
      label = e.label;
    
  }else{ // for group conver
     label = inputGroup.name;
     setInputgroup({ 'name' : '', participants : [] });
  }
    getLabel(label);
    
  }

  function showMessages(label,id) {
    usermanageService.getmessages(id).then((response) => {
        setMessages(response.data.data);
        getConversationId(response.data.conversation_id)
    });
    setchat(true);
    getLabel(label);
  }

  const [conversations, setConversations] = useState([]);
  function get_conversations(type=null){
    if(type==null){
      setOpen(!open)
      getConversationId('')
    }
    usermanageService.getconversation().then((response) => {
      setConversations(response.data.data)
    });
  }
  function add_response(e) {
    e.preventDefault();
    if(inputFields.content == '' || inputFields.content == 'undefined'){
      toast.error('Message can not be blank.');
    }else{
    usermanageService.addresponse(inputFields,conversation_id).then((response) => {
      // console.log(response);
      if (response.status == 200) {
        // toast.success(response.data.message);
        showMessages(getlabel,conversation_id)
        setInputfields({ content : "" })
      } else if (response.status == 201) {
        toast.error(response.data.message);
      }
    });
  }
  }
  const messagesEndRef = useRef(null)

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
  }

  useEffect(() => {
    const interval = setInterval(() => {
      if(conversation_id!=''){
        showMessages(getlabel,conversation_id)
      }
      else if(open == true){
        get_conversations('auto')
      }
      else{
        // getTotData()
      }
    }, 10000);
  
    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, [open,conversation_id])

  function backProcess(){
    setOpen(true);
    setchat(false);
    getConversationId('')
  }
  React.useEffect(() => {
    if(user_info.role_id != 13){
        getuser_list();
    }
    // getTotData();
  }, []);

  useEffect(() => {
    scrollToBottom()
  }, [messages]);

  return (
    <footer className="footer">
      <Row className="align-items-center justify-content-xl-between">
        <Col xl="6">
          <div className="copyright text-center text-xl-center text-muted">
            Design & Developed by
            <a
              className="font-weight-bold ml-1"
              href="https://corient.tech/"
              rel="noopener noreferrer"
              target="_blank"
            >
              Corient Business Solutions Pvt. Ltd.
            </a>
            © {new Date().getFullYear()}
          </div>
          
        </Col>
        {user_info.role_id != 13 ? 
        <Col xl="6" >
        <div >
       
          {/* <Button
         onClick={() => {get_conversations()}}
          className="col-9 chat-button"
          color="white"
          type="button"
         style={{position: "fixed",bottom : "40px",right : "20px",width:"55px"}}
          
        >
          {" "}
          <div className="chatdiv"><i class='far fa-comment' style={{fontSize:'24px',color:'white',marginLeft:'6px'}}></i><span className="countspan">{totalunread}</span></div> */}
          {/* <div>
          <span style={{fontSize:'16px',fontWeight:'bold',color:'#2FC3B9'}}>Comment/Chat</span>
          <p id="time" class="time-txt">{lastupdateddate}</p>
          </div> */}
          
        {/* </Button> */}
        
        </div>
        </Col>
: null }
        {/* Phase selection modal --start */}
        <Row>
          <Modal
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            isOpen={open}
            className='main-modal'
          >
            {/* <ModalHeader>
            <button
                aria-label="Close"
                className=" close"setVisibleAreas
                type="button"
                onClick={() => {setOpen(!open),toggle()}}
              >
                <span aria-hidden={true}>dd</span>
              </button>

            </ModalHeader> */}
           
              

      
             
            <ModalBody style={{padding:0}}>
            <div id='whatsapp-chat' class='hide'>
<div class='header-chat'>
{!showchat && user_info.role_id != 13 && (
  <>
  {/* <Card className=""> */}
              {/* <CardHeader className="bg-white border-0"> */}
                <Row className="align-items-center">
                <Col  xs="2">
                  <UncontrolledDropdown>
                        <DropdownToggle
                          className="btn-icon-only text-light chat-shadow"
                          href="#pablo"
                          role="button"
                          size="sm"
                          color=""
                          onClick={(e) => e.preventDefault()}
                        >
                          <i className="fas fa-ellipsis-v" />
                        </DropdownToggle>


                        <DropdownMenu className="dropdown-menu-arrow" left>
                          <DropdownItem
                            href="#pablo"
                            onClick={(e) => {opentab(e,'tab1',0)}}
                          >
                            Internal Team
                          </DropdownItem>
                          <DropdownItem
                            href="#pablo"
                            onClick={(e) => {opentab(e,'tab1',1)}}
                          >
                            Client
                          </DropdownItem>
                          <DropdownItem
                            href="#pablo"
                            onClick={(e) => {opentab(e,'tab2',0)}}
                          >
                            Create Group
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                  </Col>
                  <Col xs="10" className="text-right">
                  {/* <Form className="navbar-search navbar-search-dark form-inline mr-3 d-none d-md-flex ml-lg-auto">
                    <FormGroup className="mb-0">
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="fas fa-search" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input placeholder="Search" type="text" />
                      </InputGroup>
                    </FormGroup>
                  </Form> */}
                   {visibleAreas['tab2'] && user_info.role_id != 13 && (
              <div className="col-xl-12 col-md-12 col-xs-12 mt-3" style={{marginTop:"0px !important",paddingLeft : "0px",textAlign:"left"}}>
                  
              
                           <Select 
                            className="input-group-alternative mb-3" 
                            name="participants" 
                            allowSelectAll={true} 
                            value={(inputGroup.participants) ? userdata.filter(obj => inputGroup.participants.includes(obj.value)) : ''} // set selected values
                            options={userdata} // set list of the data
                            onChange={inputgrouphandler}
                            isMulti
                            isClearable
                          />
                          {inputFields.qatl && <div style={{ marginTop: 20, lineHeight: '25px' }}>
                            
                              </div>}
                         <Input
                            value={inputGroup.name}
                            bsSize="sm"
                            name="name"
                            placeholder="Group name"
                            id="example-date-input"
                            type="text"
                            onChange={(e) => inputgrouphandler(e)}
                          ></Input>

                          <div > 
                           <Button
                                color=""
                                type="button"
                                style={{
                                  padding: "revert",padding: "revert",
                                  background: "#d6f2f0",
                                  margin: "10px 2px"
                                }}
                                onClick={(e) => {toggleShow(e,'group')}}
                              >
                             Create group
                              </Button> </div>
              </div>
              )}
              {visibleAreas['tab1'] && user_info.role_id != 13 && (
              <div className="col-xl-12 col-md-12 col-xs-12 mt-3"  style={{marginTop:"0px !important",paddingLeft : "0px",textAlign:"left"}}>
                  <Select 
                   
                   className="input-group-alternative mb-3" 
                   name="user_id"
                   options={users} // set list of the data
                   onChange={(e) => {toggleShow(e,'single')}} 
                   searchable
                   isClearable={false}
                 />
              {/* <select className="form-control" name="roles" onChange={(e) => {toggleShow(e,'single')}}  placeholder="Role">
                              <option key="role_1"  value="">Select User</option>
                              {users.map(roles =>
                                  <option key={roles.id} value={roles.id}>{roles.name}</option>
                              )}
                            </select> */}
              </div>
              )}
                  </Col>
                 
                </Row>
              {/* </CardHeader> */}
              
  
  {/* </Card> */}
  </>)}
</div>
{!showchat && (
  
<div class='home-chat'>
<div class='blanter-msg'> 
             
              </div>
                <div style={{ height: "300px", overflow: "auto"}}>
                 {conversations.length > 0 && conversations  ? (
                        conversations.map((conversation, i) => ( 
                        <a className={(conversation.unread_messages_count > 0 ? "informasi active" : "informasi")}  title={conversation.last_message} onClick={() => {showMessages((conversation.type == 'single') ? conversation.participant_name : conversation.name,conversation.id)}}>
                        <div class='info-avatar'>
                        <div className="ellipse" >{(Array.from((conversation.type == 'single') ? conversation.participant_name : conversation.name)[0]).toUpperCase()}</div>
                        </div>
                        <div class='info-chat'>
                        <span class='chat-nama'>{(conversation.type == 'single') ? conversation.participant_name : conversation.name} ({conversation.unread_messages_count})</span>
                        <span class='chat-label'>{conversation.last_updated}</span> 
                        </div>
                        <span class='my-number'>{conversation.last_message}</span>
                        </a>
                        ))
                  ) : (
                    <div className="col-xl-12 col-md-12 col-xs-12 mt-3">No conversation Found !</div>
                  )}
</div>

</div>)}
{showchat && (
  
<div class='start-chat hide'>

<div class='get-new hide bg-transparent card-header'>
<Row className="align-items-center">
  <Col xs="1" style={{left:"8px"}}>
  <a onClick={backProcess.bind(this)}><i className='fas fa-arrow-left' style={{fontSize:"13px",cursor:"pointer"}}></i></a>
                             
  </Col>
                <Col xs="11">
                  
                <div class='info-avatar'>
                 <div className="ellipse" >{(Array.from(getlabel)[0]).toUpperCase()}</div>
                        </div>
<h2 className="mb-0 msg-label" style={{fontSize:"16px"}}>{getlabel}</h2>
</Col></Row>
</div>
<ul id="chat" style={{ height: "200px", overflow: "auto"}}>
{messages.length > 0 && messages  ? (
        messages.map((message, i) => ( 
          <>
          {message.sender.sender_type == 'me' ? 

			(<li class="me">
				<div class="entete">
					<span class="status green"></span>
					<h2>{message.sender.name}{showchat}</h2>
					<h3>{message.updated} </h3>
				</div>
			
				<div class="message">
					{message.content}
				</div>
			</li>) : 
      (<li class="you">
      <div class="entete">
      <h2>{message.sender.name}</h2>
        <h3>{message.updated} </h3>
        <span class="status blue"></span>
      </div>
    
      <div class="message">
      {message.content}
      </div>
    </li>) }
    </>
       ))
       ) : (
         <li>No Messages Found !</li>
       )}
      
			
      <div ref={messagesEndRef} />
		</ul>
<div class='blanter-msg'>
<Input
                            value={inputFields.content}
                            bsSize="sm"
                            name="content"
                            placeholder="Write a message"
                            className="msg-text"
                            type="textarea"
                            onChange={(e) => inputhandler(e)}
                          ></Input>
<a  id='send-it' onClick={add_response}><i className='fas fa-arrow-right'></i></a></div>
</div>
)}
<div id='get-number'></div><a class='close-chat'onClick={() => {setOpen(!open),toggle(),getConversationId('')}}>×</a>
</div>
              
            </ModalBody>
           
          </Modal>
        </Row>
       
      </Row>
    </footer>
  );
};

export default Footer;
