
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {apiUrl} from '../constant';
import {authHeader} from '../helpers/auth-header';
import {authtoken} from 'global.js';
const axios = require("axios");
export const clientmanageService = {
    addclient,
    addclientfrequency,
    addjobclient,
    create_amendment,
    getphasestl,
    getparticular_client,
    createclient,
    updatejobclient,
    getclientlist,
    getsubclientlist,
    getjobdocs,
    getfilterlist,
    getuniquecompaniessearchable,
    getsubclientlistsearchable,
    getuniqueclient,
    getclientfreq,
    addgovernancetransdata,
    getGovernanceCalltransdata,
    editgovernancedata,
    getgovernance,
    editgovernancetransdata,
    getcllientfrequency,
    getbillingdata,
    gettransitionData,
    updateTransData,
    updateChecklistMaster,
    generateForm,
    generateFormChecklist,
    updateTransitionForm,
    getClientTransition,
    gettransitionType ,
    getTransitionFields,
    getTransitionFieldData,
    addtransitionFieldData,
    download_transitionform_report,
    getbillingclientlist,
    getusersclientlist,
    addinternalgovernancetransdata,
    getInternalGovernanceCalltransdata,
    getinternalgovernance,
    editinternalgovernancetransdata,
    addBillingRow,
    getclienttransitionlog,
    clienttransitionlogexport,
    synctotimesheet,
    synctotimesheetunlock,
    addinternaltransdata,
    getInternalCalltransdata,
    getinternalcall,
    editinternaltransdata,
    generateSOPForm,
    updateTransitionSOPForm,
    updateChecklistForm,
    xeroConnect,
    xeroconnectstatus,
    xeroDisconnect,
    pushxero,
    getusers,
    addBillingRowbelowtotal,
    governanceCalldashboard,
    getGovernanceMISdashboard,
    getGovernanceMISActiondashboard,
    editgovernancefreqdata,
    governanceCalldashboarddetails,
    governanceCalldashboardactiondetails,
    saveManagerRejection,
    getChecklistMaster,
    addChecklistMaster,
    getChecklistFeedback,
    download_sample,
    checklist_import,
    getsubclient,
    checklist_feedback_report,
    deleteCommonTableData,
    editManagerRejection,
    getsinglekrotypeData,
    getunlockbillingclientlist,
    getunlockbillingdata,
    billingunlock_export,
    getIncentiveMaster,
    updateIncentiveMaster,
    getIncentives,
    billingDataPDF,
    governanceChartData,
    getallusers
};

function getallusers(client_data){
  return axios({
      method: "get",
      url: `${apiUrl}/getallusers`,
      data: client_data,
      headers: { Authorization: `Bearer ${authtoken}` },
    })
      .then(function (response) {
           return response;  
      }, error => {
        return error.response;
      //   console.log(error.response.status);
      })
      .catch(function (error) {
        // console.log(error.response.status);
      });      
}
function addclient(client_data){
    return axios({
        method: "post",
        url: `${apiUrl}/companies`,
        data: client_data,
        headers: { Authorization: `Bearer ${authtoken}` },
      })
        .then(function (response) {
             return response;  
        }, error => {
          return error.response;
        //   console.log(error.response.status);
        })
        .catch(function (error) {
          // console.log(error.response.status);
        });      
}

function addclientfrequency(client_data){
  return axios({
      method: "post",
      url: `${apiUrl}/addclientfrequency`,
      data: client_data,
      headers: { Authorization: `Bearer ${authtoken}` },
    })
      .then(function (response) {
           return response;  
      }, error => {
        return error.response;
      //   console.log(error.response.status);
      })
      .catch(function (error) {
        // console.log(error.response.status);
      });      
}

function addjobclient(client_data){
  return axios({
      method: "post",
      url: `${apiUrl}/client-job-add`,
      data: client_data,
      headers: { Authorization: `Bearer ${authtoken}` },
    })
      .then(function (response) {
           return response;  
      }, error => {
        return error.response;
      //   console.log(error.response.status);
      })
      .catch(function (error) {
        // console.log(error.response.status);
      });      
}
function updatejobclient(client_data){
  return axios({
      method: "post",
      url: `${apiUrl}/client-job-update`,
      data: client_data,
      headers: { Authorization: `Bearer ${authtoken}` },
    })
      .then(function (response) {
           return response;  
      }, error => {
        return error.response;
      //   console.log(error.response.status);
      })
      .catch(function (error) {
        // console.log(error.response.status);
      });      
}

function addgovernancetransdata(data){
  return axios({
    method: "post",
    url: `${apiUrl}/addgovernancetrans`,
    data: data,
    headers: { Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
         return response;  
    }, error => {
      return error.response;
    //   console.log(error.response.status);
    })
    .catch(function (error) {
      // console.log(error.response.status);
    });
}

function createclient(client_data){
  // console.log('client data');
  // console.log(client_data); 
  var bodyFormData = new FormData();
  bodyFormData.append("name", client_data.name);
  bodyFormData.append("sub_client", client_data.sub_client);
  bodyFormData.append("account_prod_software",client_data.account_prod_software);
  bodyFormData.append("book_keeping_software", client_data.book_keeping_software);
  bodyFormData.append("client_deadline", client_data.client_deadline);
  bodyFormData.append("company_id", client_data.company_id);
  bodyFormData.append("due_date", client_data.client_deadline);
  bodyFormData.append("initial_budget", client_data.initial_budget);
  bodyFormData.append("task_id", client_data.task_id);
  bodyFormData.append("year_end_date", client_data.year_end_date);
  bodyFormData.append("created_at", client_data.raised_on);
  bodyFormData.append("phase_lead_id", client_data.phase_lead_id);
  bodyFormData.append("phase_due_date", client_data.phase_due_date);
  return axios({
    method: "post",
    url: `${apiUrl}/companies/update`,
    data: bodyFormData,
    headers: { Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
         return response;
    }, error => {
      return error.response;
    //   console.log(error.response.status);
    })
    .catch(function (error) {
      // console.log(error.response.status);
    });  
}

function create_amendment(amendment_data){
  var current_date = new Date().toISOString().slice(0, 10);
  var bodyFormData = new FormData();
  bodyFormData.append("company_id", amendment_data.am_company);
  bodyFormData.append("client_id", amendment_data.am_analyst);
  bodyFormData.append("raised_date",current_date);
  bodyFormData.append("select_due_date", amendment_data.select_due_date);
  bodyFormData.append("type", 'A');
  bodyFormData.append("am_budget", amendment_data.am_budget);
  return axios({
    method: "post",
    url: `${apiUrl}/companies/amendmentcreate`,
    data: bodyFormData,
    headers: { Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
         return response;
    }, error => {
      return error.response;
    //   console.log(error.response.status);
    })
    .catch(function (error) {
      // console.log(error.response.status);
    });  
}

function getphasestl(id){
  return axios({
      method: "get",
      url: `${apiUrl}/phase-wise-user?id=${id}`,
      headers: {Authorization: `Bearer ${authtoken}` },
    })
      .then(function (response) {
         if(response.status == 200){
              return response;  
         }
      }, error => {
        return error.response;
        console.log(error.response.status);
      })
      .catch(function (error) {
        // console.log(error.response.status);
      });      
}

function getclientlist(){
  return axios({
    method: "get",
    url: `${apiUrl}/getuniquecompanies`,
    headers: {Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
       if(response.status == 200){
            return response;  
       }
    }, error => {
      return error.response;
      console.log(error.response.status);
    })
    .catch(function (error) {
      // console.log(error.response.status);
    });
}

function getbillingclientlist(fromdate,todate){
  return axios({
    method: "get",
    url: `${apiUrl}/getbillingclientlist?fromdate=${fromdate}&todate=${todate}`,
    headers: {Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
       if(response.status == 200){
            return response;  
       }
    }, error => {
      return error.response;
      console.log(error.response.status);
    })
    .catch(function (error) {
      // console.log(error.response.status);
    });
}
function getuniquecompaniessearchable(){
  return axios({
    method: "get",
    url: `${apiUrl}/getuniquecompaniessearchable`,
    headers: {Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
       if(response.status == 200){
            return response;  
       }
    }, error => {
      return error.response;
      console.log(error.response.status);
    })
    .catch(function (error) {
      // console.log(error.response.status);
    });
}
function getuniqueclient(){
  return axios({
    method: "get",
    url: `${apiUrl}/getuniqueclient`,
    headers: {Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
       if(response.status == 200){
            return response;  
       }
    }, error => {
      return error.response;
      console.log(error.response.status);
    })
    .catch(function (error) {
      // console.log(error.response.status);
    });
}

function getsubclientlist(client){
  return axios({
    method: "get",
    url: `${apiUrl}/getsubclient?client=${encodeURIComponent(client)}`,
    headers: {Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
       if(response.status == 200){
            return response;  
       }
    }, error => {
      return error.response;
      console.log(error.response.status);
    })
    .catch(function (error) {
      // console.log(error.response.status);
    });
}
function getsubclientlistsearchable(client){
  let client1 =encodeURIComponent(client);
  return axios({
    method: "get",
    url: `${apiUrl}/getsubclientlistsearchable?client=${client1}`,
    headers: {Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
       if(response.status == 200){
            return response;  
       }
    }, error => {
      return error.response;
      console.log(error.response.status);
    })
    .catch(function (error) {
      // console.log(error.response.status);
    });
}

function getparticular_client(task_id){

  return axios({
    method: "get",
    url: `${apiUrl}/companies/get/${task_id}`,
    headers: {Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
            return response;  
    }, error => {
      return error.response;
      console.log(error.response.status);
    })
    .catch(function (error) {
      // console.log(error.response.status);
    });
}


function getjobdocs(task_id){
  return axios({
    method: "get",
    url: `${apiUrl}/uploaddocs/${task_id}`,
    headers: {Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
            return response;  
    }, error => {
      return error.response;
      console.log(error.response.status);
    })
    .catch(function (error) {
      // console.log(error.response.status);
    });
}
function getfilterlist(){
  return axios({
    method: "get",
    url: `${apiUrl}/client-filter-list`,
    headers: { Authorization: `Bearer ${authtoken}` },
  })
  .then(function (response) {
      return response;
    }, error => {
      return error.response;
    })
    .catch(function (error) {
      console.log(error.response.status);
    });
}

function getclientfreq(user_id){
  return axios({
    method: "get",
    url: `${apiUrl}/getclientfreq?user_id=${user_id}`,
    headers: { Authorization: `Bearer ${authtoken}` },
  })
  .then(function (response) {
      return response;
    }, error => {
      return error.response;
    })
    .catch(function (error) {
      console.log(error.response.status);
    });
}

function getGovernanceCalltransdata(page,search,filter,filtertype,sortfield,sortorder,per_page){
  let filters = '';
  if(filter){
     filters = `&filters=${JSON.stringify(filter)}`;
  }
  let typefilter = '';
  if(filtertype!=''){
     typefilter = `&filtertype=${filtertype}`;
  }
  return axios({
    method: "get",
    url: `${apiUrl}/getgovernancetransdata?page=${page}&search=${search}${filters}${typefilter}&sort=${sortfield}&order=${sortorder}&per_page=${per_page}`,
    headers: { Authorization: `Bearer ${authtoken}` },
  })
  .then(function (response) {
      return response;
    }, error => {
      return error.response;
    })
    .catch(function (error) {
      console.log(error.response.status);
    });
}

function editgovernancedata(value,field,id,table=''){
  var bodyFormData = new FormData();
  bodyFormData.append("id", id);
  bodyFormData.append("field", field);
  bodyFormData.append("value", value);
  var api = 'governancedaatedit';
  if(table == 'weeklydaatedit' || table == 'internalcalledit'){
    api = table;
  }
  return axios({
      method: "POST",
      url: `${apiUrl}/${api}`,
      data: bodyFormData,
      headers: {Authorization: `Bearer ${authtoken}` },
    })
      .then(function (response) {
        return response;
      }, error => {
       // return error.response;
        console.log(error.response.status);
      })
      .catch(function (error) {
        // console.log(error.response.status);
      });      
}

function getgovernance(id){
  return axios({
    method: "get",
    url: `${apiUrl}/getgovernance?id=${id}`,
    headers: { Authorization: `Bearer ${authtoken}` },
  })
  .then(function (response) {
      return response;
    }, error => {
      return error.response;
    })
    .catch(function (error) {
      console.log(error.response.status);
    });
}

function editgovernancetransdata(data){
  return axios({
    method: "post",
    url: `${apiUrl}/editgovernancetrans`,
    data: data,
    headers: { Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
         return response;  
    }, error => {
      return error.response;
    //   console.log(error.response.status);
    })
    .catch(function (error) {
      // console.log(error.response.status);
    });
}

function getcllientfrequency(page,search){
  return axios({
    method: "get",
    url: `${apiUrl}/getclientfrequency?page=${page}&search=${search}`,
    headers: { Authorization: `Bearer ${authtoken}` },
  })
  .then(function (response) {
      return response;
    }, error => {
      return error.response;
    })
    .catch(function (error) {
      console.log(error.response.status);
    });
}

function getbillingdata(data){
  console.log(data)
 
  return axios({
    method: "get",
    url: `${apiUrl}/getbillingdata?department=${data.department}&fromdate=${data.fromdate}&todate=${data.todate}&client=${encodeURIComponent(data.client)}&subclient=${encodeURIComponent(data.subclient)}`,
    headers: { Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
      console.log('aaa',response)
         return response;  
    }, error => {
      return error.response;
    //   console.log(error.response.status);
    })
    .catch(function (error) {
      // console.log(error.response.status);
    });
}

function synctotimesheet(data){
  console.log(data)
  let api = 'cronbillingdatabk';
  if(data.department == 'Year End'){
      api = 'cronbillingdata';
  }
  return axios({
    method: "get",
    url: `${apiUrl}/${api}?fromdate=${data.fromdate}&todate=${data.todate}&client=${encodeURIComponent(data.client)}&subclient=${encodeURIComponent(data.subclient)}`,
    headers: { Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
      console.log('aaa',response)
         return response;  
    }, error => {
      return error.response;
    //   console.log(error.response.status);
    })
    .catch(function (error) {
      // console.log(error.response.status);
    });
}

function synctotimesheetunlock(data){
  console.log(data)
  let api = 'cronbillinunlockgdatabk';
  if(data.department == 'Year End'){
      api = 'cronbillinunlockgdata';
  }
  return axios({
    method: "get",
    url: `${apiUrl}/${api}?fromdate=${data.fromdate}&todate=${data.todate}&client=${encodeURIComponent(data.client)}&subclient=${encodeURIComponent(data.subclient)}`,
    headers: { Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
      console.log('aaa',response)
         return response;  
    }, error => {
      return error.response;
    //   console.log(error.response.status);
    })
    .catch(function (error) {
      // console.log(error.response.status);
    });
}

function getbillingdashboarddata(data){
  console.log(data)
 
  return axios({
    method: "get",
    url: `${apiUrl}/getbillingdashboarddata?department=${data.department}&fromdate=${data.fromdate}&todate=${data.todate}&client=${encodeURIComponent(data.client)}&subclient=${encodeURIComponent(data.subclient)}`,
    headers: { Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
      console.log('aaa',response)
         return response;  
    }, error => {
      return error.response;
    //   console.log(error.response.status);
    })
    .catch(function (error) {
      // console.log(error.response.status);
    });
}

function gettransitionData(client_id){
  return axios({
    method: "get",
    url: `${apiUrl}/getClientTransData?client_id=${client_id}`,
    headers: { Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
      console.log('aaa',response)
         return response;  
    }, error => {
      return error.response;
    //   console.log(error.response.status);
    })
    .catch(function (error) {
      // console.log(error.response.status);
    });
}
function updateTransData(client_id,trans_id,field,value){
  var bodyFormData = new FormData();
  bodyFormData.append("client_id", client_id);
  bodyFormData.append("trans_id", trans_id);
  bodyFormData.append("field", field);
  bodyFormData.append("value", value);
  return axios({
    method: "post",
    url: `${apiUrl}/updateTransData`,
    data: bodyFormData,
    headers: { Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
         return response;  
    }, error => {
      return error.response;
    //   console.log(error.response.status);
    })
    .catch(function (error) {
      // console.log(error.response.status);
    });
}

function generateForm(id,client_id,trans_id){

  return axios({
    method: "get",
    url: `${apiUrl}/generateForm?id=${id}&client_id=${client_id}&trans_id=${trans_id}`,
    headers: { Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
         return response;  
    }, error => {
      return error.response;
    //   console.log(error.response.status);
    })
    .catch(function (error) {
      // console.log(error.response.status);
    });
}
function updateTransitionForm(data){
  var bodyFormData = new FormData();
  bodyFormData.append("data", JSON.stringify(data));
  return axios({
    method: "post",
    url: `${apiUrl}/updateTransitionForm`,
    data: bodyFormData,
    headers: { Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
         return response;  
    }, error => {
      return error.response;
    //   console.log(error.response.status);
    })
    .catch(function (error) {
      // console.log(error.response.status);
    });
}

function getClientTransition(page,search,filter,filtertype,sortfield,sortorder){
  let filters = '';
  if(filter){
     filters = `&filters=${JSON.stringify(filter)}`;
  }
  let typefilter = '';
  if(filtertype!=''){
     typefilter = `&filtertype=${filtertype}`;
  }
  return axios({
    method: "get",
    url: `${apiUrl}/getTransitionList?page=${page}&search=${search}${filters}${typefilter}&sort=${sortfield}&order=${sortorder}`,
    headers: { Authorization: `Bearer ${authtoken}` },
  })
  .then(function (response) {
      return response;
    }, error => {
      return error.response;
    })
    .catch(function (error) {
      console.log(error.response.status);
    });
}
function gettransitionType(client_id){
  return axios({
    method: "get",
    url: `${apiUrl}/gettransitionType`,
    headers: { Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
      console.log('aaa',response)
         return response;  
    }, error => {
      return error.response;
    //   console.log(error.response.status);
    })
    .catch(function (error) {
      // console.log(error.response.status);
    });
}
function getTransitionFields(trans_id){
  return axios({
    method: "get",
    url: `${apiUrl}/getTransitionFields?trans_id=${trans_id}`,
    headers: { Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
      console.log('aaa',response)
         return response;  
    }, error => {
      return error.response;
    //   console.log(error.response.status);
    })
    .catch(function (error) {
      // console.log(error.response.status);
    });
}
function getTransitionFieldData(id){
  return axios({
    method: "get",
    url: `${apiUrl}/getTransitionFieldData?id=${id}`,
    headers: { Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
      console.log('aaa',response)
         return response;  
    }, error => {
      return error.response;
    //   console.log(error.response.status);
    })
    .catch(function (error) {
      // console.log(error.response.status);
    });
}
function addtransitionFieldData(data){
  return axios({
    method: "post",
    url: `${apiUrl}/addtransitionFieldData`,
    data: data,
    headers: { Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
         return response;  
    }, error => {
      return error.response;
    //   console.log(error.response.status);
    })
    .catch(function (error) {
      // console.log(error.response.status);
    });
  }

  function download_transitionform_report(client_id,trans_id){
    
    return axios({
      method: "get",
      responseType: 'blob', 
      url: `${apiUrl}/transition-form-export?client_id=${client_id}&trans_id=${trans_id}`,
      headers: { Authorization: `Bearer ${authtoken}`,"Content-Type": "application/vnd.ms-excel" },
    })
    .then(function (response) {
      const outputFilename = `transition-form-report-${Date.now()}.xlsx`;
      const url = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', outputFilename);
      document.body.appendChild(link);
      link.click();
  // return response;
      }, error => {
        return error.response;
      })
      .catch(function (error) {
        console.log(error.response.status);
      });  
  }
  function getusersclientlist(){
    return axios({
      method: "get",
      url: `${apiUrl}/getusersclientlist`,
      headers: { Authorization: `Bearer ${authtoken}` },
    })
    .then(function (response) {
        return response;
      }, error => {
        return error.response;
      })
      .catch(function (error) {
        console.log(error.response.status);
      });
  }
  function addinternalgovernancetransdata(data,flag){
    return axios({
      method: "post",
      url: `${apiUrl}/addinternalgovernancetransdata?flag=${flag}`,
      data: data,
      headers: { Authorization: `Bearer ${authtoken}` },
    })
      .then(function (response) {
           return response;  
      }, error => {
        return error.response;
      //   console.log(error.response.status);
      })
      .catch(function (error) {
        // console.log(error.response.status);
      });
    }

  function addBillingRow(data){
    return axios({
      method: "post",
      url: `${apiUrl}/addbillingrow`,
      data: data,
      headers: { Authorization: `Bearer ${authtoken}` },
    })
      .then(function (response) {
           return response;  
      }, error => {
        return error.response;
      //   console.log(error.response.status);
      })
      .catch(function (error) {
        // console.log(error.response.status);
      });
  }
  function getInternalGovernanceCalltransdata(page,search,filter,filtertype,per_page){
    let filters = '';
    if(filter){
       filters = `&filters=${JSON.stringify(filter)}`;
    }
    let typefilter = '';
    if(filtertype!=''){
       typefilter = `&filtertype=${filtertype}`;
    }
    return axios({
      method: "get",
      url: `${apiUrl}/getinternalgovernancetransdata?page=${page}&search=${search}${filters}${typefilter}&per_page=${per_page}`,
      headers: { Authorization: `Bearer ${authtoken}` },
    })
    .then(function (response) {
        return response;
      }, error => {
        return error.response;
      })
      .catch(function (error) {
        console.log(error.response.status);
      });
  }
  function getinternalgovernance(id){
    return axios({
      method: "get",
      url: `${apiUrl}/getinternalgovernance?id=${id}`,
      headers: { Authorization: `Bearer ${authtoken}` },
    })
    .then(function (response) {
        return response;
      }, error => {
        return error.response;
      })
      .catch(function (error) {
        console.log(error.response.status);
      });
  }
  function editinternalgovernancetransdata(data){
    return axios({
      method: "post",
      url: `${apiUrl}/editinternalgovernancetrans`,
      data: data,
      headers: { Authorization: `Bearer ${authtoken}` },
    })
      .then(function (response) {
           return response;  
      }, error => {
        return error.response;
      //   console.log(error.response.status);
      })
      .catch(function (error) {
        // console.log(error.response.status);
      });
  }
  function getclienttransitionlog(page){
    return axios({
      method: "get",
      url: `${apiUrl}/getclienttransitionlog?page=${page}`,
      headers: {Authorization: `Bearer ${authtoken}` },
    })
      .then(function (response) {
         if(response.status == 200){
              return response;  
         }
      }, error => {
        return error.response;
        console.log(error.response.status);
      })
      .catch(function (error) {
        // console.log(error.response.status);
      });  
  }

  function clienttransitionlogexport(start_date,end_date){
     var sendData = '';
    return axios({
      method: "get",
      responseType: 'blob', 
      url: `${apiUrl}/clienttransitionlogexport?${sendData}`,
      headers: { Authorization: `Bearer ${authtoken}`,"Content-Type": "application/vnd.ms-excel" },
    })
    .then(function (response) {
      const outputFilename = `log-report-${Date.now()}.xlsx`;
      const url = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', outputFilename);
      document.body.appendChild(link);
      link.click();
  // return response;
      }, error => {
        return error.response;
      })
      .catch(function (error) {
        console.log(error.response.status);
      });  
  }
  function addinternaltransdata(data,flag){
    return axios({
      method: "post",
      url: `${apiUrl}/addinternaltransdata?flag=${flag}`,
      data: data,
      headers: { Authorization: `Bearer ${authtoken}` },
    })
      .then(function (response) {
           return response;  
      }, error => {
        return error.response;
      //   console.log(error.response.status);
      })
      .catch(function (error) {
        // console.log(error.response.status);
      });
  }
  function getInternalCalltransdata(page,search,filter,filtertype,per_page){
    let filters = '';
    if(filter){
       filters = `&filters=${JSON.stringify(filter)}`;
    }
    let typefilter = '';
    if(filtertype!=''){
       typefilter = `&filtertype=${filtertype}`;
    }
    return axios({
      method: "get",
      url: `${apiUrl}/getinternaltransdata?page=${page}&search=${search}${filters}${typefilter}&per_page=${per_page}`,
      headers: { Authorization: `Bearer ${authtoken}` },
    })
    .then(function (response) {
        return response;
      }, error => {
        return error.response;
      })
      .catch(function (error) {
        console.log(error.response.status);
      });
  }
  function getinternalcall(id){
    return axios({
      method: "get",
      url: `${apiUrl}/getinternalcall?id=${id}`,
      headers: { Authorization: `Bearer ${authtoken}` },
    })
    .then(function (response) {
        return response;
      }, error => {
        return error.response;
      })
      .catch(function (error) {
        console.log(error.response.status);
      });
  }
  function editinternaltransdata(data){
    return axios({
      method: "post",
      url: `${apiUrl}/editinternaltrans`,
      data: data,
      headers: { Authorization: `Bearer ${authtoken}` },
    })
      .then(function (response) {
           return response;  
      }, error => {
        return error.response;
      //   console.log(error.response.status);
      })
      .catch(function (error) {
        // console.log(error.response.status);
      });
  }
  function generateSOPForm(client_id,trans_id){

    return axios({
      method: "get",
      url: `${apiUrl}/getsubclientSOP?client_id=${client_id}&trans_id=${trans_id}`,
      headers: { Authorization: `Bearer ${authtoken}` },
    })
      .then(function (response) {
           return response;  
      }, error => {
        return error.response;
      //   console.log(error.response.status);
      })
      .catch(function (error) {
        // console.log(error.response.status);
      });
  }
  function updateTransitionSOPForm(data){
    var bodyFormData = new FormData();
    bodyFormData.append("data", JSON.stringify(data));
    return axios({
      method: "post",
      url: `${apiUrl}/updateTransitionSOPForm`,
      data: bodyFormData,
      headers: { Authorization: `Bearer ${authtoken}` },
    })
      .then(function (response) {
           return response;  
      }, error => {
        return error.response;
      //   console.log(error.response.status);
      })
      .catch(function (error) {
        // console.log(error.response.status);
      });
  }
  function xeroConnect(client){
    return axios({
      method: "get",
      url: `${apiUrl}/xeroConnect?client=${client}`,
      headers: { Authorization: `Bearer ${authtoken}` },
    })
      .then(function (response) {
           return response;  
      }, error => {
        return error.response;
      //   console.log(error.response.status);
      })
      .catch(function (error) {
        // console.log(error.response.status);
      });
  }
  function xeroconnectstatus(){
    return axios({
      method: "get",
      url: `${apiUrl}/xeroconnectstatus`,
      headers: { Authorization: `Bearer ${authtoken}` },
    })
      .then(function (response) {
           return response;  
      }, error => {
        return error.response;
      //   console.log(error.response.status);
      })
      .catch(function (error) {
        // console.log(error.response.status);
      });
  }
  function xeroDisconnect(client){
    return axios({
      method: "get",
      url: `${apiUrl}/disconnect`,
      headers: { Authorization: `Bearer ${authtoken}` },
    })
      .then(function (response) {
           return response;  
      }, error => {
        return error.response;
      //   console.log(error.response.status);
      })
      .catch(function (error) {
        // console.log(error.response.status);
      });
  }
  function pushxero(data,inputfields){
    var bodyFormData = new FormData();
    bodyFormData.append("filedata", JSON.stringify(data));
    bodyFormData.append("fromdate", inputfields.fromdate);
    bodyFormData.append("todate", inputfields.todate);
    bodyFormData.append("department", inputfields.department);
    bodyFormData.append("client", inputfields.client);
    bodyFormData.append("description", inputfields.description);
    bodyFormData.append("quantity", inputfields.quantity);
    bodyFormData.append("amount", inputfields.amount);
    bodyFormData.append("account_code", inputfields.account_code);
    return axios({
      method: "post",
      url: `${apiUrl}/pushxero`,
      data: bodyFormData,
      headers: { Authorization: `Bearer ${authtoken}` },
    })
      .then(function (response) {
           return response;  
      }, error => {
        return error.response;
      //   console.log(error.response.status);
      })
      .catch(function (error) {
        // console.log(error.response.status);
      });
  }
  function getusers(){
    return axios({
      method: "get",
      url: `${apiUrl}/getuserlist`,
      headers: { Authorization: `Bearer ${authtoken}` },
    })
      .then(function (response) {
           return response;  
      }, error => {
        return error.response;
      //   console.log(error.response.status);
      })
      .catch(function (error) {
        // console.log(error.response.status);
      });
  }
  function addBillingRowbelowtotal(data,type){
    return axios({
      method: "post",
      url: `${apiUrl}/addBillingRowbelowtotal?type=${type}`,
      data: data,
      headers: { Authorization: `Bearer ${authtoken}` },
    })
      .then(function (response) {
           return response;  
      }, error => {
        return error.response;
      //   console.log(error.response.status);
      })
      .catch(function (error) {
        // console.log(error.response.status);
      });
  }
  function governanceCalldashboard(id,downloadexcel=0){
    if(downloadexcel==0){
    return axios({
      method: "get",
      url: `${apiUrl}/governanceCalldashboard?user_id=${id}`,
      headers: { Authorization: `Bearer ${authtoken}` },
    })
      .then(function (response) {
           return response;  
      }, error => {
        return error.response;
      //   console.log(error.response.status);
      })
      .catch(function (error) {
        // console.log(error.response.status);
      });
    }else{
      return axios({
        method: "get",
        responseType: 'blob', 
        url: `${apiUrl}/governanceCalldashboard?user_id=${id}&downloadexcel=${downloadexcel}`,
        headers: { Authorization: `Bearer ${authtoken}`,"Content-Type": "application/vnd.ms-excel" },
      })
      .then(function (response) {
        const outputFilename = `Governance-Dashboard-report-${Date.now()}.xlsx`;
        const url = URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', outputFilename);
        document.body.appendChild(link);
        link.click();
    // return response;
        }, error => {
          return error.response;
        })
        .catch(function (error) {
          console.log(error.response.status);
        });  
    }
  }

  function getGovernanceMISdashboard(id,downloadexcel=0){
    if(downloadexcel==0){
      return axios({
        method: "get",
        url: `${apiUrl}/getGovernanceMISdashboard?user_id=${id}&downloadexcel=${downloadexcel}`,
        headers: { Authorization: `Bearer ${authtoken}` },
      })
        .then(function (response) {
            return response;  
        }, error => {
          return error.response;
        //   console.log(error.response.status);
        })
        .catch(function (error) {
          // console.log(error.response.status);
        });
      }else{
        return axios({
          method: "get",
          responseType: 'blob', 
          url: `${apiUrl}/getGovernanceMISdashboard?user_id=${id}&downloadexcel=${downloadexcel}`,
          headers: { Authorization: `Bearer ${authtoken}`,"Content-Type": "application/vnd.ms-excel" },
        })
        .then(function (response) {
          const outputFilename = `MIS-governance-report-${Date.now()}.xlsx`;
          const url = URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', outputFilename);
          document.body.appendChild(link);
          link.click();
      // return response;
          }, error => {
            return error.response;
          })
          .catch(function (error) {
            console.log(error.response.status);
          });  
      }
    }
  function editgovernancefreqdata(value,field,id,table=''){
    var bodyFormData = new FormData();
    bodyFormData.append("id", id);
    bodyFormData.append("field", field);
    bodyFormData.append("value", value);
    var api = 'editgovernancefreqdata';

    return axios({
        method: "POST",
        url: `${apiUrl}/${api}`,
        data: bodyFormData,
        headers: {Authorization: `Bearer ${authtoken}` },
      })
        .then(function (response) {
          return response;
        }, error => {
         // return error.response;
          console.log(error.response.status);
        })
        .catch(function (error) {
          // console.log(error.response.status);
        });      
  }

  function getGovernanceMISActiondashboard(id,downloadexcel=0){
    if(downloadexcel==0){
    return axios({
      method: "get",
      url: `${apiUrl}/getGovernanceMISActiondashboard?user_id=${id}&downloadexcel=${downloadexcel}`,
      headers: { Authorization: `Bearer ${authtoken}` },
    })
      .then(function (response) {
           return response;  
      }, error => {
        return error.response;
      //   console.log(error.response.status);
      })
      .catch(function (error) {
        // console.log(error.response.status);
      });
    }else{
      return axios({
        method: "get",
        responseType: 'blob', 
        url: `${apiUrl}/getGovernanceMISActiondashboard?user_id=${id}&downloadexcel=${downloadexcel}`,
        headers: { Authorization: `Bearer ${authtoken}`,"Content-Type": "application/vnd.ms-excel" },
      })
      .then(function (response) {
        const outputFilename = `MIS-governance-action-report-${Date.now()}.xlsx`;
        const url = URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', outputFilename);
        document.body.appendChild(link);
        link.click();
    // return response;
        }, error => {
          return error.response;
        })
        .catch(function (error) {
          console.log(error.response.status);
        });  
    }
    }
    function governanceCalldashboarddetails(month,title,username){
      return axios({
        method: "get",
        url: `${apiUrl}/getGovernanceMISdashboarddetails?month_year=${month}&type=${title}&user_id=${username}`,
        headers: { Authorization: `Bearer ${authtoken}` },
      })
        .then(function (response) {
             return response;  
        }, error => {
          return error.response;
        //   console.log(error.response.status);
        })
        .catch(function (error) {
          // console.log(error.response.status);
        });
    }
    function governanceCalldashboardactiondetails(month,title,username){
      return axios({
        method: "get",
        url: `${apiUrl}/getGovernanceMISActiondetails?month_year=${month}&type=${title}&user_id=${username}`,
        headers: { Authorization: `Bearer ${authtoken}` },
      })
        .then(function (response) {
             return response;  
        }, error => {
          return error.response;
        //   console.log(error.response.status);
        })
        .catch(function (error) {
          // console.log(error.response.status);
        });
    }

    function saveManagerRejection(data){
      return axios({
        method: "post",
        url: `${apiUrl}/saveManagerRejection`,
        data: data,
        headers: { Authorization: `Bearer ${authtoken}` },
      })
        .then(function (response) {
             return response;  
        }, error => {
          return error.response;
        //   console.log(error.response.status);
        })
        .catch(function (error) {
          // console.log(error.response.status);
        });
    }
    
    function getChecklistMaster(){
      return axios({
        method: "get",
        url: `${apiUrl}/getChecklistMaster`,
        headers: { Authorization: `Bearer ${authtoken}` },
      })
        .then(function (response) {
             return response;  
        }, error => {
          return error.response;
        //   console.log(error.response.status);
        })
        .catch(function (error) {
          // console.log(error.response.status);
        });
    }

    function generateFormChecklist(id,phase_id,sub_cat){

      return axios({
        method: "get",
        url: `${apiUrl}/generateFormChecklist?id=${id}&phase_id=${phase_id}&sub_cat=${sub_cat}`,
        headers: { Authorization: `Bearer ${authtoken}` },
      })
        .then(function (response) {
             return response;  
        }, error => {
          return error.response;
        //   console.log(error.response.status);
        })
        .catch(function (error) {
          // console.log(error.response.status);
        });
    }
    
    function updateChecklistForm(data){
      var bodyFormData = new FormData();
      bodyFormData.append("data", JSON.stringify(data));
      return axios({
        method: "post",
        url: `${apiUrl}/updateChecklistForm`,
        data: bodyFormData,
        headers: { Authorization: `Bearer ${authtoken}` },
      })
        .then(function (response) {
             return response;  
        }, error => {
          return error.response;
        //   console.log(error.response.status);
        })
        .catch(function (error) {
          // console.log(error.response.status);
        });
    }

    function updateChecklistMaster(id,field,value){
      var bodyFormData = new FormData();
      bodyFormData.append("id", id);
      bodyFormData.append("field", field);
      bodyFormData.append("value", value);
      return axios({
        method: "post",
        url: `${apiUrl}/updateChecklistMaster`,
        data: bodyFormData,
        headers: { Authorization: `Bearer ${authtoken}` },
      })
        .then(function (response) {
             return response;  
        }, error => {
          return error.response;
        //   console.log(error.response.status);
        })
        .catch(function (error) {
          // console.log(error.response.status);
        });
    }
    
    function addChecklistMaster(data){
      var bodyFormData = new FormData();
      bodyFormData.append("data", JSON.stringify(data));
      return axios({
        method: "post",
        url: `${apiUrl}/addChecklistMaster`,
        data: bodyFormData,
        headers: { Authorization: `Bearer ${authtoken}` },
      })
        .then(function (response) {
             return response;  
        }, error => {
          return error.response;
        //   console.log(error.response.status);
        })
        .catch(function (error) {
          // console.log(error.response.status);
        });
    }
    function getChecklistFeedback(page,filter){
      let filters = '';
      if(filter){
        filters = `&filters=${JSON.stringify(filter)}`;
      }
      let typefilter = '';
      
      return axios({
        method: "get",
        url: `${apiUrl}/getChecklistFeedback?page=${page}&search=${filters}${typefilter}`,
        headers: { Authorization: `Bearer ${authtoken}` },
      })
        .then(function (response) {
             return response;  
        }, error => {
          return error.response;
        //   console.log(error.response.status);
        })
        .catch(function (error) {
          // console.log(error.response.status);
        });
    }
    function download_sample(){
      return axios({
        method: "get",
        responseType: 'blob', 
        url: `${apiUrl}/download_sample`,
        headers: { Authorization: `Bearer ${authtoken}`,"Content-Type": "application/vnd.ms-excel" },
      })
      .then(function (response) {
        const outputFilename = `sample.xlsx`;
        const url = URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', outputFilename);
        document.body.appendChild(link);
        link.click();
    // return response;
        }, error => {
          return error.response;
        })
        .catch(function (error) {
          console.log(error.response.status);
        });  

    }
    function checklist_import(master_id,phase_id,file,csubcat){
      var bodyFormData = new FormData();
      bodyFormData.append("master_id", master_id);
      bodyFormData.append("phase_id",phase_id);
      bodyFormData.append("sub_cat",csubcat);
      bodyFormData.append("file",file);
      return axios({
        method: "post",
        url: `${apiUrl}/checklist_import`,
        data:bodyFormData,
        headers: { "Content-Type": "multipart/form-data",Authorization: `Bearer ${authtoken}` },
      }).then(function (response) {
        if(response.status == 200){
           return response;  
        }
     }, error => {
         return error.response;
       // console.log(error.response.status);
     })
     .catch(function (error) {
       // console.log(error.response.status);
     });  
    }
    function getsubclient(client){
      return axios({
        method: "get",
        url: `${apiUrl}/getsubclient?client=${client}`,
        headers: {Authorization: `Bearer ${authtoken}` },
      })
        .then(function (response) {
           if(response.status == 200){
                return response;  
           }
        }, error => {
          return error.response;
          console.log(error.response.status);
        })
        .catch(function (error) {
          // console.log(error.response.status);
        });
    }
    function checklist_feedback_report(filter){
      let filters = '';
      if(filter){
         filters = `&filters=${JSON.stringify(filter)}`;
      }
     
      return axios({
        method: "get",
        responseType: 'blob', 
        url: `${apiUrl}/checklist_feedback_report?search=''${filters}`,
        headers: { Authorization: `Bearer ${authtoken}`,"Content-Type": "application/vnd.ms-excel" },
      })
      .then(function (response) {
        const outputFilename = `checklist-report-${Date.now()}.xlsx`;
        const url = URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', outputFilename);
        document.body.appendChild(link);
        link.click();
    // return response;
        }, error => {
          return error.response;
        })
        .catch(function (error) {
          console.log(error.response.status);
        });  
    }
    function deleteCommonTableData(id,table){
      var bodyFormData = new FormData();
      bodyFormData.append("id", id);
      bodyFormData.append("table",table);
      return axios({
        method: "post",
        data:bodyFormData,
        url: `${apiUrl}/deleteCommonTableData`,
        headers: {Authorization: `Bearer ${authtoken}` },
      })
        .then(function (response) {
           if(response.status == 200){
                return response;  
           }
        }, error => {
          return error.response;
          console.log(error.response.status);
        })
        .catch(function (error) {
          // console.log(error.response.status);
        });
    }
    function editManagerRejection(data){
      return axios({
        method: "post",
        url: `${apiUrl}/editManagerRejection`,
        data: data,
        headers: { Authorization: `Bearer ${authtoken}` },
      })
        .then(function (response) {
             return response;  
        }, error => {
          return error.response;
        //   console.log(error.response.status);
        })
        .catch(function (error) {
          // console.log(error.response.status);
        });
    }
    function getsinglekrotypeData(year='',user_id){
       year = '2024-05-01';
      return axios({
        method: "get",
        url: `${apiUrl}/getsinglekrotypeData?year=${year}&user_id=${user_id}`,
        headers: {Authorization: `Bearer ${authtoken}` },
      })
        .then(function (response) {
           if(response.status == 200){
                return response;  
           }
        }, error => {
          return error.response;
          console.log(error.response.status);
        })
        .catch(function (error) {
          // console.log(error.response.status);
        });
    }
    function getunlockbillingclientlist(fromdate,todate){
      return axios({
        method: "get",
        url: `${apiUrl}/getUnlockbillingclientlist?fromdate=${fromdate}&todate=${todate}`,
        headers: {Authorization: `Bearer ${authtoken}` },
      })
        .then(function (response) {
           if(response.status == 200){
                return response;  
           }
        }, error => {
          return error.response;
          console.log(error.response.status);
        })
        .catch(function (error) {
          // console.log(error.response.status);
        });
    }
    function getunlockbillingdata(data){
      console.log(data)
     
      return axios({
        method: "get",
        url: `${apiUrl}/getunlockbillingdata?department=${data.department}&fromdate=${data.fromdate}&todate=${data.todate}&client=${encodeURIComponent(data.client)}&subclient=${encodeURIComponent(data.subclient)}`,
        headers: { Authorization: `Bearer ${authtoken}` },
      })
        .then(function (response) {
          console.log('aaa',response)
             return response;  
        }, error => {
          return error.response;
        //   console.log(error.response.status);
        })
        .catch(function (error) {
          // console.log(error.response.status);
        });
    }
    function billingunlock_export(data){
      
      return axios({
        method: "get",
        responseType: 'blob', 
        url: `${apiUrl}/billingunlock_export?department=${data.department}&fromdate=${data.fromdate}&todate=${data.todate}&client=${encodeURIComponent(data.client)}&subclient=${encodeURIComponent(data.subclient)}`,
        headers: { Authorization: `Bearer ${authtoken}`,"Content-Type": "application/vnd.ms-excel" },
      })
      .then(function (response) {
        const outputFilename = `billing-report-${Date.now()}.xlsx`;
        const url = URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', outputFilename);
        document.body.appendChild(link);
        link.click();
    // return response;
        }, error => {
          return error.response;
        })
        .catch(function (error) {
          console.log(error.response.status);
        });  
    }
    function getIncentiveMaster(){
      return axios({
        method: "get",
        url: `${apiUrl}/getIncentiveMaster`,
        headers: { Authorization: `Bearer ${authtoken}` },
      })
        .then(function (response) {
             return response;  
        }, error => {
          return error.response;
        //   console.log(error.response.status);
        })
        .catch(function (error) {
          // console.log(error.response.status);
        });
    }
    function updateIncentiveMaster(id,field,value){
      var bodyFormData = new FormData();
      bodyFormData.append("id", id);
      bodyFormData.append("field", field);
      bodyFormData.append("value", value);
      return axios({
        method: "post",
        url: `${apiUrl}/updateIncentiveMaster`,
        data: bodyFormData,
        headers: { Authorization: `Bearer ${authtoken}` },
      })
        .then(function (response) {
             return response;  
        }, error => {
          return error.response;
        //   console.log(error.response.status);
        })
        .catch(function (error) {
          // console.log(error.response.status);
        });
    }
    function getIncentives(client,month,download=0){
      if(download==0){
        return axios({
          method: "get",
          url: `${apiUrl}/getIncentives?client=${client}&month=${month}&download=${download}`,
          headers: { Authorization: `Bearer ${authtoken}` },
        })
              .then(function (response) {
                return response;  
          }, error => {
            return error.response;
          //   console.log(error.response.status);
          })
          .catch(function (error) {
            // console.log(error.response.status);
          });
        }else{
          return axios({
            method: "get",
            responseType: 'blob', 
            url: `${apiUrl}/getIncentives?client=${client}&download=${download}`,
            headers: { Authorization: `Bearer ${authtoken}`,"Content-Type": "application/vnd.ms-excel" },
          })
          .then(function (response) {
            const outputFilename = `Incentive-report-${Date.now()}.xlsx`;
            const url = URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', outputFilename);
            document.body.appendChild(link);
            link.click();
        // return response;
            }, error => {
              return error.response;
            })
            .catch(function (error) {
              console.log(error.response.status);
            });  
        }
     }

    function billingDataPDF(data){
      console.log(data)
     
      return axios({
        method: "get",
        responseType: 'blob', 
        url: `${apiUrl}/billingDataPDF?department=${data.department}&fromdate=${data.fromdate}&todate=${data.todate}&client=${encodeURIComponent(data.client)}&subclient=${encodeURIComponent(data.subclient)}`,
        headers: { Authorization: `Bearer ${authtoken}` },
      })
        .then(function (response) {
          return response
        }, error => {
          return error.response;
        //   console.log(error.response.status);
        })
        .catch(function (error) {
          // console.log(error.response.status);
        });
    }
    function governanceChartData(month){
      return axios({
        method: "get",
        url: `${apiUrl}/governanceChartData?month=${month}`,
        headers: { Authorization: `Bearer ${authtoken}` },
      })
        .then(function (response) {
             return response;  
        }, error => {
          return error.response;
        //   console.log(error.response.status);
        })
        .catch(function (error) {
          // console.log(error.response.status);
        });
    }
   