import { useState, useEffect,useRef } from "react";
import React from 'react';
import {
  Button,
  Card,
  Table,
  Container,
  Row,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  FormGroup,
  Form,
  Input,
  CardHeader,
} from "reactstrap";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-theme-alpine-dark.css";
import "../../assets/css/custom.css";
import Header from "components/Headers/Header.js";
import { clientmanageService } from "../../services/clientmanageService.js";
import 'assets/css/themecustom-style.css';
import { toast } from 'react-toastify';
import { useHistory,useParams } from "react-router-dom";
import Select from 'react-select';
import { taskmanageService } from '../../services/taskmanageService';
const axios = require("axios");

const ChecklistFormData = (props) => {
  const user_info = JSON.parse(localStorage.getItem("user-info"));
  const params = useParams();
  const [disablestate, setdisablestate] = useState(false);
  const [currentphase, setcurrentPhase] = useState('');

  const [checklistData, setCheckListData] = useState([]);
  const [checklistMasterData, setCheckListMasterData] = useState([]);
  const formRef = useRef();
  const [reviewMasterChecklist,setReviewChecklistMasterModalOpen] = useState(false);  
  const [reviewChecklist,setReviewChecklistModalOpen] = useState(false);
  const [checkstate, setchecklistState] = useState({task_id:null,phase_id:null,data:null});
  const [sendmailpopupdetails,setsendmailPopupDetails] = useState(false);
useEffect(() => {
  console.log(params)
  addreviewchecklist(params.task_id,params.main_id,params.type)
}, []);

useEffect(() => { // when load then click on first phase button
  if (checklistMasterData.length > 0) {
    addreviewchecklist(params.task_id, checklistMasterData[0].phase_id, "phases");
  }
}, [checklistMasterData]);

function add_checklist_data(e) {
  setdisablestate(true)
  e.preventDefault();
  var type = '';
  if(user_info.phase_id != null && user_info.phase_id != ''){
     type ='phases';
  }else{
     type ='tasks';
  }

  console.log(checkstate)
  taskmanageService.updateDashboardChecklistData(checkstate,type,sub_cat).then((response) => {
    console.log(response);

    if (response.status == 200) {
      toast.success(response.data.message);
      setReviewChecklistModalOpen(!reviewChecklist);
      setReviewChecklistMasterModalOpen(false);
    }else{
      toast.error(response.data.message);
    }
    setdisablestate(false)
  });
}
function addreviewchecklist(id,main_id='',type='tasks'){
  setCheckListData([]);
  getchecklistData(id,main_id,type)
  
}
const[sub_cat,setSubCat]=useState('');
function getchecklistData(task_id,id,type){
  setcurrentPhase(id)
    taskmanageService.getchecklistData(task_id,type,id).then((response) => {
      if(type == 'phases'){
        console.log(response.data.data)
        setCheckListData(response.data.data);
        if (response.data.data && typeof response.data.data === 'object') {
          const firstKey = Object.keys(response.data.data)[0]; // Get the first key
          setSubCat(firstKey);
          
        }
      
        setchecklistState({ ...checkstate,data: response.data.data,task_id: response.data.task_id,phase_id: response.data.phase_id}); 
       
      }else{
        setCheckListMasterData(response.data.data)
        getchecklistSubCat();
      }
      
    });
    if(type == 'phases'){
      setReviewChecklistModalOpen(!reviewChecklist);
    }else{
      setReviewChecklistMasterModalOpen(!reviewMasterChecklist);
    }
   
};
function getchecklistSubCat(){
  taskmanageService.getchecklistSubCatData(currentphase).then((response) => {
  })

}
function inputhandlerchecklist(e, field, index,form_id,task_id,phase_id,master_id) {
  console.log(field+"=="+sub_cat+'index=='+index)
  console.log(checklistData)
  console.log(checklistData[sub_cat])
  let temp_m = [...checklistData[sub_cat]];
  let temp_ele = { ...temp_m[index] };

  let temp_st = { ...checkstate.data[sub_cat] };
  console.log(temp_st)
  var value = '';
  console.log(field)
  if (field === "phase_value" || field === "qa_value") {
    const { checked } = e.target;
    if (!temp_st[index]) {
      temp_st[index] = {}; // Ensure the object exists
    }
    temp_st[index][field] = checked ? 1 : 0;
    value = checked ? 1 : 0;
  }else if (field === "all_phase_value" || field === "all_qa_value") {
    const { checked } = e.target;
    const checkfield = field === "all_phase_value" ? 'phase_value' : 'qa_value';
    Object.keys(temp_st).forEach((index) => {
      if (!temp_st[index]) {
        temp_st[index] = {}; // Ensure the object exists if it's not already
      }
      temp_st[index][checkfield] = checked ? 1 : 0;
      value = checked ? 1 : 0;
    })
    
  } else if(field == "phase_rejection" || field === "qa_rejection"){
      if(field == 'phase_rejection'){
        if(temp_ele.phase_feedback == "" || temp_ele.phase_feedback == null){
          toast.error("Please fill feedback to auditpod.");
          return;
        }
      }
      if(field == 'qa_rejection'){
        if(temp_ele.qa_feedback == ""  || temp_ele.qa_feedback == null){
          toast.error("Please fill feedback to QA.");
          return;
        }
      }
    if (!temp_st[index]) {
      temp_st[index] = {}; // Ensure the object exists
    }
    temp_st[index][field] = e;
    value = e;
  }else {
    if (!temp_st[index]) {
      temp_st[index] = {}; // Ensure the object exists
    }
    temp_st[index][field] = e.target.value;
    value = e.target.value;
  }
  if (field !== "all_phase_value" && field !== "all_qa_value") {
    temp_ele[field] = value;
    temp_ele.editing = false;
    temp_m[index] = temp_ele;

  }

  //setCheckListData(temp_m);

  //setchecklistState({ ...checkstate, data: temp_st });
  setCheckListData(prevState => ({
    ...prevState,
    [sub_cat]: temp_m // Update specific sub_cat with temp_m
}));
  setchecklistState(prevState => ({
    ...prevState,
    data: {
      ...prevState.data, // Preserve existing data
      [sub_cat]: temp_st // Update specific sub_cat with temp_st
    }
  }));

  // taskmanageService.editChecklistForm(value, field, form_id, task_id,phase_id,master_id).then((response) => {
      
  // })
}
function inputhandlerchecklist1(e, field, index,form_id,task_id,phase_id,master_id,sub_cat) {
  console.log(checklistData)
  console.log(checklistData[sub_cat])
  let temp_m = [...checklistData[sub_cat]];
  let temp_ele = { ...temp_m[index] };

  let temp_st = { ...checkstate.data[sub_cat] };
  console.log(temp_st)
  var value = '';
  console.log(field)
  if (field === "phase_value" || field === "qa_value") {
    const { checked } = e.target;
    if (!temp_st[index]) {
      temp_st[index] = {}; // Ensure the object exists
    }
    temp_st[index][field] = checked ? 1 : 0;
    value = checked ? 1 : 0;
  } else if(field == "phase_rejection" || field === "qa_rejection"){
      if(field == 'phase_rejection'){
        if(temp_ele.phase_feedback == "" || temp_ele.phase_feedback == null){
          toast.error("Please fill feedback to auditpod.");
          return;
        }
      }
      if(field == 'qa_rejection'){
        if(temp_ele.qa_feedback == ""  || temp_ele.qa_feedback == null){
          toast.error("Please fill feedback to QA.");
          return;
        }
      }
    if (!temp_st[index]) {
      temp_st[index] = {}; // Ensure the object exists
    }
    temp_st[index][field] = e;
    value = e;
  }else {
    if (!temp_st[index]) {
      temp_st[index] = {}; // Ensure the object exists
    }
    temp_st[index][field] = e.target.value;
    value = e.target.value;
  }

  temp_ele[field] = value;
  temp_ele.editing = false;
  temp_m[index] = temp_ele;
  console.log("dd")
  console.log(temp_m)

  //setCheckListData(temp_m);

  //setchecklistState({ ...checkstate, data: temp_st });
  setCheckListData(prevState => ({
    ...prevState,
    [sub_cat]: temp_m // Update specific sub_cat with temp_m
}));
  setchecklistState(prevState => ({
    ...prevState,
    data: {
      ...prevState.data, // Preserve existing data
      [sub_cat]: temp_st // Update specific sub_cat with temp_st
    }
  }));

  taskmanageService.editChecklistForm(value, field, form_id, task_id,phase_id,master_id).then((response) => {
      
  })
}
const[remarkdata,setRemarkData]=useState(null);
const[remarkdataType,setRemarkDataType]=useState(null);
const[remarkIndex,setRemarkIndex]=useState(null);
const handleeditRow = (index,sub_cat,type,remarkdata) => {
    let temp_state = [...checklistData[sub_cat]];
    let temp_element = { ...temp_state[index] };
    temp_element.editing = true;
    temp_state[index] = temp_element;
    setCheckListData(prevState => ({
      ...prevState,
      [sub_cat]: temp_state // Update specific sub_cat with temp_m
  }));
  console.log(type)
  if((type=='qa_feedback' && user_info.role_id == 2) || type=='phase_feedback'){
    console.log(remarkdata)
    setRemarkData(remarkdata);
    setRemarkDataType(type);
    setRemarkIndex(index)
    setsendmailPopupDetails(true);
  }
    //setCheckListData(temp_state);
};
const scrollContainerRef = useRef(null);
  const scrollLeft = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollLeft -= 100; // Adjust scroll distance as needed
    }
  };

  const scrollRight = () => {
   
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollLeft += 100; // Adjust scroll distance as needed
    }
  };
  const saveRemark=() => {
    setsendmailPopupDetails(false);
    let temp_state = [...checklistData[sub_cat]];
    let temp_element = { ...temp_state[remarkIndex] };
    console.log(temp_element)
  }
  const containerStyle = {
    overflowWrap: 'break-word',  // Or use 'word-wrap: break-word' if needed for compatibility
    wordWrap: 'break-word',      // For legacy browsers
    wordBreak: 'break-word',     // Ensures words are broken to fit the container
    maxWidth: '100px',           // Set a fixed width for the wrapping to work
  };
  return (
    <>
      <Header />
      <Container className="mt--7"  fluid>
      {(user_info.phase_id == null)? 
      <>
      <Row >
      <h3>Checklist Phases </h3>
      </Row>
      <Row >
      {checklistMasterData.length > 0 && checklistMasterData  ? (
                        checklistMasterData.map((item, i) => (
               
                 <button type="button"  className=" btn btn-secondary text-center" 
                          style={{ backgroundColor: currentphase === item.phase_id ? '#11cdef' : 'initial' }}
                          onClick={(e) => addreviewchecklist(params.task_id,item.phase_id,'phases')}>
                 {item.desc}
               </button> 
                 ))
                 ) : (
                  <div>
                  <span >No Data Found</span>
                  </div>
                )}
              
  
          </Row>
          </>
          :null}

          <Row className="mt-2">
      
    </Row>
        <Row className="mt-5">
          <div className="col" style={{padding:'0%'}}>
            <Card className="shadow">
            {checklistData ?
              <CardHeader>
              {checklistData && Object.keys(checklistData).length > 0 && sub_cat !=='' ? (
        Object.keys(checklistData).map((subCat, index) => (
          <button
            key={index} // Ensure each button has a unique key
            type="button"
            className="btn btn-secondary text-center"
            style={{ backgroundColor: subCat === sub_cat ? '#11cdef' : 'initial' }}
            onClick={() => setSubCat(subCat)} // Update state on click
          >
            {subCat || sub_cat}
          </button>
        ))
      ) : ''}
              </CardHeader> : ''}
            {checklistData[sub_cat] ?
            <>
            <div>
              
            </div>
            <h2 className="mt-3">Checklist Form</h2>
            <span style={{color:"red",fontSize:"12px"}}> Data is saved only after click on save button.</span>
            <div className="scroll-div"><button className="scroll-button" onClick={scrollLeft}><i className="fa fa-angle-left"></i></button>&nbsp;&nbsp;<button className="scroll-button" onClick={scrollRight}><i className="fa fa-angle-right"></i></button></div>
            
            <Form role="form" ref={formRef} id="add_checklist_form" > 
            <div className="scroll-container"  ref={scrollContainerRef}>
              <div className="scroll-content">
            <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th >Sr. No.</th>
                    <th className="whitespace">Category</th>
                    <th className="whitespace">Checklist</th>
                    <th className="whitespace">Responsibility</th>
                    <th  className="whitespace">Auditpod Checks<br/>
                    { (user_info.phase_id != null) ?
                    <input
                    type="checkbox"
                    name="all_phase_value"
                    
      
                    onChange={(e) => {
                      if (user_info.phase_id != null) {
                        inputhandlerchecklist(e,'all_phase_value');
                      } else {
                        // No action needed if user_info.phase_id is false
                      }
                    }}
                  /> : null}
                    </th>
                    {(user_info.phase_id == null)? 
                    <>
                    <th  className="whitespace">QA Checks<br/>
                    <input
                    type="checkbox"
                    name="all_qa_value"
                    onChange={(e) => inputhandlerchecklist(e, "all_qa_value")}
                  />

                    </th>
                    <th  className="whitespace">Remarks by AuditPod</th>
                    <th  className="whitespace">Rejection to AuditPod</th>
                    <th  className="whitespace">Financial Impact</th>
                    <th  className="whitespace">Feedback to AuditPod</th>
                    <th  className="whitespace">Rejection to QA</th>
                    <th  className="whitespace">Feedback to QA</th>
                    </>
                    : 
                    <>
                    <th>Latest updated</th>
                    <th>Remarks</th></>}
                     
                  </tr>
                </thead>
                <tbody>
                {  checklistData[sub_cat] && checklistData[sub_cat].length > 0  ? (
                        checklistData[sub_cat].map((list, i) => (
                <tr key={i} className={((i % 2 == 0)? 'blue-row':(i % 3 == 0)?'purple-row':'orange-row') } style={{background: (list.phase_rejection == 1 || list.qa_rejection == 1)?`#fdf42a`: null}}>
                  <td>{i+1}</td>
                  <td className="whitespace">{list.category}</td>
                  <td className="whitespace">{list.checklist}</td>
                  <td className="whitespace">{list.responsibility}</td>
                  <td>
                  <input
                    type="checkbox"
                    id={`requiredPhaseCheckbox${i}`}
                    name="phase_value"
                    checked={checkstate.data && checkstate.data[sub_cat] && checkstate.data[sub_cat][i] ? checkstate.data[sub_cat][i].phase_value === 1 : false}
                    value={checkstate.data && checkstate.data[sub_cat] ? checkstate.data[sub_cat].phase_value : ''}
                    onChange={(e) => {
                      if (user_info.phase_id != null) {
                        inputhandlerchecklist(e, "phase_value", i, list.id, checkstate.task_id, list.phase_id, list.master_id,sub_cat);
                      } else {
                        // No action needed if user_info.phase_id is false
                      }
                    }}
                  />
                  </td>
                  {(user_info.phase_id == null)?
                  <>
                  <td>
                  <input
                    type="checkbox"
                    id={`requiredQACheckbox${i}`}
                    name="qa_value"
                    checked={checkstate.data && checkstate.data[sub_cat] && checkstate.data[sub_cat][i] ? checkstate.data[sub_cat][i].qa_value === 1 : false}
                    value={checkstate.data && checkstate.data[sub_cat] ? checkstate.data[sub_cat].qa_value : ''}
                    onChange={(e) => inputhandlerchecklist(e, "qa_value", i,list.id,checkstate.task_id,list.phase_id,list.master_id,sub_cat)}
                  />
                  </td>
                 
                  {/* <td>{list.updated_at}</td> */}
                  <td>{list.remarks} </td>
                  <td>
                    {(list.phase_rejection == 1) ?
                      <span style={{ color : "#FF1717",cursor : 'pointer' }}>Rejected </span>
                    :
                  <Button
                                color="primary"
                                style={{
                                  border: "1px solid #FF6430",
                                  background:'none',
                                  color: "#FF1717",
                                  borderRadius: "18px",
                                }}
                                size="sm"
                                type="button"
                                onClick={(e) =>
                                  inputhandlerchecklist1(
                                    1,
                                    "phase_rejection",
                                    i,list.id,checkstate.task_id,list.phase_id,list.master_id,sub_cat
                                  )}
                              >
                                Reject
                              </Button>
} 
                  </td>
                  <td onClick={()=> handleeditRow(i,sub_cat)}>
                  {list.editing ? (
                    <Input type="number" placeholder="impact" name ="financial_impact"   defaultValue={list.financial_impact}
                    onBlur={(e) =>
                   inputhandlerchecklist(
                     e,
                     "financial_impact",
                     i,list.id,checkstate.task_id,list.phase_id,list.master_id,sub_cat
                   )} />
                  ) : <span>{list.financial_impact}</span> }
              </td>

              <td style={{
                // Spread operator to include custom styles
                width: '10px',       // Additional inline styles
                wordWrap: 'break-word',
                wordBreak: 'break-word'
              }} onClick={()=> handleeditRow(i,sub_cat,'phase_feedback',list.phase_feedback)}>
              <span  >{list.phase_feedback}</span> 
               </td>
               <td>
                    {(list.qa_rejection == 1) ?
                      <span style={{ color : "#FF1717",cursor : 'pointer' }}>Rejected </span>
                    : (user_info.role_id !=2) ? '' :
                  <Button
                                color="primary"
                                style={{
                                  border: "1px solid #FF6430",
                                  background:'none',
                                  color: "#FF1717",
                                  borderRadius: "18px",
                                }}
                                size="sm"
                                type="button"
                                onClick={(e) =>
                                  inputhandlerchecklist1(
                                    1,
                                    "qa_rejection",
                                    i,list.id,checkstate.task_id,list.phase_id,list.master_id,sub_cat
                                  )}
                              >
                                Reject
                              </Button>
} 
                  </td>
                <td onClick={()=> handleeditRow(i,sub_cat,'qa_feedback',list.qa_feedback)}>
                  
                {user_info.role_id == 2 ?  <span style={containerStyle}>{list.qa_feedback}</span> :null}
               </td>
               </> 
                : 
                <>
                <td>{list.updated_at}</td>
                  <td>
                 
                    <Input type="textarea" placeholder="Remarks" name ="remarks"   value={list.remarks ? list.remarks : ''}
                    onChange={(e) =>
                      inputhandlerchecklist(
                        e,
                        "remarks",
                        i,list.id,checkstate.task_id,list.phase_id,list.master_id,sub_cat
                      )} />
                 
                  </td>
                </>
                }
                </tr>
                  ))
                  ) : (
                   <tr>
                   <td>No Data Found</td>
                   </tr>
                 )}
                
            
                </tbody>
              </Table>
              </div>
              </div>
              
              <br></br>
               {/* {(datatype == 0) ? */}
               <Button color="primary" type="button" onClick={add_checklist_data} disabled={disablestate}>
            {disablestate ? 'Sending...' : 'Save'} 
              </Button> 
              {/* {(user_info.role_id == 3) ?
              <span style={{color:"red",fontSize:"12px"}}> Data is auto save so after click on send button then data will not be editable and then only job status can be changed to complete.</span>
              : null }  */}
              </Form>
             
              </>
          : <p>No data Found!</p> }
            </Card>
          </div>
        </Row>
        <Row>
        <Modal
            size="lg"
            className="taskdetailmodal"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            isOpen={sendmailpopupdetails}
      toggle={() => setsendmailPopupDetails(!sendmailpopupdetails)}
          >
            <div className="modal-header">
            <h3>Remark</h3>
              <button
                aria-label="Close"
                className=" close"
                type="button"
                onClick={() => {setsendmailPopupDetails(false),saveRemark}}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <ModalBody style={{paddingTop:'0px'}}>
             
              <Input type="textarea"  name ="qa_feedback"   defaultValue={remarkdata ? remarkdata : null}
                 onBlur={(e) =>
                   inputhandlerchecklist(
                     e,
                     remarkdataType,
                     remarkIndex,'','','','',sub_cat
                   )}
                   style={{ width: '60%',height:'200px' }} 
                   />
                   <br></br>
  <Button color="primary" type="button"  onClick={saveRemark}>Save</Button>
            </ModalBody>
            
           </Modal>
        </Row>

      </Container>
    </>
  );
};


export default ChecklistFormData;
