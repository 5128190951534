
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {apiUrl} from '../constant';
import {authHeader} from '../helpers/auth-header';
import {authtoken} from 'global.js';
const axios = require("axios");
export const usermanageService = {
    getusers,
    getuserswithoutClient,
    adduser,
    addclientuser,
    createphase,
    getsingleuser,
    edituser,
    getphases,
    getsinglephase,
    editphase,
    download_report,
    getUserListforassign,
    addmanager,
    getphasesselect,
    getphasesfulllist,
    getcategories,
    createcategory,
    archiveuser,
    getarchiveusers,
    restoreUser,
    getclient,
    getusersforchatbox,
    getconversation,
    addconversation,
    getmessages,
    addresponse,
    getTotalChatCount,
    getsingleclient,
    editclient,
    editclientmaster,
    addclientmaster,
    getclientdoclist,
    getclientpagination,
    getclienthistory,
    getdocusigndocumenttypelist,
    showKROMaster,
    getkrotypes,
    updateUserMasterKroForm,
    getkrousers,
    showKROCalculation,
    updateUserKroCalculationForm,
    updateCompetencyForm,
    calculateRating,
    KROTypeRatingList,
    addKROTypeRating,
    getsingleKROTypeRating,
    updateKROTypeRating,
    deleteKroTypeRating,
    getkrolist,
    approveKRO,
    editKRORow,
    updateUserEligibility,
    getnotEligibleusers,
    krogetstatus,
    download_kro_data,
    getmanager_list,
    getassign_client,
    addclientmanager,
    archiveratecard,
    KRODashboarddata,
    removeDocument,
    KRODashboarduserwisedata,
    KRODashboardsummary,
    getkrodetails,
    archivekrousermaster,
    getroles,
    editTypeRatingRow,
    getrolesforKROTypeRating,
    getKroData,
    adddocs,
    getcompetencytypes,
    addKROCompetency,
    competencyList,
    getreviewers,
    assignProcessors,
    getProcessorListforassign,
    getusersforkro,
    getqatls
};


function getusers(search,role_value,phase_value,page){
    return axios({
        method: "get",
        url: `${apiUrl}/users?search=`+search+`&role_value=`+role_value+`&phase_value=`+phase_value+`&page=`+page,
        headers: {Authorization: `Bearer ${authtoken}` },
      })
        .then(function (response) {
           if(response.status == 200){
                return response;  
           }
        }, error => {
          return error.response;
          console.log(error.response.status);
        })
        .catch(function (error) {
          // console.log(error.response.status);
        });      
}

function getclienthistory(user_id){
  return axios({
      method: "get",
      url: `${apiUrl}/clienthistory?userid=`+user_id,
      headers: {Authorization: `Bearer ${authtoken}` },
    })
      .then(function (response) {
         if(response.status == 200){
              return response;  
         }
      }, error => {
        return error.response;
        console.log(error.response.status);
      })
      .catch(function (error) {
        // console.log(error.response.status);
      });      
}

function getphasesselect(){
  return axios({
      method: "get",
      url: `${apiUrl}/getphasesselect`,
      headers: {Authorization: `Bearer ${authtoken}` },
    })
      .then(function (response) {
         if(response.status == 200){
              return response;  
         }
      }, error => {
        return error.response;
        console.log(error.response.status);
      })
      .catch(function (error) {
        // console.log(error.response.status);
      });      
}

function getuserswithoutClient(){
  return axios({
      method: "get",
      url: `${apiUrl}/internal-team`,
      headers: {Authorization: `Bearer ${authtoken}` },
    })
      .then(function (response) {
         if(response.status == 200){
              return response;  
         }
      }, error => {
        return error.response;
        console.log(error.response.status);
      })
      .catch(function (error) {
        // console.log(error.response.status);
      });      
}

function getsingleuser(user_id){
  return axios({
    method: "get",
    url: `${apiUrl}/users/getuser?userid=`+user_id,
    headers: {Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
      //  if(response.status == 200){
            return response;  
      //  }
    }, error => {
      return error.response;
      // console.log(error.response.status);
    })
    .catch(function (error) {
      // console.log(error.response.status);
    }); 
}
function getsingleclient(user_id){
  return axios({
    method: "get",
    url: `${apiUrl}/getclient?userid=`+user_id,
    headers: {Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
      //  if(response.status == 200){
            return response;  
      //  }
    }, error => {
      return error.response;
      // console.log(error.response.status);
    })
    .catch(function (error) {
      // console.log(error.response.status);
    }); 
}
function addclientuser(userdata){
  console.log(userdata)
 var bodyFormData = new FormData();
 bodyFormData.append("name", userdata.name);
 bodyFormData.append("email",userdata.email);
 bodyFormData.append("password",userdata.password);
 let user_id = userdata.user_id ? userdata.user_id : '';
 bodyFormData.append("user_id",user_id);
 
 return axios({
   method: "post",
   url: `${apiUrl}/addclientuser`,
   data:bodyFormData,
   headers: { "Content-Type": "application/json",Authorization: `Bearer ${authtoken}` },
 })
   .then(function (response) {
     //  if(response.status == 200){
           return response;  
     //  }
   }, error => {
     return error.response;
     // console.log(error.response.status);
   })
   .catch(function (error) {
      return error;
     // console.log(error.response.status);
   }); 
}
function addclientmaster(userdata){
  console.log(userdata)
  var bodyFormData = new FormData();
  bodyFormData.append("name", userdata.name);
  bodyFormData.append("email",userdata.email);
  bodyFormData.append("other_email",userdata.other_email);
  bodyFormData.append("home_number",userdata.home_number);
  bodyFormData.append("company_address",userdata.company_address);
  bodyFormData.append("phone_no",userdata.phone_no);
  bodyFormData.append("services",userdata.services);
  bodyFormData.append("client_type",userdata.client_type);
  bodyFormData.append("total_emp_size",userdata.total_emp_size);
  bodyFormData.append("total_offices",userdata.total_offices);
  bodyFormData.append("agreement_date",userdata.agreement_date);
  bodyFormData.append("billing_spoc",userdata.billing_spoc);
  bodyFormData.append("increment_date",userdata.increment_date);
  bodyFormData.append("increment_rate",userdata.increment_rate);
  bodyFormData.append("notice_period",userdata.notice_period);
  bodyFormData.append("last_day_service",userdata.last_day_service);
  // bodyFormData.append("governance_freq",userdata.governance_freq);
  // bodyFormData.append("client_referred",userdata.client_referred);
  // Add KYC documents
  bodyFormData.append("passport_expiry_date", userdata.passport_expiry_date);
  bodyFormData.append("driving_expiry_date", userdata.driving_expiry_date);
  bodyFormData.append("utility_expiry_date", userdata.utility_expiry_date);
  bodyFormData.append("marketting_channel", userdata.marketting_channel);
  bodyFormData.append("entity", userdata.entity);
  bodyFormData.append("director_name", userdata.director_name);
  bodyFormData.append("status", userdata.status);
  bodyFormData.append("compliance", userdata.compliance);
  bodyFormData.append("relateddepartment", userdata.department);
  
  // Add KYC document files
  if(userdata.passport_doc!=null){
    bodyFormData.append("passport_doc", userdata.passport_doc);
  }
  if(userdata.driving_doc!=null){
    bodyFormData.append("driving_doc", userdata.driving_doc);
  }
  if(userdata.utility_doc!=null){
    bodyFormData.append("utility_doc", userdata.utility_doc);
  }
  // if(userdata.files.length > 0){
  //   bodyFormData.append("files", userdata.files);
  // }
   // Add the users array as JSON data
   bodyFormData.append("users", JSON.stringify(userdata.users));
   bodyFormData.append("rates", JSON.stringify(userdata.rates));
   bodyFormData.append("refer", JSON.stringify(userdata.refer));
   if(userdata.otherdocs){
    userdata.otherdocs.forEach((item, index) => {
      if(item.id){
        bodyFormData.append(`id[${index}]`, item.id);
      }
      bodyFormData.append(`files[${index}]`, item.files);
      bodyFormData.append(`remarks[${index}]`, item.remarks);
    });
  }
  return axios({
    method: "post",
    url: `${apiUrl}/addclientmaster`,
    data:bodyFormData,
    headers: { "Content-Type": "application/json",Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
      //  if(response.status == 200){
            return response;  
      //  }
    }, error => {
      return error.response;
      // console.log(error.response.status);
    })
    .catch(function (error) {
       return error;
      // console.log(error.response.status);
    }); 
 }

function adduser(userdata,sendemail = false){
  var userobj = JSON.stringify(userdata);
   console.log("=="+userdata.name+"=="+userdata.team_user+"=="+userdata.department);

  var bodyFormData = new FormData();
  // bodyFormData.append("user_data", userobj);
  bodyFormData.append("name", userdata.name);
  bodyFormData.append("email",userdata.email);
  bodyFormData.append("password",userdata.password);
  bodyFormData.append("role_id",userdata.roles);
  bodyFormData.append("phase_id",userdata.phases);
  bodyFormData.append("qtl_leads",userdata.qatl);
  bodyFormData.append("atl_leads",userdata.atl);
  bodyFormData.append("client_qa",userdata.client_qa ? userdata.client_qa : null);
  bodyFormData.append("client_qatm",userdata.client_qatm ? userdata.client_qatm : null);
  bodyFormData.append("manager",userdata.team_user);
  bodyFormData.append("sendemail",sendemail);
  bodyFormData.append("department",userdata.department);
  bodyFormData.append("joining_date",userdata.joining_date);
  bodyFormData.append("exit_date",userdata.exit_date);
  bodyFormData.append("client_manager",userdata.client_manager ? userdata.client_manager : null);
  
  
  return axios({
    method: "post",
    url: `${apiUrl}/users/create`,
    data:bodyFormData,
    headers: { "Content-Type": "application/json",Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
      //  if(response.status == 200){
            return response;  
      //  }
    }, error => {
      return error.response;
      // console.log(error.response.status);
    })
    .catch(function (error) {
       return error;
      // console.log(error.response.status);
    });  
}

function createphase(phasedata){
  var phaseobj = phasedata;
  var bodyFormData = new FormData();
  bodyFormData.append("phase_name", phaseobj.phase_name);
  bodyFormData.append("desc", phaseobj.desc);
  return axios({
      method: "post",
      url: `${apiUrl}/users/createphase`,
      data:bodyFormData,
      headers: { "Content-Type": "multipart/form-data",Authorization: `Bearer ${authtoken}` },
    })
      .then(function (response) {
         if(response.status == 200){
            return response;  
         }
      }, error => {
          return error.response;
        // console.log(error.response.status);
      })
      .catch(function (error) {
        // console.log(error.response.status);
      });      
}

function editphase(phasedata){
  var phaseobj = phasedata;
  var bodyFormData = new FormData();
  bodyFormData.append("phase_id", phaseobj.phase_id);
  bodyFormData.append("phase_name", phaseobj.phase_name);
  bodyFormData.append("desc", phaseobj.desc);

  return axios({
      method: "post",
      url: `${apiUrl}/users/editphase`,
      data:bodyFormData,
      headers: { "Content-Type": "multipart/form-data",Authorization: `Bearer ${authtoken}` },
    })
      .then(function (response) {
         if(response.status == 200){
            return response;  
         }
      }, error => {
          return error.response;
        // console.log(error.response.status);
      })
      .catch(function (error) {
        // console.log(error.response.status);
      });      
}

function getphases(){
  return axios({
    method: "post",
    url: `${apiUrl}/users/getphases`,
    headers: {Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
      //  if(response.status == 200){
       return response;  
      //  }
    }, error => {
      return error.response;
      // console.log(error.response.status);
    })
    .catch(function (error) {
      // console.log(error.response.status);
    });   
}

function getphasesfulllist(){
  return axios({
    method: "post",
    url: `${apiUrl}/users/getphaseslist`,
    headers: {Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
      //  if(response.status == 200){
       return response;  
      //  }
    }, error => {
      return error.response;
      // console.log(error.response.status);
    })
    .catch(function (error) {
      // console.log(error.response.status);
    });   
}

function getsinglephase(phase_id){
  return axios({
    method: "get",
    url: `${apiUrl}/users/getsinglephase/`+phase_id,
    headers: {Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
      //  if(response.status == 200){
       return response;  
      //  }
    }, error => {
      return error.response;
      // console.log(error.response.status);
    })
    .catch(function (error) {
      // console.log(error.response.status);
    }); 
}


function userroles(){
  return axios({
    method: "get",
    url: `${apiUrl}/getusers`,
    headers: { "Content-Type": "multipart/form-data",Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
       if(response.status == 200){
            return response;  
       }
    }, error => {
      return error.response;
      // console.log(error.response.status);
    })
    .catch(function (error) {
      // console.log(error.response.status);
    }); 
}

function edituser(userdata,sendemail = false){
  var bodyFormData = new FormData();
  bodyFormData.append("user_id", userdata.user_id);
  bodyFormData.append("name", userdata.name);
  bodyFormData.append("email",userdata.email);
  bodyFormData.append("role_id",userdata.role);
  bodyFormData.append("department",userdata.department);
  bodyFormData.append("phase_id",userdata.phases);
  bodyFormData.append("select_phase_id",userdata.select_phase_id);
  bodyFormData.append("select_role_id",userdata.select_role_id);
  bodyFormData.append("selected_qtl",userdata.qatl);
  bodyFormData.append("selected_atl",userdata.atl);
  bodyFormData.append("client_qa",userdata.client_qa ? userdata.client_qa : null);
  bodyFormData.append("client_qatm",userdata.client_qatm ? userdata.client_qatm : null);
  bodyFormData.append("manager",userdata.team_user);
  bodyFormData.append("sendemail",sendemail);
  bodyFormData.append("joining_date",userdata.joining_date);
  bodyFormData.append("exit_date",userdata.exit_date);
  bodyFormData.append("client_manager",userdata.client_manager ? userdata.client_manager : null);


  return axios({
    method: "post",
    url: `${apiUrl}/users/edit`,
    data:bodyFormData,
    headers: { "Content-Type": "multipart/form-data",Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
       if(response.status == 200){
            return response;  
       }
    }, error => {
      return error.response;
      console.log(error.response.status);
    })
    .catch(function (error) {
      // console.log(error.response.status);
    });  
}

function editclient(userdata){
  var bodyFormData = new FormData();
  bodyFormData.append("user_id", userdata.user_id);
  bodyFormData.append("name", userdata.name);
  bodyFormData.append("email",userdata.email);

  return axios({
    method: "post",
    url: `${apiUrl}/editclient`,
    data:bodyFormData,
    headers: { "Content-Type": "multipart/form-data",Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
       if(response.status == 200){
            return response;  
       }
    }, error => {
      return error.response;
      console.log(error.response.status);
    })
    .catch(function (error) {
      // console.log(error.response.status);
    });  
}

function editclientmaster(userdata){
  var bodyFormData = new FormData();
  bodyFormData.append("user_id", userdata.user_id);
  bodyFormData.append("name", userdata.name);
  bodyFormData.append("email",userdata.email);
  bodyFormData.append("other_email",userdata.other_email);
  bodyFormData.append("password",userdata.password);
  bodyFormData.append("home_number",userdata.home_number);
  bodyFormData.append("company_address",userdata.company_address);
  bodyFormData.append("phone_no",userdata.phone_no);
  bodyFormData.append("services",userdata.services);
  bodyFormData.append("client_type",userdata.client_type);
  bodyFormData.append("total_emp_size",userdata.total_emp_size);
  bodyFormData.append("total_offices",userdata.total_offices);
  bodyFormData.append("agreement_date",userdata.agreement_date);
  bodyFormData.append("billing_spoc",userdata.billing_spoc);
  bodyFormData.append("increment_date",userdata.increment_date);
  bodyFormData.append("increment_rate",userdata.increment_rate);
  bodyFormData.append("notice_period",userdata.notice_period);
  bodyFormData.append("last_day_service",userdata.last_day_service);
  // bodyFormData.append("governance_freq",userdata.governance_freq);
  // bodyFormData.append("client_referred",userdata.client_referred);
  // Add KYC documents
  bodyFormData.append("passport_expiry_date", userdata.passport_expiry_date);
  bodyFormData.append("driving_expiry_date", userdata.driving_expiry_date);
  bodyFormData.append("utility_expiry_date", userdata.utility_expiry_date);
  bodyFormData.append("entity", userdata.entity);
  bodyFormData.append("director_name", userdata.director_name);
  bodyFormData.append("status", userdata.status);
  bodyFormData.append("compliance", userdata.compliance);
  bodyFormData.append("relateddepartment", userdata.department);
  
  // Add KYC document files
  if(userdata.passport_doc!=null){
    bodyFormData.append("passport_doc", userdata.passport_doc);
  }
  if(userdata.driving_doc!=null){
    bodyFormData.append("driving_doc", userdata.driving_doc);
  }
  if(userdata.utility_doc!=null){
    bodyFormData.append("utility_doc", userdata.utility_doc);
  }
  // if(userdata.files.length > 0){
  //   bodyFormData.append("files", userdata.files);
  // }
   bodyFormData.append("rates", JSON.stringify(userdata.rates));
   bodyFormData.append("refer", JSON.stringify(userdata.refer));
   userdata.otherdocs.forEach((item, index) => {
    bodyFormData.append(`files[${index}]`, item.files);
    bodyFormData.append(`remarks[${index}]`, item.remarks);
  });
  bodyFormData.append("emailData", JSON.stringify(userdata.emailboxData));
  bodyFormData.append("marketting_channel", userdata.marketting_channel);

  return axios({
    method: "post",
    url: `${apiUrl}/editclientmaster`,
    data:bodyFormData,
    headers: { "Content-Type": "multipart/form-data",Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
       if(response.status == 200){
            return response;  
       }
    }, error => {
      return error.response;
      console.log(error.response.status);
    })
    .catch(function (error) {
      // console.log(error.response.status);
    });  
}

function download_report(){
  return axios({
    method: "get",
    responseType: 'blob', 
    url: `${apiUrl}/users-export`,
    headers: { Authorization: `Bearer ${authtoken}`,"Content-Type": "application/vnd.ms-excel" },
  })
  .then(function (response) {
    const outputFilename = `users-report-${Date.now()}.xlsx`;
    const url = URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', outputFilename);
    document.body.appendChild(link);
    link.click();
// return response;
    }, error => {
      return error.response;
    })
    .catch(function (error) {
      console.log(error.response.status);
    });  
}

function getUserListforassign(id){
  return axios({
      method: "get",
      url: `${apiUrl}/getUserListforassign/`+id,
      headers: {Authorization: `Bearer ${authtoken}` },
    })
    .then(function (response) {
      if(response.status == 200){
        return response;
       }
      }, error => {
        return error.response;
      })
      .catch(function (error) {
        console.logaddmanager(error.response.status);
      });      
}
function addmanager(userdata){
  console.log(userdata);
  var userobj = JSON.stringify(userdata);
   console.log("=="+userdata.roles+"=="+userdata.qatl);

  var bodyFormData = new FormData();
  bodyFormData.append("roles", userdata.roles);
  bodyFormData.append("user_data",userdata.qatl);
  
  return axios({
    method: "post",
    url: `${apiUrl}/addmanager`,
    data:bodyFormData,
    headers: { "Content-Type": "application/json",Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
      //  if(response.status == 200){
            return response;  
      //  }
    }, error => {
      return error.response;
      // console.log(error.response.status);
    })
    .catch(function (error) {
       return error;
      // console.log(error.response.status);
    });  
}

function getcategories(){
  return axios({
    method: "get",
    url: `${apiUrl}/getcategories`,
    headers: {Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
      //  if(response.status == 200){
       return response;  
      //  }
    }, error => {
      return error.response;
      // console.log(error.response.status);
    })
    .catch(function (error) {
      // console.log(error.response.status);
    });
}

function createcategory(data){
  var bodyFormData = new FormData();
  bodyFormData.append("id", data.id);
  bodyFormData.append("name", data.name);
  return axios({
      method: "post",
      url: `${apiUrl}/createcategory`,
      data:bodyFormData,
      headers: { "Content-Type": "multipart/form-data",Authorization: `Bearer ${authtoken}` },
    })
      .then(function (response) {
         if(response.status == 200){
            return response;  
         }
      }, error => {
          return error.response;
        // console.log(error.response.status);
      })
      .catch(function (error) {
        // console.log(error.response.status);
      });      
}


function archiveuser(id){
  return axios({
    method: "delete",
    url: `${apiUrl}/users/${id}`,
    headers: { Authorization: `Bearer ${authtoken}` },
  })
  .then(function (response) {
      return response;
    }, error => {
      return error.response;
    })
    .catch(function (error) {
      console.log(error.response.status);
    });  
}

function getarchiveusers(page){

  return axios({
      method: "get",
      url: `${apiUrl}/archive-user-list?page=${page}`,
      headers: {Authorization: `Bearer ${authtoken}` },
    })
      .then(function (response) {
         if(response.status == 200){
              return response;  
         }
      }, error => {
        return error.response;
        console.log(error.response.status);
      })
      .catch(function (error) {
        // console.log(error.response.status);
      });      
}

function getkrolist(page,data,sortfield,sortorder){
  console.log(data)
  return axios({
    method: "get",
    url: `${apiUrl}/krolisting?page=${page}&user_id=${data.user_id}&month_year=${data.month_year}&reviewer_id=${data.reviewer_id}&status=${data.status}&sort=${sortfield}&order=${sortorder}&approval=${data.approval}&manager_approval=${data.manager_approval}`,
    headers: {Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
       if(response.status == 200){
            return response;  
       }
    }, error => {
      return error.response;
      console.log(error.response.status);
    })
    .catch(function (error) {
      // console.log(error.response.status);
    }); 
}

function restoreUser(id){
  return axios({
    method: "get",
    url: `${apiUrl}/restore-user?id=${id}`,
    headers: { Authorization: `Bearer ${authtoken}` },
  })
  .then(function (response) {
      return response;
    }, error => {
      return error.response;
    })
    .catch(function (error) {
      console.log(error.response.status);
    });  
}
function getusersforchatbox(name){
  return axios({
    method: "get",
    url: `${apiUrl}/getusersformanager?name=${name}`,
    headers: {Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
       if(response.status == 200){
            return response;  
       }
    }, error => {
      return error.response;
      // console.log(error.response.status);
    })
    .catch(function (error) {
      // console.log(error.response.status);
    }); 
}
function getusersforkro(name){
  return axios({
    method: "get",
    url: `${apiUrl}/getusersforkro?name=${name}`,
    headers: {Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
       if(response.status == 200){
            return response;  
       }
    }, error => {
      return error.response;
      // console.log(error.response.status);
    })
    .catch(function (error) {
      // console.log(error.response.status);
    }); 
}
function getclient(){
  return axios({
    method: "get",
    url: `${apiUrl}/getclients`,
    headers: {Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
       if(response.status == 200){
            return response;  
       }
    }, error => {
      return error.response;
      // console.log(error.response.status);
    })
    .catch(function (error) {
      // console.log(error.response.status);
    }); 
}
function getclientpagination(page,client){
  console.log(client)
  return axios({
    method: "get",
    url: `${apiUrl}/getclientsPagination?page=${page}&client=${encodeURIComponent(client)}`,
    headers: {Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
       if(response.status == 200){
            return response;  
       }
    }, error => {
      return error.response;
      // console.log(error.response.status);
    })
    .catch(function (error) {
      // console.log(error.response.status);
    }); 
}

function getconversation(){
  return axios({
    method: "get",
    url: `${apiUrl}/conversations`,
    headers: {Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
       if(response.status == 200){
            return response;  
       }
    }, error => {
      return error.response;
      // console.log(error.response.status);
    })
    .catch(function (error) {
      // console.log(error.response.status);
    }); 
}
function addconversation(data){
  var dataobj = data;
  var bodyFormData = new FormData();
  bodyFormData.append("type", dataobj.type);
  if(dataobj.type == 'single'){
    bodyFormData.append("user_id", dataobj.user_id);
  }
  else if(dataobj.type == 'group'){
    bodyFormData.append("group_name", dataobj.name);
    bodyFormData.append("participants", dataobj.participants);
  }
  return axios({
      method: "post",
      url: `${apiUrl}/conversations`,
      data:bodyFormData,
      headers: { "Content-Type": "multipart/form-data",Authorization: `Bearer ${authtoken}` },
    })
      .then(function (response) {
         if(response.status == 200){
            return response;  
         }
      }, error => {
          return error.response;
        // console.log(error.response.status);
      })
      .catch(function (error) {
        // console.log(error.response.status);
      });      
}
function addresponse(data,id){
  var dataobj = data;
  var bodyFormData = new FormData();
  bodyFormData.append("content", dataobj.content);
  bodyFormData.append("id", id);
  return axios({
      method: "post",
      url: `${apiUrl}/conversations/add-comment`,
      data:bodyFormData,
      headers: { "Content-Type": "multipart/form-data",Authorization: `Bearer ${authtoken}` },
    })
      .then(function (response) {
         if(response.status == 200){
            return response;  
         }
      }, error => {
          return error.response;
        // console.log(error.response.status);
      })
      .catch(function (error) {
        // console.log(error.response.status);
      });      
}
function getmessages(id){
  return axios({
    method: "get",
    url: `${apiUrl}/conversations/${id}/messages`,
    headers: {Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
       if(response.status == 200){
            return response;  
       }
    }, error => {
      return error.response;
      // console.log(error.response.status);
    })
    .catch(function (error) {
      // console.log(error.response.status);
    }); 
}

function getTotalChatCount(){
  return axios({
    method: "get",
    url: `${apiUrl}/getchattotal`,
    headers: {Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
       if(response.status == 200){
            return response;  
       }
    }, error => {
      return error.response;
      // console.log(error.response.status);
    })
    .catch(function (error) {
      // console.log(error.response.status);
    }); 
}

function getclientdoclist(page,filter){
  let filters = '';
  if(filter){
     filters = `&filters=${JSON.stringify(filter)}`;
  }
  return axios({
    method: "get",
    url: `${apiUrl}/getClientDocumentList?page=${page}&search=${filters}`,
    headers: {Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
      //  if(response.status == 200){
            return response;  
      //  }
    }, error => {
      return error.response;
      // console.log(error.response.status);
    })
    .catch(function (error) {
      // console.log(error.response.status);
    }); 
}
function getdocusigndocumenttypelist(){
  return axios({
    method: "get",
    url: `${apiUrl}/docusign-doc-filter-list`,
    headers: { Authorization: `Bearer ${authtoken}` },
  })
  .then(function (response) {
      return response;
    }, error => {
      return error.response;
    })
    .catch(function (error) {
      console.log(error.response.status);
    });
}

function showKROMaster(user){
  return axios({
    method: "get",
    url: `${apiUrl}/showKROMaster?user_id=${user}`,
    headers: { Authorization: `Bearer ${authtoken}` },
  })
  .then(function (response) {
      return response;
    }, error => {
      return error.response;
    })
    .catch(function (error) {
      console.log(error.response.status);
    });
}

function showKROCalculation(data,sync){
  return axios({
    method: "get",
    url: `${apiUrl}/showKROCalculation?user_id=${data.user_id}&month_year=${data.month_year}&sync=${sync}`,
    headers: { Authorization: `Bearer ${authtoken}` },
  })
  .then(function (response) {
      return response;
    }, error => {
      return error.response;
    })
    .catch(function (error) {
      console.log(error.response.status);
    });
}

function getkrotypes(user){
  return axios({
    method: "get",
    url: `${apiUrl}/getkrotypes`,
    headers: { Authorization: `Bearer ${authtoken}` },
  })
  .then(function (response) {
      return response;
    }, error => {
      return error.response;
    })
    .catch(function (error) {
      console.log(error.response.status);
    });
}

function getcompetencytypes(user){
  return axios({
    method: "get",
    url: `${apiUrl}/getcompetencyroles`,
    headers: { Authorization: `Bearer ${authtoken}` },
  })
  .then(function (response) {
      return response;
    }, error => {
      return error.response;
    })
    .catch(function (error) {
      console.log(error.response.status);
    });
}

function updateUserMasterKroForm(data,month){
  var bodyFormData = new FormData();
    bodyFormData.append("data", JSON.stringify(data));
    bodyFormData.append("month", month);
    return axios({
      method: "post",
      url: `${apiUrl}/updateMasterKroForm`,
      data: bodyFormData,
      headers: { Authorization: `Bearer ${authtoken}` },
    })
      .then(function (response) {
           return response;  
      }, error => {
        return error.response;
      //   console.log(error.response.status);
      })
      .catch(function (error) {
        // console.log(error.response.status);
      });
}

function updateUserEligibility(eligible,user_id,month){
  var bodyFormData = new FormData();
  bodyFormData.append("kro_eligibility", eligible);
  bodyFormData.append("user_id", user_id);
  bodyFormData.append("month", month);
  return axios({
    method: "post",
    url: `${apiUrl}/updateUserEligibility`,
    data: bodyFormData,
    headers: { Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
         return response;  
    }, error => {
      return error.response;
    //   console.log(error.response.status);
    })
    .catch(function (error) {
      // console.log(error.response.status);
    });
}

function updateUserKroCalculationForm(data,month){
  var bodyFormData = new FormData();
    bodyFormData.append("data", JSON.stringify(data));
    bodyFormData.append("month_year", month);
    return axios({
      method: "post",
      url: `${apiUrl}/updateKroCalculationForm`,
      data: bodyFormData,
      headers: { Authorization: `Bearer ${authtoken}` },
    })
      .then(function (response) {
           return response;  
      }, error => {
        return error.response;
      //   console.log(error.response.status);
      })
      .catch(function (error) {
        // console.log(error.response.status);
      });
}

function updateCompetencyForm(data,month){
  var bodyFormData = new FormData();
  bodyFormData.append("data", JSON.stringify(data));
  bodyFormData.append("month_year", month);
  return axios({
    method: "post",
    url: `${apiUrl}/updateCompetencyData`,
    data: bodyFormData,
    headers: { Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
         return response;  
    }, error => {
      return error.response;
    //   console.log(error.response.status);
    })
    .catch(function (error) {
      // console.log(error.response.status);
    });
}

function getkrousers(){
  return axios({
      method: "get",
      url: `${apiUrl}/getkrousers`,
      headers: {Authorization: `Bearer ${authtoken}` },
    })
      .then(function (response) {
         if(response.status == 200){
              return response;  
         }
      }, error => {
        return error.response;
        console.log(error.response.status);
      })
      .catch(function (error) {
        // console.log(error.response.status);
      });      
}
function calculateRating(data,type_id,user_id){
  return axios({
    method: "get",
    url: `${apiUrl}/calculateRating?type_id=${type_id}&actual_target=${data}&user_id=${user_id}`,
    headers: { Authorization: `Bearer ${authtoken}` },
  })
  .then(function (response) {
      return response;
    }, error => {
      return error.response;
    })
    .catch(function (error) {
      console.log(error.response.status);
    });
}
function KROTypeRatingList(page,type,active_role){
  return axios({
      method: "get",
      url: `${apiUrl}/KROTypeRatingList?page=${page}&type=${type}&active_role=${active_role}`,
      headers: {Authorization: `Bearer ${authtoken}` },
    })
      .then(function (response) {
         if(response.status == 200){
              return response;  
         }
      }, error => {
        return error.response;
        console.log(error.response.status);
      })
      .catch(function (error) {
        // console.log(error.response.status);
      });      
}
function addKROTypeRating(inputs,data){
  var bodyFormData = new FormData();
    bodyFormData.append("data", JSON.stringify(data));
    bodyFormData.append("type_id", inputs.type_id);
    bodyFormData.append("roles", inputs.roles);
    return axios({
      method: "post",
      url: `${apiUrl}/addKROTypeRating`,
      data: bodyFormData,
      headers: { Authorization: `Bearer ${authtoken}` },
    })
      .then(function (response) {
           return response;  
      }, error => {
        return error.response;
      //   console.log(error.response.status);
      })
      .catch(function (error) {
        // console.log(error.response.status);
      });
}
function getsingleKROTypeRating(id){
  return axios({
    method: "get",
    url: `${apiUrl}/getsingleKROTypeRating?id=`+id,
    headers: {Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
       if(response.status == 200){
            return response;  
       }
    }, error => {
      return error.response;
      console.log(error.response.status);
    })
    .catch(function (error) {
      // console.log(error.response.status);
    });
}
function updateKROTypeRating(data){
  return axios({
    method: "post",
    url: `${apiUrl}/updateKROTypeRating`,
    data: data,
    headers: { Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
         return response;  
    }, error => {
      return error.response;
    //   console.log(error.response.status);
    })
    .catch(function (error) {
      // console.log(error.response.status);
    });
}
function deleteKroTypeRating(id){
  return axios({
    method: "get",
    url: `${apiUrl}/deleteKroTypeRating/${id}`,
    headers: { Authorization: `Bearer ${authtoken}` },
    })
    .then(function (response) {
      console.log(response);
      // localStorage.setItem("task_count", JSON.stringify(response.data.data));
      return response;
    })
    .catch(function (error) {
      return error;
    }); 
}

function approveKRO(data){
  return axios({
    method: "post",
    url: `${apiUrl}/approveKRO`,
    data: data,
    headers: { Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
         return response;  
    }, error => {
      return error.response;
    //   console.log(error.response.status);
    })
    .catch(function (error) {
      // console.log(error.response.status);
    });
}

function editKRORow(value,field,id,user_id){
  var bodyFormData = new FormData();
  bodyFormData.append("month", id);
  bodyFormData.append("field", field);
  bodyFormData.append("value", value);
  bodyFormData.append("user_id", user_id);
  return axios({
      method: "POST",
      url: `${apiUrl}/kro-edit`,
      data: bodyFormData,
      headers: {Authorization: `Bearer ${authtoken}` },
    })
      .then(function (response) {
        return response;
      }, error => {
       // return error.response;
        console.log(error.response.status);
      })
      .catch(function (error) {
        // console.log(error.response.status);
      });      
}
function getnotEligibleusers(){
  return axios({
      method: "get",
      url: `${apiUrl}/getnotEligibleusers`,
      headers: {Authorization: `Bearer ${authtoken}` },
    })
      .then(function (response) {
         if(response.status == 200){
              return response;  
         }
      }, error => {
        return error.response;
        console.log(error.response.status);
      })
      .catch(function (error) {
        // console.log(error.response.status);
      });      
}
function krogetstatus(){
  return axios({
    method: "get",
    url: `${apiUrl}/krostatuslist`,
    headers: {Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
       if(response.status == 200){
            return response;  
       }
    }, error => {
      return error.response;
      console.log(error.response.status);
    })
    .catch(function (error) {
      // console.log(error.response.status);
    }); 
}
function download_kro_data(data){
  return axios({
    method: "get",
    responseType: 'blob', 
    url: `${apiUrl}/download_kro_data?user_id=${data.user_id}&month_year=${data.month_year}&reviewer_id=${data.reviewer_id}&status=${data.status}`,
    headers: { Authorization: `Bearer ${authtoken}`,"Content-Type": "application/vnd.ms-excel" },
  })
  .then(function (response) {
    const outputFilename = `kro-data-report-${Date.now()}.xlsx`;
    const url = URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', outputFilename);
    document.body.appendChild(link);
    link.click();
// return response;
    }, error => {
      return error.response;
    })
    .catch(function (error) {
      console.log(error.response.status);
    });
}
function getmanager_list(){
  return axios({
    method: "get",
    url: `${apiUrl}/get_manager`,
    headers: {Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
       if(response.status == 200){
            return response;  
       }
    }, error => {
      return error.response;
      console.log(error.response.status);
    })
    .catch(function (error) {
      // console.log(error.response.status);
    }); 
}
function getassign_client(id){
  return axios({
    method: "get",
    url: `${apiUrl}/getassign_client?id=${id}`,
    headers: {Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
       if(response.status == 200){
            return response;  
       }
    }, error => {
      return error.response;
      console.log(error.response.status);
    })
    .catch(function (error) {
      // console.log(error.response.status);
    }); 
}
function addclientmanager(userdata){
  var bodyFormData = new FormData();
  bodyFormData.append("roles", userdata.roles);
  bodyFormData.append("user_data",userdata.clients);
  
  return axios({
    method: "post",
    url: `${apiUrl}/addclientmanager`,
    data:bodyFormData,
    headers: { "Content-Type": "application/json",Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
      //  if(response.status == 200){
            return response;  
      //  }
    }, error => {
      return error.response;
      // console.log(error.response.status);
    })
    .catch(function (error) {
       return error;
      // console.log(error.response.status);
    });  
}
function archiveratecard(id){
  return axios({
    method: "delete",
    url: `${apiUrl}/archiveratecard/${id}`,
    headers: { Authorization: `Bearer ${authtoken}` },
  })
  .then(function (response) {
      return response;
    }, error => {
      return error.response;
    })
    .catch(function (error) {
      console.log(error.response.status);
    });  
}

function KRODashboarddata(id,month_year,downloadexcel=0){
  if(downloadexcel==0){

  return axios({
    method: "get",
    url: `${apiUrl}/kroDashboard?user_id=${id}&month_year=${month_year}&downloadexcel=${downloadexcel}`,
    headers: { Authorization: `Bearer ${authtoken}` },
  })
  .then(function (response) {
      return response;
    }, error => {
      return error.response;
    })
    .catch(function (error) {
      console.log(error.response.status);
    });  
  }else{
    return axios({
      method: "get",
      responseType: 'blob', 
      url: `${apiUrl}/kroDashboard?user_id=${id}&downloadexcel=${downloadexcel}`,
      headers: { Authorization: `Bearer ${authtoken}`,"Content-Type": "application/vnd.ms-excel" },
    })
    .then(function (response) {
      const outputFilename = `KRO-Dashboard-report-${Date.now()}.xlsx`;
      const url = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', outputFilename);
      document.body.appendChild(link);
      link.click();
  // return response;
      }, error => {
        return error.response;
      })
      .catch(function (error) {
        console.log(error.response.status);
      });  
  }
}

function KRODashboarduserwisedata(id,month_year,downloadexcel=0){
  if(downloadexcel==0){
  return axios({
    method: "get",
    url: `${apiUrl}/kroDashboardUserWise?user_id=${id}&month_year=${month_year}&downloadexcel=${downloadexcel}`,
    headers: { Authorization: `Bearer ${authtoken}` },
  })
  .then(function (response) {
      return response;
    }, error => {
      return error.response;
    })
    .catch(function (error) {
      console.log(error.response.status);
    }); 
  }else{
    return axios({
      method: "get",
      responseType: 'blob', 
      url: `${apiUrl}/kroDashboardUserWise?user_id=${id}&month_year=${month_year}&downloadexcel=${downloadexcel}`,
      headers: { Authorization: `Bearer ${authtoken}`,"Content-Type": "application/vnd.ms-excel" },
    })
    .then(function (response) {
      const outputFilename = `KRO-Dashboard-Userwise-report-${Date.now()}.xlsx`;
      const url = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', outputFilename);
      document.body.appendChild(link);
      link.click();
  // return response;
      }, error => {
        return error.response;
      })
      .catch(function (error) {
        console.log(error.response.status);
      });  
  } 
}

function removeDocument(id){
  return axios({
    method: "get",
    url: `${apiUrl}/removeDocument?id=${id}`,
    headers: { Authorization: `Bearer ${authtoken}` },
  })
  .then(function (response) {
      return response;
    }, error => {
      return error.response;
    })
    .catch(function (error) {
      console.log(error.response.status);
    });  
}


function KRODashboardsummary(id,month_year,downloadexcel=0){
  if(downloadexcel==0){
  return axios({
    method: "get",
    url: `${apiUrl}/KRODashboardsummary?user_id=${id}&month_year=${month_year}&downloadexcel=${downloadexcel}`,
    headers: { Authorization: `Bearer ${authtoken}` },
  })
  .then(function (response) {
      return response;
    }, error => {
      return error.response;
    })
    .catch(function (error) {
      console.log(error.response.status);
    });
  }else{
    return axios({
      method: "get",
      responseType: 'blob', 
      url: `${apiUrl}/KRODashboardsummary?user_id=${id}&month_year=${month_year}&downloadexcel=${downloadexcel}`,
      headers: { Authorization: `Bearer ${authtoken}`,"Content-Type": "application/vnd.ms-excel" },
    })
    .then(function (response) {
      const outputFilename = `KRO-RatingSummary-report-${Date.now()}.xlsx`;
      const url = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', outputFilename);
      document.body.appendChild(link);
      link.click();
  // return response;
      }, error => {
        return error.response;
      })
      .catch(function (error) {
        console.log(error.response.status);
      });  
  }   
}

function getkrodetails(month,title,username){
  return axios({
    method: "get",
    url: `${apiUrl}/krodashboarddetails?month_year=${month}&type=${title}&user_id=${username}`,
    headers: {Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
       if(response.status == 200){
            return response;  
       }
    }, error => {
      return error.response;
      console.log(error.response.status);
    })
    .catch(function (error) {
      // console.log(error.response.status);
    }); 
}
function archivekrousermaster(id){
  return axios({
    method: "get",
    url: `${apiUrl}/archivekrousermaster/${id}`,
    headers: { Authorization: `Bearer ${authtoken}` },
  })
  .then(function (response) {
      return response;
    }, error => {
      return error.response;
    })
    .catch(function (error) {
      console.log(error.response.status);
    });  
}
function getroles(){
  return axios({
    method: "get",
    url: `${apiUrl}/getroles`,
    headers: { Authorization: `Bearer ${authtoken}` },
  })
  .then(function (response) {
      return response;
    }, error => {
      return error.response;
    })
    .catch(function (error) {
      console.log(error.response.status);
    }); 
}
function editTypeRatingRow(value,field,id){
  var bodyFormData = new FormData();
  bodyFormData.append("id", id);
  bodyFormData.append("field", field);
  bodyFormData.append("value", value);
  return axios({
      method: "POST",
      url: `${apiUrl}/editTypeRatingRow`,
      data: bodyFormData,
      headers: {Authorization: `Bearer ${authtoken}` },
    })
      .then(function (response) {
        return response;
      }, error => {
       // return error.response;
        console.log(error.response.status);
      })
      .catch(function (error) {
        // console.log(error.response.status);
      });      
}
function getrolesforKROTypeRating(){
  return axios({
    method: "get",
    url: `${apiUrl}/getrolesforKROTypeRating`,
    headers: { Authorization: `Bearer ${authtoken}` },
  })
  .then(function (response) {
      return response;
    }, error => {
      return error.response;
    })
    .catch(function (error) {
      console.log(error.response.status);
    }); 
}
function getKroData(user_id,year){
  return axios({
      method: "get",
      url: `${apiUrl}/getsinglekrotypeData?user_id=${user_id}&year=${year}`,
      headers: {Authorization: `Bearer ${authtoken}` },
    })
      .then(function (response) {
         if(response.status == 200){
              return response;  
         }
      }, error => {
        return error.response;
        console.log(error.response.status);
      })
      .catch(function (error) {
        // console.log(error.response.status);
      });      
}

function adddocs(userdata) {
  var bodyFormData = new FormData();
  
  userdata.files.forEach((file, index) => {
    bodyFormData.append(`files[${index}]`, file);
  });

  for (var pair of bodyFormData.entries()) {
    console.log(pair[0]+ ', ' + pair[1]);
  }

  return axios({
    method: "post",
    url: `${apiUrl}/adddocs`,
    data: bodyFormData,
    headers: { Authorization: `Bearer ${authtoken}` }, 
  })
    .then(function (response) {
      return response;  
    })
    .catch(function (error) {
      return error.response;
    }); 
}


function addKROCompetency(inputs,data){
  var bodyFormData = new FormData();
  bodyFormData.append("data", JSON.stringify(data));
  bodyFormData.append("roles", inputs.roles);
  return axios({
    method: "post",
    url: `${apiUrl}/addCompetencyRole`,
    data: bodyFormData,
    headers: { Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
         return response;  
    }, error => {
      return error.response;
    //   console.log(error.response.status);
    })
    .catch(function (error) {
      // console.log(error.response.status);
    });
}
function competencyList(page,type,active_role){
  return axios({
      method: "get",
      url: `${apiUrl}/competencyList?page=${page}&type=${type}&active_role=${active_role}`,
      headers: {Authorization: `Bearer ${authtoken}` },
    })
      .then(function (response) {
         if(response.status == 200){
              return response;  
         }
      }, error => {
        return error.response;
        console.log(error.response.status);
      })
      .catch(function (error) {
        // console.log(error.response.status);
      });      
}
function getreviewers(){
  return axios({
      method: "get",
      url: `${apiUrl}/getreviewers`,
      headers: {Authorization: `Bearer ${authtoken}` },
    })
      .then(function (response) {
         if(response.status == 200){
              return response;  
         }
      }, error => {
        return error.response;
        console.log(error.response.status);
      })
      .catch(function (error) {
        // console.log(error.response.status);
      });      
}
function assignProcessors(userdata){
 
  var userobj = JSON.stringify(userdata);


  var bodyFormData = new FormData();
  bodyFormData.append("reviewer", userdata.roles);
  bodyFormData.append("processors",userdata.qatl);
  
  return axios({
    method: "post",
    url: `${apiUrl}/assignProcessors`,
    data:bodyFormData,
    headers: { "Content-Type": "application/json",Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
      //  if(response.status == 200){
            return response;  
      //  }
    }, error => {
      return error.response;
      // console.log(error.response.status);
    })
    .catch(function (error) {
       return error;
      // console.log(error.response.status);
    });  
}
function getProcessorListforassign(id){
  return axios({
      method: "get",
      url: `${apiUrl}/getProcessorListforassign/`+id,
      headers: {Authorization: `Bearer ${authtoken}` },
    })
    .then(function (response) {
      if(response.status == 200){
        return response;
       }
      }, error => {
        return error.response;
      })
      .catch(function (error) {
        
      });      
}
function getqatls(type){
  return axios({
    method: "get",
    url: `${apiUrl}/qatlList?type=${type}`,
    headers: { Authorization: `Bearer ${authtoken}` },
  })
    .then(function (response) {
         return response;  
    }, error => {
      return error.response;
    //   console.log(error.response.status);
    })
    .catch(function (error) {
      // console.log(error.response.status);
    });
}